import { observable, action, reaction, makeObservable, toJS } from "mobx";

class DriverStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable driverId = null;
  @observable driverNm = null;
  @observable pw = null;
  @observable hpNo = null;
  @observable useYn = null;
  @observable vcTyp = null;
  @observable vcWeight = null;
  @observable bsNo = null;

  @observable driverList = [];
  @observable selectedDriver = null;

  @observable currentPage = 1;
  @observable totalPage = 1;

  @observable latitude = null;
  @observable longitude = null;

  @observable geoLocationList = [];
  @observable currentFreight = null;

  @observable selectedDriverList = [];

  @action
  setDriverId = (value) => {
    this.driverId = value;
  };

  @action
  initDriverId = () => {
    this.driverId = null;
  };

  @action
  setDriverNm = (value) => {
    this.driverNm = value;
  };

  @action
  initDriverNm = () => {
    this.driverNm = null;
  };

  @action
  setPw = (value) => {
    this.pw = value;
  };

  @action
  initPw = () => {
    this.pw = null;
  };

  @action
  setHpNo = (value) => {
    this.hpNo = value;
  };

  @action
  initHpNo = () => {
    this.hpNo = null;
  };

  @action
  setUseYn = (value) => {
    this.useYn = value;
  };

  @action
  initUseYn = () => {
    this.useYn = null;
  };

  @action
  setVcTyp = (value) => {
    this.vcTyp = value;
  };

  @action
  initVcTyp = () => {
    this.vcTyp = null;
  };

  @action
  setVcWeight = (value) => {
    this.vcWeight = value;
  };

  @action
  initVcWeight = () => {
    this.vcWeight = null;
  };

  @action
  setBsNo = (value) => {
    this.bsNo = value;
  };

  @action
  initBsNo = () => {
    this.bsNo = null;
  };

  @action
  setDriverList = (value) => {
    this.driverList = value;
  };

  @action
  initDriverList = () => {
    this.driverList = [];
  };

  @action
  setSelectedDriver = (value) => {
    this.selectedDriver = value;
  };

  @action
  initSelectedDriver = () => {
    this.selectedDriver = null;
  };

  @action
  initForm = () => {
    this.initDriverId();
    this.initDriverNm();
    this.initHpNo();
    this.initVcTyp();
    this.initUseYn();
    this.initPw();
    this.initVcWeight();
    this.initSelectedDriver();
    this.initCurrentPage();
    this.initTotalPage();
    this.initBsNo();
  };

  @action
  setCurrentPage = (value) => {
    this.currentPage = value;
  };

  @action
  initCurrentPage = () => {
    this.currentPage = 1;
  };

  @action
  setTotalPage = (value) => {
    this.totalPage = value;
  };

  @action
  initTotalPage = () => {
    this.totalPage = 1;
  };

  @action
  setLatitude = (value) => {
    this.latitude = value;
  };

  @action
  initLatitude = () => {
    this.latitude = null;
  };

  @action
  setLongitude = (value) => {
    this.longitude = value;
  };

  @action
  initLongitude = () => {
    this.longitude = null;
  };

  @action
  setGeoLocationList = (value) => {
    this.geoLocationList = value;
  };

  @action
  initGeoLocationList = () => {
    this.geoLocationList = [];
  };

  @action
  setCurrentFreight = (value) => {
    this.currentFreight = value;
  };

  @action
  initCurrentFreight = () => {
    this.currentFreight = null;
  };

  @action
  addSelectedDriver = (value) => {
    this.selectedDriverList.push(value);
  };

  @action
  removeSelectedDriver = (value) => {
    //인덱스 찾아서 삭제
    let index = this.selectedDriverList.findIndex(
      (obj) => obj.driverNo == value.driverNo
    );
    this.selectedDriverList.splice(index, 1);
  };

  @action
  clearSelectedDriver = () => {
    this.selectedDriverList = [];
  };
}

export default DriverStore;
