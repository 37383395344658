import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_BLACK,
  COLOR_FONT_GRAY,
  COLOR_GRAY_GR06,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import { inject, observer } from "mobx-react";
import { ReactComponent as IntroduceImg } from "../../assets/introduce.svg";
import MediaQuery from "react-responsive";

const { kakao } = window;
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class Introduce extends React.Component {
  componentDidMount() {
    this.fnMap();
  }
  fnMap = () => {
    var container = document.getElementById("map"); //지도를 담을 영역의 DOM 레퍼런스
    var options = {
      //지도를 생성할 때 필요한 기본 옵션
      center: new kakao.maps.LatLng(36.3544, 127.3425), //지도의 중심좌표.
      level: 2, //지도의 레벨(확대, 축소 정도)
    };

    var map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴

    var markerPosition = new kakao.maps.LatLng(36.3544, 127.3425);
    var marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);
  };

  render() {
    const { commonStore } = this.props;
    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              //marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                회사소개
              </p>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                빠르고 정확한 개별화물배송! 차별화된 서비스
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "50%",
                    height: "100%",
                    color:
                      commonStore.path == "introduce"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "introduce" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("introduce")}
                >
                  회사소개
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "50%",
                    height: "100%",

                    color:
                      commonStore.path == "serviceInfo"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "serviceInfo" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("serviceInfo")}
                >
                  배송시스템
                </li>
              </ul>
            </div>
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "40px" }}
            >
              <p
                style={{
                  fontSize: "2.013rem",
                  fontWeight: "600",
                  marginBottom: "0px",
                }}
              >
                Differentiated systems and
              </p>
              <p
                style={{
                  fontSize: "2.013rem",
                  fontWeight: "600",
                  marginBottom: "10px",
                  color: COLOR_PRIMARY,
                }}
              >
                customer-first spirit
              </p>
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  marginBottom: "0px",
                }}
              >
                차별화된 시스템과 고객 제일주의 정신으로
              </p>
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                항상 최선을 다하겠습니다.
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "7%",
                height: "auto",
                flexDirection: "column",
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                <IntroduceImg height={"330px"} />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "400px",
                  paddingTop: "3%",
                  textAlign: "left",
                }}
              >
                <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    marginBottom: "5px",
                  }}
                >
                  <span style={{ color: COLOR_PRIMARY }}>
                    주식회사 에이치전국화물 홈페이지를 방문해 주신
                  </span>{" "}
                </p>
                <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    marginBottom: "30px",
                  }}
                >
                  고객 여러분의 관심과 성원에 깊은 감사를 드립니다.
                </p>

                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  안녕하십니까
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  차별화된 시스템과 고객 제일주의 정신으로 항상 최선을
                  다하겠습니다. 주식회사 에이치 전국화물은 모든 화물 운송에
                  최적화된 시스템으로 고객만족에 최선을 다하기 위해 노력하고
                  있습니다.
                </p>

                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  차별화된 물류 시스템으로 새로운 화물 운송 문화를 이끌어
                  나가겠습니다. 앞으로도 많은 관심과 성원 부탁드립니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "45px",
                  }}
                >
                  감사합니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                  }}
                >
                  에이치전국화물 임직원 일동
                </p>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                background: COLOR_BACKGROUND_GRAY,
                height: "auto",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "2.188rem",
                  textAlign: "center",
                  paddingTop: "60px",
                  marginBottom: "60px",
                }}
              >
                오시는길
              </p>
              <div
                id="map"
                style={{ width: "100%", height: "400px", margin: "0 auto" }}
              ></div>
              <div
                style={{
                  width: "90%",
                  height: "230px",
                  margin: "0 auto",
                  marginTop: "50px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    주소
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2em",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    대전광역시 유성구 계룡로105번길 15, 2층 203-G12호
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                    marginTop: "20px",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    이메일
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    logis2021@naver.com
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                  height: "250px",
                  margin: "0 auto",
                  marginTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    전화
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2em",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    1833-8852
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                    marginTop: "20px",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    팩스
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    050-4421-1982
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769} maxWidth={1249}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                회사소개
              </p>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                빠르고 정확한 개별화물배송! 차별화된 서비스
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "introduce"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "introduce"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "introduce"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "introduce" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("introduce")}
                >
                  회사소개
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "serviceInfo"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "serviceInfo"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "serviceInfo"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "serviceInfo" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("serviceInfo")}
                >
                  배송시스템
                </li>
              </ul>
            </div>
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "40px" }}
            >
              <p
                style={{
                  fontSize: "2.413rem",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Differentiated systems and{" "}
                <span style={{ color: COLOR_PRIMARY }}>
                  customer-first spirit
                </span>
              </p>
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  marginBottom: "0px",
                }}
              >
                차별화된 시스템과 고객 제일주의 정신으로
              </p>
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                항상 최선을 다하겠습니다.
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "7%",
                height: "auto",
                flexDirection: "column",
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                <IntroduceImg height={"330px"} />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "400px",
                  paddingTop: "3%",
                  textAlign: "left",
                }}
              >
                <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    marginBottom: "5px",
                  }}
                >
                  <span style={{ color: COLOR_PRIMARY }}>
                    주식회사 에이치전국화물 홈페이지를 방문해 주신
                  </span>{" "}
                </p>
                <p
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    marginBottom: "30px",
                  }}
                >
                  고객 여러분의 관심과 성원에 깊은 감사를 드립니다.
                </p>

                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  안녕하십니까
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  차별화된 시스템과 고객 제일주의 정신으로 항상 최선을
                  다하겠습니다. 주식회사 에이치 전국화물은 모든 화물 운송에
                  최적화된 시스템으로 고객만족에 최선을 다하기 위해 노력하고
                  있습니다.
                </p>

                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  차별화된 물류 시스템으로 새로운 화물 운송 문화를 이끌어
                  나가겠습니다. 앞으로도 많은 관심과 성원 부탁드립니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "45px",
                  }}
                >
                  감사합니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                  }}
                >
                  에이치전국화물 임직원 일동
                </p>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                background: COLOR_BACKGROUND_GRAY,
                height: "auto",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "2.188rem",
                  textAlign: "center",
                  paddingTop: "60px",
                  marginBottom: "60px",
                }}
              >
                오시는길
              </p>
              <div
                id="map"
                style={{ width: "100%", height: "400px", margin: "0 auto" }}
              ></div>
              <div
                style={{
                  width: "90%",
                  height: "230px",
                  margin: "0 auto",
                  marginTop: "50px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    주소
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2em",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    대전광역시 유성구 계룡로105번길 15, 2층 203-G12호
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                    marginTop: "20px",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    이메일
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    logis2021@naver.com
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                  height: "250px",
                  margin: "0 auto",
                  marginTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    전화
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2em",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    1833-8852
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                    marginTop: "20px",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    팩스
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    050-4421-1982
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1250}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              //marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                회사소개
              </p>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                빠르고 정확한 개별화물배송! 차별화된 서비스
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "introduce"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "introduce"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "introduce"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "introduce" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("introduce")}
                >
                  회사소개
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "serviceInfo"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "serviceInfo"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "serviceInfo"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "serviceInfo" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("serviceInfo")}
                >
                  배송시스템
                </li>
              </ul>
            </div>
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "40px" }}
            >
              <p
                style={{
                  fontSize: "2.813rem",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                Differentiated systems and{" "}
                <span style={{ color: COLOR_PRIMARY }}>
                  customer-first spirit
                </span>
              </p>
              <p
                style={{
                  fontSize: "2rem",
                  fontWeight: "600",
                  marginBottom: "10px",
                }}
              >
                차별화된 시스템과 고객 제일주의 정신으로 항상 최선을
                다하겠습니다.
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "7%",
                height: "500px",
              }}
            >
              <div style={{ float: "left", height: "400px" }}>
                <IntroduceImg />
              </div>
              <div
                style={{
                  float: "left",
                  height: "400px",
                  marginLeft: "3%",
                  paddingTop: "3%",
                  paddingLeft: "3%",
                }}
              >
                <p
                  style={{
                    fontSize: "1.75rem",
                    fontWeight: "600",
                    marginBottom: "5px",
                  }}
                >
                  <span style={{ color: COLOR_PRIMARY }}>
                    주식회사 에이치전국화물
                  </span>{" "}
                  홈페이지를 방문해 주신 고객 여러분의
                </p>
                <p
                  style={{
                    fontSize: "1.75rem",
                    fontWeight: "600",
                    marginBottom: "30px",
                  }}
                >
                  관심과 성원에 깊은 감사를 드립니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  안녕하십니까
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  차별화된 시스템과 고객 제일주의 정신으로 항상 최선을
                  다하겠습니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  주식회사 에이치 전국화물은 모든 화물 운송에 최적화된
                  시스템으로
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  고객만족에 최선을 다하기 위해 노력하고 있습니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  차별화된 물류 시스템으로 새로운 화물 운송 문화를 이끌어
                  나가겠습니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "5px",
                  }}
                >
                  앞으로도 많은 관심과 성원 부탁드립니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                    marginBottom: "45px",
                  }}
                >
                  감사합니다.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    color: COLOR_FONT_GRAY,
                    fontSize: "1.25rem",
                  }}
                >
                  에이치전국화물 임직원 일동
                </p>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                background: COLOR_BACKGROUND_GRAY,
                height: "950px",
              }}
            >
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "2.188rem",
                  textAlign: "center",
                  paddingTop: "60px",
                  marginBottom: "60px",
                }}
              >
                오시는길
              </p>
              <div
                id="map"
                style={{ width: "50%", height: "400px", margin: "0 auto" }}
              ></div>
              <div
                style={{
                  width: "50%",
                  height: "100px",
                  margin: "0 auto",
                  marginTop: "50px",
                }}
              >
                <div
                  style={{
                    float: "left",
                    width: "45%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    주소
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2em",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    대전광역시 유성구 계룡로105번길 15, 2층 203-G12호
                  </p>
                </div>
                <div style={{ float: "left", width: "10%", height: "100px" }} />
                <div
                  style={{
                    float: "left",
                    width: "45%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    이메일
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    logis2021@naver.com
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  height: "100px",
                  margin: "0 auto",
                  marginTop: "50px",
                  marginBottom: "50px",
                }}
              >
                <div
                  style={{
                    float: "left",
                    width: "45%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    전화
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2em",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    1833-8852
                  </p>
                </div>
                <div style={{ float: "left", width: "10%", height: "100px" }} />
                <div
                  style={{
                    float: "left",
                    width: "45%",
                    height: "100px",
                    borderBottom: "1px solid rgba(103,103,103,0.5)",
                  }}
                >
                  <p style={{ fontWeight: "500", fontSize: "1.563rem" }}>
                    팩스
                  </p>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    050-4421-1982
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(Introduce);
