export const COLOR_PRIMARY = "#03C75A";
export const COLOR_FONT_BLACK = "#161820";
export const COLOR_FONT_GRAY = "#676767";
export const COLOR_BACKGROUND_GRAY = "#F5F5F5";

export const COLOR_BLACK = "#000";
export const COLOR_WHITE = "#FFF";

export const COLOR_GRAY_GR00 = "#F8F9FA";
export const COLOR_GRAY_GR01 = "#EFEFEF";
export const COLOR_GRAY_GR02 = "#E9EBED";
export const COLOR_GRAY_GR05 = "#C2C2C4";
export const COLOR_GRAY_GR06 = "#8F8F8F";
export const COLOR_GRAY_GR07 = "#5A5D61";
export const COLOR_GRAY_GR08 = "#2C2E32";

export const COLOR_PINK_PRIMARY = "#f73677";

export const COLOR_INPUT_GRAY = "#F3F6F9";
export const COLOR_BTN_DEEP_GRAY = "#969696";
