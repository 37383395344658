import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import axios from "axios";
import {
  API_SELECT_CURRENT_FREIGHT,
  API_UPDATE_GEOLOCATION,
} from "../../common/ApiAddress/DriverAPI";
import CommonAlert from "../../Component/Common/CommonAlert";
import MediaQuery from "react-responsive";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_BTN_DEEP_GRAY,
  COLOR_GRAY_GR01,
  COLOR_GRAY_GR02,
  COLOR_GRAY_GR07,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import { API_SELECT_COMPLETE_FREIGHT_LIST } from "../../common/ApiAddress/FrightAPI";
import moment from "moment";
@inject((stores) => ({
  driverStore: stores.DriverStore,
  commonStore: stores.CommonStore,
  freightStore: stores.FreightStore,
}))
@observer
class DriverFreightList extends React.Component {
  state = {
    activeDateCondition: 0,
    dateCondition: "1W",
  };

  componentDidMount() {
    const { driverStore } = this.props;
    this.fnSelectCompleteFreightList();
  }

  fnSelectCompleteFreightList = async () => {
    const { freightStore } = this.props;
    const params = {};
    const that = this;
    params.driverId = sessionStorage.getItem("driverId");
    params.dateCondition = this.state.dateCondition;
    axios({
      url: API_SELECT_COMPLETE_FREIGHT_LIST,
      method: "post",
      data: params,
    })
      .then((response) => {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);
          freightStore.setFreightList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetActiveItemForDate = (index, value) => {
    const { freightStore } = this.props;
    freightStore.initFreightList();
    this.setState({
      activeDateCondition: index,
      dateCondition: value,
    });
    this.fnSelectCompleteFreightList();
  };

  fnSetSelectedFreight = (value) => {
    const { freightStore } = this.props;
    freightStore.setSelectedFreight(value);
    this.props.history.push("/driverHome/freighDetail");
  };

  render() {
    const { driverStore, commonStore, freightStore } = this.props;
    const date = [
      { text: "1주일", value: "1W" },
      { text: "1개월", value: "1M" },
      { text: "3개월", value: "3M" },
      { text: "6개월", value: "6M" },
      { text: "1년", value: "1Y" },
    ];
    return (
      <Fragment>
        <MediaQuery maxWidth={480}>
          <div
            style={{
              width: "100%",
              height: "100vh",
              background: "#F8F9FA",
              overflowY: "scroll",
              paddingBottom: "100px",
            }}
          >
            <div
              style={{
                width: "100%",
                fontWeight: "700",
                fontSize: "1.5rem",
                textAlign: "center",
                paddingTop: "90px",
                height: "150px",
              }}
            >
              <p>운송내역</p>
            </div>
            <div style={{ width: "100%", height: "40px" }}>
              {date.map((data, i) => (
                <div
                  style={{
                    width: "18%",
                    float: "left",
                    textAlign: "center",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      this.state.activeDateCondition == i
                        ? COLOR_GRAY_GR07
                        : COLOR_GRAY_GR02,
                    marginRight: "1%",
                    marginLeft: "1%",
                    borderRadius: "4px",
                    fontWeight: "700",
                    fontSize: "1.2rem",
                    color: COLOR_WHITE,
                  }}
                  onClick={() => this.fnSetActiveItemForDate(i, data)}
                >
                  <p>{data.text}</p>
                </div>
              ))}
            </div>
            {freightStore.freightList.length == 0 ? (
              <div
                style={{
                  width: "90%",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  background: COLOR_WHITE,
                  height: "300px",
                  borderRadius: "10px",
                  margin: "0 auto",
                  padding: "20px",
                  display: "flex",
                  justifyItems: "center",
                  marginTop: "20px",
                }}
              >
                <p>내역이 존재하지 않습니다.</p>
              </div>
            ) : (
              freightStore.freightList.map((data, i) => (
                <div
                  style={{
                    width: "90%",
                    boxShadow: "0px 4px 4px 0px #00000040",
                    background: COLOR_WHITE,
                    height: "300px",
                    borderRadius: "10px",
                    margin: "0 auto",
                    padding: "20px",
                    display: "flex",
                    justifyItems: "center",
                    marginTop: "20px",
                    flexDirection: "column",
                  }}
                >
                  <p style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                    {moment(data.registDt).format("YYYY-MM-DD")}
                  </p>
                  <table style={{ width: "100%" }}>
                    <tr style={{ height: "45px" }}>
                      <td
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: COLOR_PRIMARY,
                          width: "30%",
                        }}
                      >
                        상차지
                      </td>
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {data.loadingSido +
                          " " +
                          data.loadingSido +
                          " " +
                          data.loadingDong +
                          " " +
                          data.loadingDetailAdd}
                      </td>
                    </tr>
                    <tr style={{ height: "45px" }}>
                      <td
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          color: COLOR_PRIMARY,
                          width: "30%",
                        }}
                      >
                        하차지
                      </td>
                      <td
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {data.unloadingSido +
                          " " +
                          data.unloadingSido +
                          " " +
                          data.unloadingDong +
                          " " +
                          data.unloadingDetailAdd}
                      </td>
                    </tr>
                  </table>
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        textAlign: "right",
                        fontWeight: "700",
                        fontSize: "1.5rem",
                        paddingTop: "20px",
                      }}
                    >
                      {data.logisFee
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        background: COLOR_PRIMARY,
                        width: "50%",
                        height: "45px",
                        borderRadius: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: COLOR_WHITE,
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        //cursor: "pointer",
                      }}
                      onClick={() => {
                        this.fnSetSelectedFreight(data);
                      }}
                    >
                      <p>내역확인</p>
                    </div>
                  </div>
                </div>
              ))
            )}

            <CommonAlert />
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(DriverFreightList);
