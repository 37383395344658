import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class FileUploadModal extends Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Modal
        size="tiny"
        open={commonStore.fileUploadModal}
        onClose={() => commonStore.fileUploadModalClose()}
        style={{ height: "210px" }}
      >
        <Modal.Header>파일업로드</Modal.Header>
        <Modal.Content>
          <input type="file" id="excelUpload" />
        </Modal.Content>
        <div style={{ float: "right", marginTop: "20px", marginRight: "20px" }}>
          <Button positive onClick={() => this.props.fnInsertBulkByExcel()}>
            확인
          </Button>
        </div>
      </Modal>
    );
  }
}

export default FileUploadModal;
