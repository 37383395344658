import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import DriverManagement from "./Management/DriverManagement";
import CorporationManagement from "./Management/CorporationManagement";
import FreightManagement from "./Management/Freight/FreightManagement";
import CompManagement from "./Management/CompManagement";
import MapManagement from "./Management/MapManagement";
import OrderManagement from "./Management/OrderManagement";
import DashBoard from "./Management/DashBoard";
import SettlementManagement from "./Management/SettlementManagement";
class ManagementContainer extends React.Component {
  fnRouterChage = (path) => {
    this.props.history.push(path);
  };

  render() {
    let currentPage;
    switch (this.props.location.pathname) {
      case "/management/main":
        currentPage = <DashBoard onSelect={this.props.onSelect} />;
        break;
      case "/management/freight/all":
        currentPage = <FreightManagement />;
        break;
      case "/management/driver":
        currentPage = <DriverManagement />;
        break;
      case "/management/corporation":
        currentPage = <CorporationManagement />;
        break;
      case "/management/comp":
        currentPage = <CompManagement />;
        break;
      case "/management/map":
        currentPage = <MapManagement />;
        break;
      case "/management/order":
        currentPage = <OrderManagement />;
        break;
      case "/management/settlement":
        currentPage = <SettlementManagement />;
        break;
    }

    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <div style={{ width: "100%", height: "500px" }}>{currentPage}</div>
      </div>
    );
  }
}

export default withRouter(ManagementContainer);
