import React, { Component } from "react";
import { Button, Modal, Input, Form, Table } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import axios from "axios";
import { API_SELECT_DRIVER_SEARCHLIST } from "../../common/ApiAddress/DriverAPI";
import {
  COLOR_FONT_BLACK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
@inject((stores) => ({
  commonStore: stores.CommonStore,
  driverStore: stores.DriverStore,
}))
@observer
class SearchDriverModal extends Component {
  fnSelectDriverSearchList = (currentPage, driverId) => {
    const { driverStore } = this.props;
    driverStore.setDriverId(driverId);
    let params = {};
    if (currentPage == undefined || currentPage == 1) {
      params.startCnt = 0;
      params.endCnt = 10;
      params.driverId = driverId;
    } else {
      params.startCnt = (currentPage - 1) * 10;
      params.endCnt = 10;
      params.driverId = driverId;
    }
    axios({
      url: API_SELECT_DRIVER_SEARCHLIST,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          driverStore.setDriverList(response.data.data);
          driverStore.setTotalPage(response.data.totalPageNum);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { commonStore, driverStore } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={commonStore.searchDriverModal}
        onClose={() => {
          driverStore.initDriverId();
          driverStore.initDriverList();
          commonStore.searchDriverModalClose();
        }}
      >
        <Modal.Header>기사검색</Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Input
              style={{ width: "98%" }}
              placeholder={"차량번호 검색"}
              onChange={(e) => this.fnSelectDriverSearchList(1, e.target.value)}
              value={driverStore.driverId}
            />
          </Form.Field>
          <Table celled style={{ textAlign: "center" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>기사명</Table.HeaderCell>
                <Table.HeaderCell>차량번호</Table.HeaderCell>
                <Table.HeaderCell>연락처</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {driverStore.driverList == null ||
            driverStore.driverList.length == 0 ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan="3" textAlign="center">
                    등록된 기사가 없습니다.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body>
                {driverStore.driverList.map((data, i) => (
                  <React.Fragment key={data.driverNo}>
                    <Table.Row
                      style={{
                        backgroundColor:
                          driverStore.selectedDriver != null &&
                          data.driverNo == driverStore.selectedDriver.driverNo
                            ? COLOR_PRIMARY
                            : COLOR_WHITE,
                        cursor: "pointer",
                        color:
                          driverStore.selectedDriver != null &&
                          data.driverNo == driverStore.selectedDriver.driverNo
                            ? COLOR_WHITE
                            : COLOR_FONT_BLACK,
                      }}
                      onClick={() => driverStore.setSelectedDriver(data)}
                    >
                      <Table.Cell>{data.driverNm}</Table.Cell>
                      <Table.Cell>{data.driverId}</Table.Cell>
                      <Table.Cell>{data.hpNo}</Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                ))}
              </Table.Body>
            )}
          </Table>
        </Modal.Content>
        <div
          style={{
            float: "right",
            marginTop: "20px",
            marginRight: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            negative
            onClick={() => {
              driverStore.initDriverId();
              driverStore.initDriverList();
              commonStore.searchDriverModalClose();
            }}
          >
            아니오
          </Button>
          <Button positive onClick={() => this.props.fnUpdateDriverInfo()}>
            예
          </Button>
        </div>
      </Modal>
    );
  }
}

export default SearchDriverModal;
