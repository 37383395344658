import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Pagination, Table } from "semantic-ui-react";
import _ from "lodash";
import {
  COLOR_BLACK,
  COLOR_GRAY_GR06,
  COLOR_PINK_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../../common/Color";
import moment from "moment";
@inject((stores) => ({
  freightStore: stores.FreightStore,
}))
@observer
class FreightAllList extends Component {
  state = {
    column: null,
    direction: null,
  };

  handleSort = (clickedColumn) => {
    const { column, direction } = this.state;
    const { freightStore } = this.props;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: "ascending",
      });
      let listItem = _.sortBy(freightStore.freightList, [clickedColumn]);
      freightStore.setFreightList(listItem);

      return;
    }

    this.setState({
      direction: direction === "ascending" ? "descending" : "ascending",
    });
    let listItem = [...freightStore.freightList];
    freightStore.setFreightList(listItem.reverse());
  };

  render() {
    const { freightStore } = this.props;

    return (
      <Fragment>
        <Table sortable style={{ border: "0px", width: "130%" }}>
          {freightStore.freightList == null ||
          freightStore.freightList.length == 0 ? null : (
            <Table.Header style={{ border: "0px" }}>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    background: "#fff",
                  }}
                  sorted={
                    this.state.column === "idx" ? this.state.direction : null
                  }
                  onClick={() => this.handleSort("idx")}
                >
                  순번
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  배차상태
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    background: "#fff",
                    borderLeft: "0px",
                  }}
                  sorted={
                    this.state.column === "registDt"
                      ? this.state.direction
                      : null
                  }
                  onClick={() => this.handleSort("registDt")}
                >
                  등록일시
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  상차지
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  하차지
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  화물정보
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  톤수/차종
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  전체운임
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  기사운임
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  수수료
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  기사정보
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  기사 톤수/차종
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  거래처
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          {freightStore.freightList == null ||
          freightStore.freightList.length == 0 ? (
            <Table.Body>
              <Table.Row
                style={{ height: "400px", fontSize: "20px", fontWeight: "600" }}
              >
                <Table.Cell colSpan="14" textAlign="center">
                  등록된 화물내역이 없습니다.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {freightStore.freightList.map((data, i) => (
                <React.Fragment key={data.freightNo}>
                  <Table.Row
                    onClick={() => this.props.fnSelectedFreight(data)}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        freightStore.selectedFreight != null &&
                        freightStore.selectedFreight.freightNo ===
                          data.freightNo
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        freightStore.selectedFreight != null &&
                        freightStore.selectedFreight.freightNo ===
                          data.freightNo
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    <Table.Cell>{i + 1}</Table.Cell>

                    <Table.Cell>
                      <div
                        style={{
                          width: "49px",
                          height: "20px",
                          background:
                            data.freightCd == "배차대기"
                              ? null
                              : data.freightCd == "배차완료"
                              ? COLOR_PINK_PRIMARY
                              : data.freightCd == "운송중"
                              ? COLOR_PRIMARY
                              : data.freightCd == "운송완료"
                              ? "#6e6ee6"
                              : data.freightCd == "운송보류"
                              ? "#FEF0BC"
                              : data.freightCd == "운송취소"
                              ? "#000"
                              : null,

                          color:
                            data.freightCd == "배차대기"
                              ? COLOR_GRAY_GR06
                              : data.freightCd == "배차완료"
                              ? COLOR_WHITE
                              : data.freightCd == "운송중"
                              ? COLOR_WHITE
                              : data.freightCd == "운송완료"
                              ? COLOR_WHITE
                              : data.freightCd == "운송보류"
                              ? "#FFB800"
                              : data.freightCd == "운송취소"
                              ? "#fff"
                              : null,

                          border:
                            data.freightCd == "배차대기"
                              ? "1px solid #8F8F8F"
                              : data.freightCd == "배차완료"
                              ? "1px solid #f73677"
                              : data.freightCd == "운송중"
                              ? "1px solid #78C939"
                              : data.freightCd == "운송완료"
                              ? "1px solid #6e6ee6"
                              : data.freightCd == "운송보류"
                              ? "1px solid #FEF0BC"
                              : null,
                          padding: "2px 4px",
                          borderRadius: "3px",
                          fontSize: "11px",
                          fontWeight: "700",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {data.freightCd}
                      </div>
                    </Table.Cell>
                    <Table.Cell style={{ width: "10%" }}>
                      {moment(data.registDt).format("YYYY-MM-DD HH:mm:SS")}
                    </Table.Cell>
                    <Table.Cell style={{ width: "15%" }}>
                      {data.loadingSido +
                        " " +
                        data.loadingSigu +
                        " " +
                        data.loadingDong +
                        " " +
                        data.loadingDetailAdd}
                    </Table.Cell>
                    <Table.Cell style={{ width: "15%" }}>
                      {data.unloadingSido +
                        " " +
                        data.unloadingSigu +
                        " " +
                        data.unloadingDong +
                        " " +
                        data.unloadingDetailAdd}
                    </Table.Cell>
                    <Table.Cell style={{ width: "10%" }}>
                      {data.freightInfo}
                    </Table.Cell>
                    <Table.Cell style={{ width: "10%" }}>
                      {data.vcTyp + "/" + data.vcWeight}
                    </Table.Cell>
                    <Table.Cell style={{ width: "5%" }}>
                      {data.totalFee != undefined
                        ? data.totalFee
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                        : "미정"}
                    </Table.Cell>
                    <Table.Cell style={{ width: "5%" }}>
                      {data.logisFee != undefined
                        ? data.logisFee
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                        : "미정"}
                    </Table.Cell>
                    <Table.Cell style={{ width: "5%" }}>
                      {data.commission != undefined
                        ? data.commission
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                        : "미정"}
                    </Table.Cell>
                    <Table.Cell style={{ width: "13%" }}>
                      <sapn>
                        {data.driverNm != undefined ? data.driverNm + "/" : ""}
                      </sapn>
                      <sapn>
                        {data.driverId != undefined ? data.driverId + "/" : ""}
                      </sapn>
                      <sapn>
                        {data.driverHpNo != undefined ? data.driverHpNo : ""}
                      </sapn>
                    </Table.Cell>
                    <Table.Cell style={{ width: "8%" }}>
                      <sapn>
                        {data.driverVcTyp != undefined
                          ? data.driverVcTyp + "/"
                          : ""}
                      </sapn>
                      <sapn>
                        {data.driverVcWeight != undefined
                          ? data.driverVcWeight
                          : ""}
                      </sapn>
                    </Table.Cell>
                    <Table.Cell style={{ width: "12%" }}>
                      {data.compNm != undefined ? data.compNm : ""}
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
        <div style={{ textAlign: "center" }}>
          <Pagination
            onPageChange={(e, data) => {
              this.props.fnSelectFreightAllList(data.activePage);
            }}
            defaultActivePage={freightStore.currentPage}
            id="pagination"
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={freightStore.totalPage}
            style={{ border: "0px" }}
          />
        </div>
      </Fragment>
    );
  }
}

export default FreightAllList;
