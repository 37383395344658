import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { COLOR_GRAY_GR05, COLOR_PRIMARY } from "../../common/Color";
import { ReactComponent as Top_logo } from "../../assets/top_logo.svg";

export default (props) => {
  const [isOpen, setOpen] = useState(false);

  const fnRouterChage = props.fnRouterChage;
  const path = props.path;

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  const handleStateChange = (state) => {
    setOpen(state.isOpen);
  };
  return (
    // Pass on our props
    <Menu
      {...props}
      isOpen={isOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      <Top_logo
        width={"70%"}
        height={"80px"}
        style={{ marginLeft: "10%", cursor: "pointer" }}
        onClick={() => this.props.fnRouterChage("/")}
      />
      <div
        style={{
          width: "90%",
          height: "1px",
          border: "1px solid",
          marginTop: "20px",
        }}
      />
      <p
        className="menu-item"
        style={{
          color: path == "/" ? COLOR_PRIMARY : COLOR_GRAY_GR05,
          fontSize: "1.6rem",
          fontWeight: path == "/" ? "700" : "500",
          marginBottom: "15px",
          paddingLeft: "10px",
        }}
        onClick={() => {
          closeSideBar();
          fnRouterChage("/");
        }}
      >
        홈
      </p>
      <p
        className="menu-item"
        style={{
          color:
            path == "BasicFreight" ||
            path == "AllLocaFreight" ||
            path == "AllVcInfo"
              ? COLOR_PRIMARY
              : COLOR_GRAY_GR05,
          fontSize: "1.6rem",
          fontWeight:
            path == "BasicFreight" ||
            path == "AllLocaFreight" ||
            path == "AllVcInfo"
              ? "700"
              : "500",
          marginBottom: "15px",
          paddingLeft: "10px",
        }}
        onClick={() => {
          closeSideBar();
          fnRouterChage("BasicFreight");
        }}
      >
        서비스안내
      </p>
      <p
        className="menu-item"
        style={{
          color:
            path == "VcInfo1" || path == "VcInfo2" || path == "VcInfo5"
              ? COLOR_PRIMARY
              : COLOR_GRAY_GR05,
          fontSize: "1.6rem",
          fontWeight:
            path == "VcInfo1" || path == "VcInfo2" || path == "VcInfo5"
              ? "700"
              : "500",
          marginBottom: "15px",
          paddingLeft: "10px",
        }}
        onClick={() => {
          closeSideBar();
          fnRouterChage("VcInfo1");
        }}
      >
        화물운송안내
      </p>
      <p
        className="menu-item"
        style={{
          color: path == "corporation" ? COLOR_PRIMARY : COLOR_GRAY_GR05,
          fontSize: "1.6rem",
          fontWeight: path == "corporation" ? "700" : "500",
          marginBottom: "15px",
          paddingLeft: "10px",
        }}
        onClick={() => {
          closeSideBar();
          fnRouterChage("corporation");
        }}
      >
        기업거래문의
      </p>
      <p
        className="menu-item"
        style={{
          color:
            path == "introduce" || path == "serviceInfo"
              ? COLOR_PRIMARY
              : COLOR_GRAY_GR05,
          fontSize: "1.6rem",
          fontWeight:
            path == "introduce" || path == "serviceInfo" ? "700" : "500",
          marginBottom: "15px",
          paddingLeft: "10px",
        }}
        onClick={() => {
          closeSideBar();
          fnRouterChage("introduce");
        }}
      >
        회사소개
      </p>
      <div
        style={{
          width: "90%",
          height: "1px",
          border: "1px solid",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      />
      <p
        className="menu-item"
        style={{
          color: path == "terms" ? COLOR_PRIMARY : COLOR_GRAY_GR05,
          fontSize: "1.3rem",
          fontWeight: path == "terms" ? "700" : "500",
          marginBottom: "15px",
          paddingLeft: "10px",
        }}
        onClick={() => {
          closeSideBar();
          fnRouterChage("terms");
        }}
      >
        이용약관
      </p>
      <p
        className="menu-item"
        style={{
          color: path == "privacy" ? COLOR_PRIMARY : COLOR_GRAY_GR05,
          fontSize: "1.3rem",
          fontWeight: path == "privacy" ? "700" : "500",
          marginBottom: "15px",
          paddingLeft: "10px",
        }}
        onClick={() => {
          closeSideBar();
          fnRouterChage("privacy");
        }}
      >
        개인청보 처리방침
      </p>
      <div
        style={{
          width: "90%",
          height: "1px",
          border: "1px solid",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      />
      <div></div>
    </Menu>
  );
};
