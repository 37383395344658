export const isLogin = () => {
  if (sessionStorage.getItem("adminId") != undefined) {
    return true;
  } else {
    return false;
  }
};

export const isDriverLogin = () => {
  if (sessionStorage.getItem("driverId") != undefined) {
    return true;
  } else {
    return false;
  }
};
