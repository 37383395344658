import React, { Component, Fragment } from "react";
import axios from "axios";
import { Pagination, Table, Button } from "semantic-ui-react";
import {
  COLOR_BLACK,
  COLOR_GRAY_GR06,
  COLOR_PINK_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../../common/Color";
import { inject, observer } from "mobx-react";
@inject((stores) => ({
  freightStore: stores.FreightStore,
  commonStore: stores.CommonStore,
}))
@observer
class FreightDivList extends Component {
  render() {
    const { freightStore, commonStore } = this.props;
    return (
      <Fragment>
        {this.props.path == "/management/freight/transcomplete" ? (
          <div style={{ textAlign: "right", paddingRight: "20px" }}>
            <Button
              color="yellow"
              onClick={() => {
                commonStore.searchCompleteFreightModalOpen();
              }}
            >
              엑셀 다운로드
            </Button>
          </div>
        ) : null}
        <Table style={{ border: "0px" }}>
          {freightStore.freightList == null ||
          freightStore.freightList.length == 0 ? null : (
            <Table.Header style={{ border: "0px" }}>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    width: "25%",
                    borderBottom: "1px solid #CED4DA",
                    background: "#fff",
                  }}
                >
                  운송번호
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: "25%",
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  운송상태
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: "25%",
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  화주 연락처
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    width: "25%",
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  차량번호
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          {freightStore.freightList == null ||
          freightStore.freightList.length == 0 ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="14" textAlign="center">
                  등록된 화물내역이 없습니다.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {freightStore.freightList.map((data, i) => (
                <React.Fragment key={data.freightNo}>
                  <Table.Row
                    onClick={() => this.props.fnDetailViewOpen(data)}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        freightStore.selectedFreight != null &&
                        freightStore.selectedFreight.freightNo ===
                          data.freightNo
                          ? COLOR_PRIMARY
                          : COLOR_WHITE,
                      color:
                        freightStore.selectedFreight != null &&
                        freightStore.selectedFreight.freightNo ===
                          data.freightNo
                          ? COLOR_WHITE
                          : COLOR_BLACK,
                    }}
                  >
                    <Table.Cell>{data.freightNo}</Table.Cell>
                    <Table.Cell>
                      <div
                        style={{
                          width: "49px",
                          height: "20px",
                          background:
                            data.freightCd == "배차대기"
                              ? null
                              : data.freightCd == "배차완료"
                              ? COLOR_PINK_PRIMARY
                              : data.freightCd == "운송중"
                              ? COLOR_PRIMARY
                              : data.freightCd == "운송완료"
                              ? "#6e6ee6"
                              : data.freightCd == "운송보류"
                              ? "#FEF0BC"
                              : data.freightCd == "운송취소"
                              ? "#000"
                              : null,

                          color:
                            data.freightCd == "배차대기"
                              ? COLOR_GRAY_GR06
                              : data.freightCd == "배차완료"
                              ? COLOR_WHITE
                              : data.freightCd == "운송중"
                              ? COLOR_WHITE
                              : data.freightCd == "운송완료"
                              ? COLOR_WHITE
                              : data.freightCd == "운송보류"
                              ? "#FFB800"
                              : data.freightCd == "운송취소"
                              ? "#fff"
                              : null,

                          border:
                            data.freightCd == "배차대기"
                              ? "1px solid #8F8F8F"
                              : data.freightCd == "배차완료"
                              ? "1px solid #f73677"
                              : data.freightCd == "운송중"
                              ? "1px solid #78C939"
                              : data.freightCd == "운송완료"
                              ? "1px solid #6e6ee6"
                              : data.freightCd == "운송보류"
                              ? "1px solid #FEF0BC"
                              : null,
                          padding: "2px 4px",
                          borderRadius: "3px",
                          fontSize: "11px",
                          fontWeight: "700",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {data.freightCd}
                      </div>
                    </Table.Cell>
                    <Table.Cell>{data.hpNo}</Table.Cell>
                    <Table.Cell>{data.driverId}</Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
        <div style={{ textAlign: "center" }}>
          <Pagination
            onPageChange={(e, data) => {
              this.props.fnSelectFreightDivList(data.activePage);
            }}
            defaultActivePage={freightStore.currentPage}
            id="pagination"
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={freightStore.totalPage}
            style={{ border: "0px" }}
          />
        </div>
      </Fragment>
    );
  }
}

export default FreightDivList;
