import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { inject, observer } from "mobx-react";
import {
  API_SELECT_DONG,
  API_SELECT_SIDO,
  API_SELECT_SIGU,
} from "../../common/ApiAddress/CommonAPI";
import { Select, Form, TextArea, Checkbox } from "semantic-ui-react";
import {
  COLOR_BTN_DEEP_GRAY,
  COLOR_FONT_BLACK,
  COLOR_INPUT_GRAY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import {
  PayCdList,
  freightCdList,
  freightInfoList,
  loadingCdList,
  logisCdList,
  networkCdList,
  vcTypList,
  vcWeightList,
} from "../../common/VcData";
@inject((stores) => ({
  driverStore: stores.DriverStore,
  commonStore: stores.CommonStore,
}))
@observer
class OrderManagement extends React.Component {
  render() {
    const { commonStore } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: "320px",
        }}
      >
        <div style={{ height: "320px", float: "left", width: "49%" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "20px",
              height: "120px",
            }}
          >
            <div
              style={{
                width: "49%",
                height: "120px",
                float: "left",
                marginRight: "1%",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                출발지
                <span style={{ color: "red" }}>*</span>
              </p>

              <Form.Field
                style={{ width: "32%", float: "left", marginRight: "1%" }}
              >
                <Select
                  style={{
                    minWidth: "90%",
                    background: COLOR_INPUT_GRAY,
                    border: "0px",
                  }}
                  placeholder={"시도 선택"}
                  value={commonStore.selectedSido}
                  options={commonStore.sidoList}
                  onChange={(e, { value }) => this.props.fnSelectSigu(value)}
                />
              </Form.Field>
              <Form.Field
                style={{ width: "32%", float: "left", marginRight: "1%" }}
              >
                <Select
                  style={{
                    minWidth: "90%",
                    background: COLOR_INPUT_GRAY,
                    border: "0px",
                  }}
                  placeholder={"시군구 선택"}
                  value={commonStore.selectedSigu}
                  options={commonStore.siguList}
                  onChange={(e, { value }) => this.props.fnSelectDong(value)}
                />
              </Form.Field>
              <Form.Field
                style={{ width: "33%", float: "left", marginRight: "1%" }}
              >
                <Select
                  style={{
                    minWidth: "90%",
                    background: COLOR_INPUT_GRAY,
                    border: "0px",
                  }}
                  placeholder={"읍명동 선택"}
                  value={commonStore.selectedDong}
                  options={commonStore.dongList}
                  onChange={(e, { value }) =>
                    commonStore.setSelectedDong(value)
                  }
                />
              </Form.Field>
              <input
                style={{
                  width: "96%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                  borderRadius: "0.28571429rem",
                  height: "38px",
                  marginTop: "10px",
                  paddingLeft: "10px",
                }}
                placeholder={"출발지 상세주소 작성"}
                onChange={(e) =>
                  commonStore.setLoadingDetailAdd(e.target.value)
                }
                value={commonStore.loadingDetailAdd || ""}
              />
            </div>

            <div
              style={{
                width: "50%",
                height: "120px",
                float: "left",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                도착지
                <span style={{ color: "red" }}>*</span>
              </p>
              <Form.Field
                style={{ width: "32%", float: "left", marginRight: "1%" }}
              >
                <Select
                  style={{
                    minWidth: "90%",
                    background: COLOR_INPUT_GRAY,
                    border: "0px",
                  }}
                  placeholder={"시도 선택"}
                  value={commonStore.unloadingSelectedSido}
                  options={commonStore.unloadingSidoList}
                  onChange={(e, { value }) =>
                    this.props.fnUnloadingSelectSigu(value)
                  }
                />
              </Form.Field>
              <Form.Field
                style={{ width: "32%", float: "left", marginRight: "1%" }}
              >
                <Select
                  style={{
                    minWidth: "90%",
                    background: COLOR_INPUT_GRAY,
                    border: "0px",
                  }}
                  placeholder={"시군구 선택"}
                  value={commonStore.unloadingSelectedSigu}
                  options={commonStore.unloadingSiguList}
                  onChange={(e, { value }) =>
                    this.props.fnUnloadingSelectDong(value)
                  }
                />
              </Form.Field>
              <Form.Field
                style={{ width: "33%", float: "left", marginRight: "1%" }}
              >
                <Select
                  style={{
                    minWidth: "90%",
                    background: COLOR_INPUT_GRAY,
                    border: "0px",
                  }}
                  placeholder={"읍명동 선택"}
                  value={commonStore.unloadingSelectedDong}
                  options={commonStore.unloadingDongList}
                  onChange={(e, { value }) =>
                    commonStore.setUnloadingSelectedDong(value)
                  }
                />
              </Form.Field>
              <input
                style={{
                  width: "96%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                  borderRadius: "0.28571429rem",
                  height: "38px",
                  marginTop: "10px",
                  paddingLeft: "10px",
                }}
                placeholder={"도착지 상세주소 작성"}
                onChange={(e) =>
                  commonStore.setUnloadingDetailAdd(e.target.value)
                }
                value={commonStore.unloadingDetailAdd || ""}
              />
            </div>
          </div>

          <div style={{ width: "100%", height: "70px", marginTop: "20px" }}>
            <Form.Field style={{ width: "33%", float: "left" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                톤수
                <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                style={{
                  width: "96%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                }}
                placeholder={"톤수선택"}
                value={commonStore.vcWeight}
                options={vcWeightList}
                onChange={(e, { value }) => commonStore.setVcWeight(value)}
              />
            </Form.Field>
            <Form.Field style={{ width: "33%", float: "left" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                차종
                <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                style={{
                  width: "96%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                }}
                placeholder={"차종선택"}
                value={commonStore.vcTyp}
                options={vcTypList}
                onChange={(e, { value }) => commonStore.setVcTyp(value)}
              />
            </Form.Field>
            <Form.Field style={{ width: "33%", float: "left" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                운송구분
              </p>
              <Select
                style={{
                  width: "96%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                }}
                placeholder={"운송구분선택"}
                value={commonStore.logisCd}
                options={logisCdList}
                onChange={(e, { value }) => commonStore.setLogisCd(value)}
              />
            </Form.Field>
          </div>

          <div style={{ width: "100%", height: "70px", marginTop: "20px" }}>
            <Form.Field style={{ width: "33%", float: "left" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                상차방법
              </p>
              <Select
                style={{
                  width: "96%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                }}
                placeholder={"상차방법선택"}
                value={commonStore.loadingCd}
                options={loadingCdList}
                onChange={(e, { value }) => commonStore.setLoadingCd(value)}
              />
            </Form.Field>
            <Form.Field style={{ width: "33%", float: "left" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                하차방법
              </p>
              <Select
                style={{
                  width: "96%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                }}
                placeholder={"하차방법선택"}
                value={commonStore.unloadingCd}
                options={loadingCdList}
                onChange={(e, { value }) => commonStore.setUnloadingCd(value)}
              />
            </Form.Field>
            <Form.Field style={{ width: "33%", float: "left" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                운송상태
              </p>
              <Select
                style={{
                  width: "96%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                }}
                placeholder={"운송상태 선택"}
                value={commonStore.freightCd}
                options={freightCdList}
                onChange={(e, { value }) => commonStore.setFreightCd(value)}
              />
            </Form.Field>
          </div>
        </div>

        <div style={{ width: "50%", float: "left" }}>
          <div
            style={{
              width: "100%",
              height: "122px",
            }}
          >
            <Form style={{ width: "100%", height: "100px" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                  marginTop: "0px",
                }}
              >
                운송물건<span style={{ color: "red" }}>*</span>
              </p>
              <TextArea
                style={{
                  resize: "none",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                  height: "100px",
                }}
                placeholder="운송물건 직접입력"
                value={commonStore.freightInfo || ""}
                onChange={(e, { value }) => commonStore.setFreightInfo(value)}
              />
            </Form>
          </div>

          <div style={{ width: "100%", height: "70px", marginTop: "18px" }}>
            <Form.Field
              style={{ width: "32%", float: "left", marginRight: "1%" }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                차주운임
              </p>
              <input
                style={{
                  width: "100%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                  borderRadius: "0.28571429rem",
                  height: "38px",
                  paddingLeft: "10px",
                }}
                placeholder="차주운임 입력"
                onChange={(e) => commonStore.setLogisFee(e.target.value)}
                value={commonStore.logisFee || ""}
              />
            </Form.Field>
            <Form.Field
              style={{ width: "33%", float: "left", marginRight: "1%" }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                수수료
              </p>
              <input
                style={{
                  width: "100%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                  borderRadius: "0.28571429rem",
                  height: "38px",
                  paddingLeft: "10px",
                }}
                placeholder="수수료 입력"
                onChange={(e) => commonStore.setCommission(e.target.value)}
                value={commonStore.commission || ""}
              />
            </Form.Field>
            <Form.Field style={{ width: "33%", float: "left" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                총운임
              </p>
              <input
                disabled
                style={{
                  width: "100%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                  borderRadius: "0.28571429rem",
                  height: "38px",
                  paddingLeft: "10px",
                  color: "red",
                  fontWeight: "700",
                }}
                placeholder="0"
                value={
                  Number(commonStore.logisFee) + Number(commonStore.commission)
                }
              />
            </Form.Field>
          </div>

          <div style={{ width: "100%", height: "70px", marginTop: "18px" }}>
            <Form.Field
              style={{ width: "32%", float: "left", marginRight: "1%" }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                결제방법
              </p>
              <Select
                style={{
                  width: "100%",
                  float: "left",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                }}
                placeholder={"결제방법선택"}
                value={commonStore.payCd}
                options={PayCdList}
                onChange={(e, { value }) => commonStore.setPayCd(value)}
              />
            </Form.Field>
            <Form.Field
              style={{ width: "33%", float: "left", marginRight: "1%" }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                정보망 선택
              </p>
              <Select
                style={{
                  width: "100%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                }}
                placeholder={"정보망 선택"}
                value={commonStore.networkCd}
                options={networkCdList}
                onChange={(e, { value }) => commonStore.setNetworkCd(value)}
              />
            </Form.Field>
            <Form.Field style={{ width: "33%", float: "left" }}>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1rem",
                  color: COLOR_FONT_BLACK,
                }}
              >
                연락처<span style={{ color: "red" }}>*</span>
              </p>
              <input
                style={{
                  width: "100%",
                  background: COLOR_INPUT_GRAY,
                  border: "0px",
                  borderRadius: "0.28571429rem",
                  height: "38px",
                  paddingLeft: "10px",
                }}
                placeholder="화주 연락처 입력"
                onChange={(e) => commonStore.setHpNo(e.target.value)}
                value={commonStore.hpNo || ""}
              />
            </Form.Field>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OrderManagement);
