export const TermsList = [
  { title: "제 1조", content: "제1조내용~~~" },
  { title: "제 2조", content: "제2조내용~~~" },
  { title: "제 3조", content: "제3조내용~~~" },
  { title: "제 4조", content: "제4조내용~~~" },
  { title: "제 5조", content: "제5조내용~~~" },
  { title: "제 6조", content: "제6조내용~~~" },
  { title: "제 7조", content: "제7조내용~~~" },
  { title: "제 8조", content: "제8조내용~~~" },
  { title: "제 9조", content: "제9조내용~~~" },
  { title: "제 10조", content: "제10조내용~~~" },
];

export const PrivacyTremsList = [
  { title: "제 1조", content: "제1조내용~~~" },
  { title: "제 2조", content: "제2조내용~~~" },
  { title: "제 3조", content: "제3조내용~~~" },
  { title: "제 4조", content: "제4조내용~~~" },
  { title: "제 5조", content: "제5조내용~~~" },
  { title: "제 6조", content: "제6조내용~~~" },
  { title: "제 7조", content: "제7조내용~~~" },
  { title: "제 8조", content: "제8조내용~~~" },
  { title: "제 9조", content: "제9조내용~~~" },
  { title: "제 10조", content: "제10조내용~~~" },
];

export const LocationTremsList = [
  { title: "제 1조", content: "제1조내용~~~" },
  { title: "제 2조", content: "제2조내용~~~" },
  { title: "제 3조", content: "제3조내용~~~" },
  { title: "제 4조", content: "제4조내용~~~" },
  { title: "제 5조", content: "제5조내용~~~" },
  { title: "제 6조", content: "제6조내용~~~" },
  { title: "제 7조", content: "제7조내용~~~" },
  { title: "제 8조", content: "제8조내용~~~" },
  { title: "제 9조", content: "제9조내용~~~" },
  { title: "제 10조", content: "제10조내용~~~" },
];
