import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_BLACK,
  COLOR_FONT_GRAY,
  COLOR_GRAY_GR06,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import { inject, observer } from "mobx-react";
import { ReactComponent as ServiceInfo1 } from "../../assets/serviceInfo1.svg";
import { ReactComponent as ServiceInfo2 } from "../../assets/serviceInfo2.svg";
import { ReactComponent as ServiceInfo3 } from "../../assets/serviceInfo3.svg";
import MediaQuery from "react-responsive";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class ServiceInfo extends React.Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                회사소개
              </p>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                빠르고 정확한 개별화물배송! 차별화된 서비스
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "50%",
                    height: "100%",
                    color:
                      commonStore.path == "introduce"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "introduce" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("introduce")}
                >
                  회사소개
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "50%",
                    height: "100%",

                    color:
                      commonStore.path == "serviceInfo"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "serviceInfo" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("serviceInfo")}
                >
                  배송시스템
                </li>
              </ul>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                배송시스템
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                  marginBottom: "0px",
                }}
              >
                운송 모든 과정은 GPS를 이용하여 실시간으로
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                }}
              >
                모니터링하며, 고객님께 신속하고 안전하게 운송됩니다.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                margin: "0 auto",
                marginTop: "70px",
                marginBottom: "50px",
                paddingLeft: "5%",
                paddingRight: "5%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <ServiceInfo1 />
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.75rem",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  온라인 / 전화접수
                </p>
                <p style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                  실시간 온라인 배차 접수
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <ServiceInfo2 />
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.75rem",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  기사 배정 및 관제
                </p>
                <p
                  style={{
                    fontWeight: "300",
                    fontSize: "1.25rem",
                    marginBottom: "0px",
                  }}
                >
                  GPS를 통해 최적의 기사배정
                </p>
                <p style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                  운송정보 및 운송상태 알림
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <ServiceInfo3 />
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.75rem",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  물품인수 / 배송완료
                </p>
                <p
                  style={{
                    fontWeight: "300",
                    fontSize: "1.25rem",
                    marginBottom: "0px",
                  }}
                >
                  운송정보 및 고객정보를 바탕으로
                </p>
                <p style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                  물건 상차 후 고객요청 시간 내 하차 완료
                </p>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769} maxWidth={1249}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                회사소개
              </p>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                빠르고 정확한 개별화물배송! 차별화된 서비스
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "introduce"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "introduce"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "introduce"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "introduce" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("introduce")}
                >
                  회사소개
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "serviceInfo"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "serviceInfo"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "serviceInfo"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "serviceInfo" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("serviceInfo")}
                >
                  배송시스템
                </li>
              </ul>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                배송시스템
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                  marginBottom: "0px",
                }}
              >
                운송 모든 과정은 GPS를 이용하여 실시간으로
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                }}
              >
                모니터링하며, 고객님께 신속하고 안전하게 운송됩니다.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                margin: "0 auto",
                marginTop: "70px",
                marginBottom: "50px",
                paddingLeft: "10%",
                paddingRight: "10%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <ServiceInfo1 />
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.75rem",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  온라인 / 전화접수
                </p>
                <p style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                  실시간 온라인 배차 접수
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <ServiceInfo2 />
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.75rem",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  기사 배정 및 관제
                </p>
                <p
                  style={{
                    fontWeight: "300",
                    fontSize: "1.25rem",
                    marginBottom: "0px",
                  }}
                >
                  GPS를 통해 최적의 기사배정
                </p>
                <p style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                  운송정보 및 운송상태 알림
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <ServiceInfo3 />
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.75rem",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  물품인수 / 배송완료
                </p>
                <p
                  style={{
                    fontWeight: "300",
                    fontSize: "1.25rem",
                    marginBottom: "0px",
                  }}
                >
                  운송정보 및 고객정보를 바탕으로
                </p>
                <p style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                  물건 상차 후 고객요청 시간 내 하차 완료
                </p>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1250}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                회사소개
              </p>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                빠르고 정확한 개별화물배송! 차별화된 서비스
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "introduce"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "introduce"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "introduce"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "introduce" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("introduce")}
                >
                  회사소개
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "serviceInfo"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "serviceInfo"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "serviceInfo"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "serviceInfo" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("serviceInfo")}
                >
                  배송시스템
                </li>
              </ul>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                배송시스템
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                  marginBottom: "0px",
                }}
              >
                운송 모든 과정은 GPS를 이용하여 실시간으로
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                }}
              >
                모니터링하며, 고객님께 신속하고 안전하게 운송됩니다.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                margin: "0 auto",
                marginTop: "70px",
                marginBottom: "50px",
              }}
            >
              <div
                style={{
                  width: "33%",
                  textAlign: "center",
                }}
              >
                <ServiceInfo1 />
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.75rem",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  온라인 / 전화접수
                </p>
                <p style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                  실시간 온라인 배차 접수
                </p>
              </div>
              <div
                style={{
                  width: "33%",
                  textAlign: "center",
                }}
              >
                <ServiceInfo2 />
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.75rem",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  기사 배정 및 관제
                </p>
                <p
                  style={{
                    fontWeight: "300",
                    fontSize: "1.25rem",
                    marginBottom: "0px",
                  }}
                >
                  GPS를 통해 최적의 기사배정
                </p>
                <p style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                  운송정보 및 운송상태 알림
                </p>
              </div>
              <div
                style={{
                  width: "33%",
                  textAlign: "center",
                }}
              >
                <ServiceInfo3 />
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "1.75rem",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  물품인수 / 배송완료
                </p>
                <p
                  style={{
                    fontWeight: "300",
                    fontSize: "1.25rem",
                    marginBottom: "0px",
                  }}
                >
                  운송정보 및 고객정보를 바탕으로
                </p>
                <p style={{ fontWeight: "300", fontSize: "1.25rem" }}>
                  물건 상차 후 고객요청 시간 내 하차 완료
                </p>
              </div>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(ServiceInfo);
