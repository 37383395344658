import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Pagination, Table, Button, Checkbox } from "semantic-ui-react";
import _ from "lodash";
import {
  COLOR_BLACK,
  COLOR_GRAY_GR06,
  COLOR_INPUT_GRAY,
  COLOR_PINK_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import axios from "axios";
import {
  API_SELECT_COMPLETE_FREIGHT_LIST_BY_DATE,
  API_SELECT_COMPLETE_FREIGHT_LIST_BY_DRIVER_ID,
  API_UPDATE_SETTLEMENT_BULK,
} from "../../common/ApiAddress/FrightAPI";
@inject((stores) => ({
  commonStore: stores.CommonStore,
  freightStore: stores.FreightStore,
}))
@observer
class SettlementManagement extends Component {
  state = {
    searchTyp: "A",
    searchKeyword: null,
    startDate: null,
    endDate: null,
  };

  componentDidMount() {
    const { freightStore } = this.props;
    freightStore.initFreightList();
    freightStore.initTotalPage();
  }

  fnSelectFreightList = (currentPage) => {
    const { freightStore } = this.props;
    let params = {};
    if (currentPage == undefined || currentPage == 1) {
      params.startCnt = 0;
      params.endCnt = 20;
    } else {
      params.startCnt = (currentPage - 1) * 20;
      params.endCnt = 20;
    }

    if (this.state.startDate == null) {
      params.startDate = moment().format("YYYY-MM-DD 00:00:00");
      params.endDate = moment().add("day", 1).format("YYYY-MM-DD 00:00:00");
    } else {
      params.startDate = moment(this.state.startDate).format(
        "YYYY-MM-DD 00:00:00"
      );
      params.endDate = moment(this.state.endDate)
        .add("day", 1)
        .format("YYYY-MM-DD 00:00:00");
    }

    if (this.state.searchTyp == "V") {
      params.driverId = this.state.searchKeyword;
    }
    axios({
      url:
        this.state.searchTyp == "D"
          ? API_SELECT_COMPLETE_FREIGHT_LIST_BY_DATE
          : this.state.searchTyp == "V"
          ? API_SELECT_COMPLETE_FREIGHT_LIST_BY_DRIVER_ID
          : null,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          freightStore.setFreightList(response.data.data);
          freightStore.setTotalPage(response.data.totalPageNum);
        } else {
          freightStore.initFreightList();
          freightStore.initTotalPage();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetDate = (update) => {
    console.log(update);
    this.setState({
      startDate: update[0],
      endDate: update[1],
    });
  };

  fnOnchageSelectedFreightList = (checked, data) => {
    const { freightStore } = this.props;
    if (checked == true) {
      freightStore.addSelectedFreightList(data);
    } else {
      freightStore.removeSelectedFreightList(data);
    }
  };

  fnUpdateSettlementYnBulk = () => {
    const { freightStore } = this.props;
    let params = {};
    params.selectedFreightList = freightStore.selectedFreightList;
    params.settlementYn = "Y";

    axios({
      url: API_UPDATE_SETTLEMENT_BULK,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { freightStore, commonStore } = this.props;

    return (
      <Fragment>
        <div
          style={{
            width: "60%",
            padding: "20px",
            height: "100px",
            border: "1px solid",
            borderRadius: "10px",
            margin: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <p
            style={{ marginBottom: "0px", marginRight: "1%", fontSize: "16px" }}
          >
            검색 조건
          </p>
          <span style={{ marginRight: "1%", fontSize: "16px" }}>기간</span>
          <div style={{ marginRight: "1%" }}>
            <DatePicker
              className="freightCalendar"
              locale="ko"
              selectsRange={true}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={(update) => {
                this.fnSetDate(update);
                this.setState({
                  searchTyp: "D",
                });
              }}
              dateFormat={"yyyy-MM-dd"}
              disabled={
                this.state.searchTyp != "D" && this.state.searchTyp != "A"
              }
            />
          </div>
          <Button
            color="purple"
            style={{ marginRight: "3%" }}
            onClick={() => {
              this.setState({
                startDate: null,
                endDate: null,
                searchTyp: "A",
              });
            }}
          >
            날짜 초기화
          </Button>
          <input
            disabled={
              this.state.searchTyp != "V" && this.state.searchTyp != "A"
            }
            style={{
              width: "20%",
              border: "1px solid",
              borderRadius: "0.28571429rem",
              height: "38px",
              paddingLeft: "10px",
            }}
            placeholder={"차량번호로 검색"}
            onChange={(e) => {
              if (e.target.value != "") {
                this.setState({
                  searchTyp: "V",
                  searchKeyword: e.target.value,
                });
              } else {
                this.setState({
                  searchTyp: "A",
                  searchKeyword: e.target.value,
                });
              }
              this.setState({
                searchKeyword: e.target.value,
              });
            }}
            value={this.state.searchKeyword || ""}
          />
          <Button
            color="teal"
            style={{ marginLeft: "3%" }}
            onClick={() => {
              this.fnSelectFreightList();
            }}
          >
            조회
          </Button>
          <Button
            color="brown"
            style={{ marginLeft: "3%" }}
            onClick={() => {
              this.fnUpdateSettlementYnBulk();
            }}
          >
            선택 정산완료 처리
          </Button>
        </div>
        <Table style={{ border: "0px", width: "100%", padding: "20px" }}>
          {freightStore.freightList == null ||
          freightStore.freightList.length == 0 ? null : (
            <Table.Header style={{ border: "0px" }}>
              <Table.Row>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    background: "#fff",
                    width: "3%",
                  }}
                >
                  선택
                </Table.HeaderCell>

                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    background: "#fff",
                    borderLeft: "0px",
                    width: "5%",
                  }}
                >
                  등록일시
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                    width: "7%",
                  }}
                >
                  상차지
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                    width: "7%",
                  }}
                >
                  하차지
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                    width: "7%",
                  }}
                >
                  화물정보
                </Table.HeaderCell>

                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  전체운임
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  기사운임
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  수수료
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  정산여부
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  정산금액
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  기사정보
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{
                    borderBottom: "1px solid #CED4DA",
                    borderLeft: "0px",
                    background: "#fff",
                  }}
                >
                  기사 톤수/차종
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          {freightStore.freightList == null ||
          freightStore.freightList.length == 0 ? (
            <Table.Body>
              <Table.Row
                style={{ height: "400px", fontSize: "20px", fontWeight: "600" }}
              >
                <Table.Cell colSpan="14" textAlign="center">
                  날짜 또는 차량번호로 조회해 주세요.
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : (
            <Table.Body>
              {freightStore.freightList.map((obj, i) => (
                <React.Fragment key={obj.freightNo}>
                  <Table.Row
                    //onClick={() => this.props.fnSelectedFreight(data)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Table.Cell>
                      <Checkbox
                        onClick={(e, data) =>
                          this.fnOnchageSelectedFreightList(data.checked, obj)
                        }
                      />
                    </Table.Cell>

                    <Table.Cell style={{ width: "10%" }}>
                      {moment(obj.registDt).format("YYYY-MM-DD HH:mm")}
                    </Table.Cell>
                    <Table.Cell style={{ width: "15%" }}>
                      {obj.loadingSido +
                        " " +
                        obj.loadingSigu +
                        " " +
                        obj.loadingDong +
                        " " +
                        obj.loadingDetailAdd}
                    </Table.Cell>
                    <Table.Cell style={{ width: "15%" }}>
                      {obj.unloadingSido +
                        " " +
                        obj.unloadingSigu +
                        " " +
                        obj.unloadingDong +
                        " " +
                        obj.unloadingDetailAdd}
                    </Table.Cell>
                    <Table.Cell style={{ width: "10%" }}>
                      {obj.freightInfo}
                    </Table.Cell>
                    <Table.Cell style={{ width: "5%" }}>
                      {obj.totalFee != undefined
                        ? obj.totalFee
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                        : "미정"}
                    </Table.Cell>
                    <Table.Cell style={{ width: "5%" }}>
                      {obj.logisFee != undefined
                        ? obj.logisFee
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                        : "미정"}
                    </Table.Cell>
                    <Table.Cell style={{ width: "5%" }}>
                      {obj.commission != undefined
                        ? obj.commission
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                        : "미정"}
                    </Table.Cell>
                    <Table.Cell style={{ width: "5%" }}>
                      {obj.settlementYn}
                    </Table.Cell>
                    <Table.Cell style={{ width: "5%" }}>
                      {obj.logisFee != undefined
                        ? obj.logisFee
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                        : "미정"}
                    </Table.Cell>
                    <Table.Cell style={{ width: "13%" }}>
                      <sapn>
                        {obj.driverNm != undefined ? obj.driverNm + "/" : ""}
                      </sapn>
                      <sapn>
                        {obj.driverId != undefined ? obj.driverId + "/" : ""}
                      </sapn>
                      <sapn>
                        {obj.driverHpNo != undefined ? obj.driverHpNo : ""}
                      </sapn>
                    </Table.Cell>
                    <Table.Cell style={{ width: "8%" }}>
                      <sapn>
                        {obj.driverVcTyp != undefined
                          ? obj.driverVcTyp + "/"
                          : ""}
                      </sapn>
                      <sapn>
                        {obj.driverVcWeight != undefined
                          ? obj.driverVcWeight
                          : ""}
                      </sapn>
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              ))}
            </Table.Body>
          )}
        </Table>
        <div style={{ textAlign: "center" }}>
          <Pagination
            onPageChange={(e, data) => {
              this.props.fnSelectFreightList(data.activePage);
            }}
            defaultActivePage={freightStore.currentPage}
            id="pagination"
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={freightStore.totalPage}
            style={{ border: "0px" }}
          />
        </div>
      </Fragment>
    );
  }
}

export default SettlementManagement;
