import CommonStore from "./CommonStore";
import QuoteStore from "./QuoteStore";
import DriverStore from "./DriverStore";
import AccStore from "./AccStore";
import CompStore from "./CompStore";
import FreightStore from "./FreightStore";
import DashBoardStore from "./DashBoardStore";

class RootStore {
  constructor() {
    this.CommonStore = new CommonStore(this);
    this.QuoteStore = new QuoteStore(this);
    this.DriverStore = new DriverStore(this);
    this.AccStore = new AccStore(this);
    this.CompStore = new CompStore(this);
    this.FreightStore = new FreightStore(this);
    this.DashBoardStore = new DashBoardStore(this);
  }
}

export default RootStore;
