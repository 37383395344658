import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_BLACK,
  COLOR_FONT_GRAY,
  COLOR_PRIMARY,
  COLOR_WHITE,
  COLOR_GRAY_GR06,
} from "../../common/Color";
import { inject, observer } from "mobx-react";
import { ReactComponent as Cargo5T } from "../../assets/5tcargo.svg";
import { ReactComponent as Topvc2 } from "../../assets/topvc2.svg";
import { ReactComponent as Topvc5 } from "../../assets/topvc5.svg";
import { ReactComponent as Wingvc2 } from "../../assets/wingvc2.svg";
import { ReactComponent as Liftvc } from "../../assets/liftvc.svg";
import { ReactComponent as Liftvc5 } from "../../assets/liftvc5.svg";
import MediaQuery from "react-responsive";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class VcInfo5 extends React.Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "171px",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                1톤 / 2.5톤 / 5톤
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.4rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                언제든지 상담 요청 주시면
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.4rem",
                  color: COLOR_WHITE,
                }}
              >
                빠른 배차로 보답하겠습니다.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  width: "100%",
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "100%",
                    color:
                      commonStore.path == "VcInfo1"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "VcInfo1" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("VcInfo1")}
                >
                  1톤
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "100%",
                    color:
                      commonStore.path == "VcInfo2"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "VcInfo2" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    borderRightWidth:
                      commonStore.path == "VcInfo2" ? "0px" : "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("VcInfo2")}
                >
                  2.5톤
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "100%",

                    color:
                      commonStore.path == "VcInfo5"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "VcInfo5" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("VcInfo5")}
                >
                  5톤
                </li>
              </ul>
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
                marginTop: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "500px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Cargo5T width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      파렛제품
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      모든물건
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 카고
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    적재물건의 형태나 적재방법에 제약이 없고 차종에 따른 중량만
                    고려하는 편리한 차량
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "500px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                    textAlign: "center",
                  }}
                >
                  <Topvc5 width={"80%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      지게차
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      안전운송
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 탑차
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    우천 시 매우 우용하며, 탑차 내부 안전 고정밧줄로 마무리 하여
                    안전하게 운송 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "500px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Wingvc2 width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      우천운송
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      안전운송
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      지게차
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 윙카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    우천에 약한 제품 및 외부의 충격을 최소화하여 안전하게
                    운송해야 하는 물품
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "500px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                    textAlign: "center",
                  }}
                >
                  <Liftvc5 width={"80%"} height={"239px"} />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      안전상하차
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 리프트카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    부피가 크고 안정된 상.하차를 필요로 하는 화물을 취급하는
                    차량
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769} maxWidth={1249}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                1톤 / 2.5톤 / 5톤
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                }}
              >
                언제든지 상담 요청 주시면 빠른 배차로 보답하겠습니다.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "VcInfo1"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "VcInfo1"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "VcInfo1" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "VcInfo1" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("VcInfo1")}
                >
                  1톤
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "VcInfo2"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "VcInfo2"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "VcInfo2" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "VcInfo2" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    borderLeftWidth:
                      commonStore.path == "VcInfo2" ? "0px" : "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("VcInfo2")}
                >
                  2.5톤
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "VcInfo5"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "VcInfo5"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "VcInfo5" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "VcInfo5" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("VcInfo5")}
                >
                  5톤
                </li>
              </ul>
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "10%",
                paddingRight: "10%",
                marginTop: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Cargo5T width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      파렛제품
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      모든물건
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 카고
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    적재물건의 형태나 적재방법에 제약이 없고 차종에 따른 중량만
                    고려하는 편리한 차량
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                    textAlign: "center",
                  }}
                >
                  <Topvc5 width={"80%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      지게차
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      안전운송
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 탑차
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    우천 시 매우 우용하며, 탑차 내부 안전 고정밧줄로 마무리 하여
                    안전하게 운송 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Wingvc2 width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      우천운송
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      안전운송
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      지게차
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 윙카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    우천에 약한 제품 및 외부의 충격을 최소화하여 안전하게
                    운송해야 하는 물품
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                    textAlign: "center",
                  }}
                >
                  <Liftvc5 width={"80%"} height={"239px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      안전상하차
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 리프트카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    부피가 크고 안정된 상.하차를 필요로 하는 화물을 취급하는
                    차량
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1250}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                1톤 / 2.5톤 / 5톤
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                }}
              >
                언제든지 상담 요청 주시면 빠른 배차로 보답하겠습니다.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "VcInfo1"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "VcInfo1"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "VcInfo1" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "VcInfo1" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("VcInfo1")}
                >
                  1톤
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "VcInfo2"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "VcInfo2"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "VcInfo2" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "VcInfo2" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    borderLeftWidth:
                      commonStore.path == "VcInfo2" ? "0px" : "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("VcInfo2")}
                >
                  2.5톤
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "VcInfo5"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "VcInfo5"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "VcInfo5" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "VcInfo5" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("VcInfo5")}
                >
                  5톤
                </li>
              </ul>
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "20%",
                paddingRight: "20%",
                marginTop: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Cargo5T width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      파렛제품
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      모든물건
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 카고
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    적재물건의 형태나 적재방법에 제약이 없고 차종에 따른 중량만
                    고려하는 편리한 차량
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                    textAlign: "center",
                  }}
                >
                  <Topvc5 width={"80%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      지게차
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      안전운송
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 탑차
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    우천 시 매우 우용하며, 탑차 내부 안전 고정밧줄로 마무리 하여
                    안전하게 운송 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Wingvc2 width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      우천운송
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      안전운송
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      지게차
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 윙카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    우천에 약한 제품 및 외부의 충격을 최소화하여 안전하게
                    운송해야 하는 물품
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                    textAlign: "center",
                  }}
                >
                  <Liftvc5 width={"80%"} height={"239px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      전국화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      안전상하차
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    5톤 리프트카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 230 X 세로길이 700 X 높이 240 (적재중량 5톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    부피가 크고 안정된 상.하차를 필요로 하는 화물을 취급하는
                    차량
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    5000KG까지 화물 운송
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(VcInfo5);
