import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_BLACK,
  COLOR_FONT_GRAY,
  COLOR_GRAY_GR06,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import { inject, observer } from "mobx-react";
import { ReactComponent as Damas } from "../../assets/damas.svg";
import { ReactComponent as Labo } from "../../assets/labo.svg";
import { ReactComponent as Cargo1T } from "../../assets/1tcargo.svg";
import { ReactComponent as Topvc } from "../../assets/topvc.svg";
import { ReactComponent as Wingvc } from "../../assets/wingvc.svg";
import { ReactComponent as Liftvc } from "../../assets/liftvc.svg";
import MediaQuery from "react-responsive";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class BasicFreight extends React.Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "171px",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                개인화물 / 전국화물 / 차량제원
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.4rem",
                  color: COLOR_WHITE,
                }}
              >
                에이치 전국화물과 편리한 운송을 경험해보세요.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  width: "100%",
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "100%",
                    color:
                      commonStore.path == "BasicFreight"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "BasicFreight" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("BasicFreight")}
                >
                  개인화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "100%",
                    color:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "AllLocaFreight" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllLocaFreight")}
                >
                  전국화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "100%",

                    color:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "AllVcInfo" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllVcInfo")}
                >
                  차량제원
                </li>
              </ul>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
                paddingRight: "5%",
                paddingLeft: "5%",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                개인화물
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                  marginBottom: "0px",
                }}
              >
                비교적 가까운 거리의 구간에 대해서 적은 수량의 단품운송 및
                부피가 작은 물품들을 신속, 안전, 정확한 시간에 당일 운송합니다.
              </p>
            </div>

            <div
              style={{
                width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
                marginTop: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "550px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Damas width={"100%"} />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      박스짐
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      간단짐
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      단품운송
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    다마스
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 110cm X 세로길이 160cm X 높이 110cm
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    400kg 미만의 배송물로서 라면박스 20개 정도의 소화물
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    운동기구, 테이블/의자, 애기침대, 서랍장, 화분, 꽃배달,
                    화분이나
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    박스짐, 세탁기,미니냉장고, 에어컨운반, 사무기기, 택배회사
                    거절 품목 등
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "500px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Labo width={"100%"} />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      소형가구
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      중형가전
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      간단이사
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    라보
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 130cm X 세로길이 200cm
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    500kg 미만의 중량물 다마스 보다 큰 물건도 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    건축자재, 공업용기계, 신문사의 지국 배송물, 행사용 악기,
                    장농, 진열장, 운동기구,
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    가구, 나무나 화환, 화분, 가전제품, 피아노, 스쿠터, 사무기기
                    등
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "500px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Cargo1T width={"100%"} />
                </div>
                <div
                  style={{
                    width: "100",
                    height: "250px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      각종자재
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      공장납품
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    카고
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    800kg 이상의 화물배송 라보 보다 많은 물건도 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    중량과 부피가 매우 큰 물건으로 대부분의 일반화물(파렛트),
                    건축자재, 금형, 대량의 박스 등
                  </p>
                </div>
              </div>

              {/* 탑차 */}
              <div
                style={{
                  width: "100%",
                  height: "500px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Topvc width={"100%"} />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      냉장/냉동
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    탑차
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    800kg 이상의 화물배송 라보 보다 많은 물건도 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    우천에 약한 제품 및 외부의 충격을 최소화하여 안전하게
                    운송해야 하는 물품
                  </p>
                </div>
              </div>
              {/* 윙카 */}
              <div
                style={{
                  width: "100%",
                  height: "500px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Wingvc width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      지게차
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    윙카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    개인화물서비스 보다 많은 물건도 가능 (지게차 운반가능)
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    우천에 약한 제품 및 외부의 충격을 최소화하여 안전하게
                    운송해야 하는 물품
                  </p>
                </div>
              </div>
              {/* 리프트카 */}
              <div
                style={{
                  width: "100%",
                  height: "500px",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Liftvc width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    리프트카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.263rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    부피가 크고 안정된 상.하차를 필요로 하는 화물을 취급하는
                    차량
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    500~1300KG의 안정상하차를 요하는 물품
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769} maxWidth={1249}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                개인화물 / 전국화물 / 차량제원
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                }}
              >
                에이치 전국화물과 편리한 운송을 경험해보세요.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "BasicFreight"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "BasicFreight"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "BasicFreight"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "BasicFreight" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("BasicFreight")}
                >
                  개인화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "AllLocaFreight"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "AllLocaFreight" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    borderRightWidth:
                      commonStore.path == "BasicFreight" ? "0px" : "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllLocaFreight")}
                >
                  전국화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "AllVcInfo"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "AllVcInfo" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllVcInfo")}
                >
                  차량제원
                </li>
              </ul>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                개인화물
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                  marginBottom: "0px",
                }}
              >
                비교적 가까운 거리의 구간에 대해서 적은 수량의 단품운송 및
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                }}
              >
                부피가 작은 물품들을 신속, 안전, 정확한 시간에 당일 운송합니다.
              </p>
            </div>

            <div
              style={{
                width: "100%",
                paddingLeft: "10%",
                paddingRight: "10%",
                marginTop: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Damas width={"100%"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      박스짐
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      간단짐
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      단품운송
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    다마스
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 110cm X 세로길이 160cm X 높이 110cm
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    400kg 미만의 배송물로서 라면박스 20개 정도의 소화물
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    운동기구, 테이블/의자, 애기침대, 서랍장, 화분, 꽃배달,
                    화분이나
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    박스짐, 세탁기,미니냉장고, 에어컨운반, 사무기기, 택배회사
                    거절 품목 등
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Labo width={"100%"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      소형가구
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      중형가전
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      간단이사
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    라보
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 130cm X 세로길이 200cm
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    500kg 미만의 중량물 다마스 보다 큰 물건도 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    건축자재, 공업용기계, 신문사의 지국 배송물, 행사용 악기,
                    장농, 진열장, 운동기구,
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    가구, 나무나 화환, 화분, 가전제품, 피아노, 스쿠터, 사무기기
                    등
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Cargo1T width={"100%"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      각종자재
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      공장납품
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    카고
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    800kg 이상의 화물배송 라보 보다 많은 물건도 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    중량과 부피가 매우 큰 물건으로 대부분의 일반화물(파렛트),
                    건축자재, 금형, 대량의 박스 등
                  </p>
                </div>
              </div>

              {/* 탑차 */}
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Topvc width={"100%"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      냉장/냉동
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    탑차
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    800kg 이상의 화물배송 라보 보다 많은 물건도 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    우천에 약한 제품 및 외부의 충격을 최소화하여 안전하게
                    운송해야 하는 물품
                  </p>
                </div>
              </div>
              {/* 윙카 */}
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Wingvc width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      지게차
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    윙카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    개인화물서비스 보다 많은 물건도 가능 (지게차 운반가능)
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    우천에 약한 제품 및 외부의 충격을 최소화하여 안전하게
                    운송해야 하는 물품
                  </p>
                </div>
              </div>
              {/* 리프트카 */}
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Liftvc width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    리프트카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    부피가 크고 안정된 상.하차를 필요로 하는 화물을 취급하는
                    차량
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    500~1300KG의 안정상하차를 요하는 물품
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1250}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                개인화물 / 전국화물 / 차량제원
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                }}
              >
                에이치 전국화물과 편리한 운송을 경험해보세요.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "BasicFreight"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "BasicFreight"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "BasicFreight"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "BasicFreight" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("BasicFreight")}
                >
                  개인화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "AllLocaFreight"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "AllLocaFreight" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    borderRightWidth:
                      commonStore.path == "BasicFreight" ? "0px" : "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllLocaFreight")}
                >
                  전국화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "AllVcInfo"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "AllVcInfo" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllVcInfo")}
                >
                  차량제원
                </li>
              </ul>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                개인화물
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                  marginBottom: "0px",
                }}
              >
                비교적 가까운 거리의 구간에 대해서 적은 수량의 단품운송 및
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                }}
              >
                부피가 작은 물품들을 신속, 안전, 정확한 시간에 당일 운송합니다.
              </p>
            </div>

            <div
              style={{
                width: "100%",
                paddingLeft: "20%",
                paddingRight: "20%",
                marginTop: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Damas width={"100%"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      박스짐
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      간단짐
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      단품운송
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    다마스
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 110cm X 세로길이 160cm X 높이 110cm
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    400kg 미만의 배송물로서 라면박스 20개 정도의 소화물
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    운동기구, 테이블/의자, 애기침대, 서랍장, 화분, 꽃배달,
                    화분이나
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    박스짐, 세탁기,미니냉장고, 에어컨운반, 사무기기, 택배회사
                    거절 품목 등
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Labo width={"100%"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      소형가구
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      중형가전
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      간단이사
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    라보
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 130cm X 세로길이 200cm
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    500kg 미만의 중량물 다마스 보다 큰 물건도 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    건축자재, 공업용기계, 신문사의 지국 배송물, 행사용 악기,
                    장농, 진열장, 운동기구,
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    가구, 나무나 화환, 화분, 가전제품, 피아노, 스쿠터, 사무기기
                    등
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Cargo1T width={"100%"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      각종자재
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      공장납품
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    카고
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    800kg 이상의 화물배송 라보 보다 많은 물건도 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    중량과 부피가 매우 큰 물건으로 대부분의 일반화물(파렛트),
                    건축자재, 금형, 대량의 박스 등
                  </p>
                </div>
              </div>

              {/* 탑차 */}
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Topvc width={"100%"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      냉장/냉동
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    탑차
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    800kg 이상의 화물배송 라보 보다 많은 물건도 가능
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    우천에 약한 제품 및 외부의 충격을 최소화하여 안전하게
                    운송해야 하는 물품
                  </p>
                </div>
              </div>
              {/* 윙카 */}
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Wingvc width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      지게차
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    윙카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    개인화물서비스 보다 많은 물건도 가능 (지게차 운반가능)
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    우천에 약한 제품 및 외부의 충격을 최소화하여 안전하게
                    운송해야 하는 물품
                  </p>
                </div>
              </div>
              {/* 리프트카 */}
              <div
                style={{
                  width: "100%",
                  height: "17.313rem",
                  marginBottom: "30px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    float: "left",
                    height: "100%",
                    background: COLOR_WHITE,
                    border: "2px solid #03C75A",
                  }}
                >
                  <Liftvc width={"100%"} height={"238px"} />
                </div>
                <div
                  style={{
                    width: "67%",
                    height: "100%",
                    background: COLOR_BACKGROUND_GRAY,
                    overflow: "hidden",
                    paddingLeft: "20px",
                    paddingTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "2px solid #F5F5F5",
                  }}
                >
                  <p>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반용달
                    </span>
                    <span
                      style={{
                        background: COLOR_PRIMARY,
                        color: COLOR_WHITE,
                        padding: "5px",
                        borderRadius: "5px",
                        marginRight: "1.5%",
                      }}
                    >
                      일반화물
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: "2.188rem",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    리프트카
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "30px",
                    }}
                  >
                    가로폭 160cm X 세로길이 280cm (적재중량 1톤)
                  </p>
                  <p
                    style={{
                      fontSize: "1.363rem",
                      fontWeight: "400",
                      marginBottom: "0px",
                    }}
                  >
                    부피가 크고 안정된 상.하차를 필요로 하는 화물을 취급하는
                    차량
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    500~1300KG의 안정상하차를 요하는 물품
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(BasicFreight);
