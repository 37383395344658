import React, { Component, Fragment } from "react";
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Modal,
  Select,
  Form,
  TextArea,
  Checkbox,
} from "semantic-ui-react";
import { freightInfoList, vcTypList, vcWeightList } from "../../common/VcData";

import {
  COLOR_BTN_DEEP_GRAY,
  COLOR_INPUT_GRAY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import { inject, observer } from "mobx-react";
import MediaQuery from "react-responsive";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class FreightInsertModal extends React.Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <Modal
            onClose={() => this.props.fnFreightModalClose()}
            onOpen={() => this.props.fnFreightModalOpen()}
            open={this.props.freightModalOpen}
            style={{
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              borderBottomLeftRadius: "20px",
              height: "93%",
            }}
          >
            <ModalHeader
              style={{
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                height: "5rem",
                background: COLOR_PRIMARY,
              }}
            >
              <div
                style={{
                  width: "104%",
                  color: COLOR_WHITE,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    float: "left",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: "1.45rem",
                  }}
                >
                  <p>배차접수</p>
                </div>
                <div
                  style={{
                    width: "50%",
                    overflow: "hidden",
                    fontSize: "1.2rem",
                    textAlign: "right",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>1833-8852</p>
                  <p>에이치 전국화물</p>
                </div>
              </div>
            </ModalHeader>
            <ModalContent>
              <div>
                <div style={{ height: "auto" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "260px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "130px",
                        marginRight: "1%",
                      }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        출발지
                      </p>
                      <Form.Field
                        style={{
                          width: "33.3%",
                          float: "left",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "97%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시도 선택"}
                          value={commonStore.selectedSido}
                          options={commonStore.sidoList}
                          onChange={(e, { value }) =>
                            this.props.fnSelectSido(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "33.3%",
                          float: "left",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "97%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시군구 선택"}
                          value={commonStore.selectedSigu}
                          options={commonStore.siguList}
                          onChange={(e, { value }) =>
                            this.props.fnSelectSigu(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "33.3%",
                          float: "left",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "97%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"읍명동 선택"}
                          value={commonStore.selectedDong}
                          options={commonStore.dongList}
                          onChange={(e, { value }) =>
                            commonStore.setSelectedDong(value)
                          }
                        />
                      </Form.Field>
                      <input
                        style={{
                          width: "99%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                          borderRadius: "0.28571429rem",
                          height: "38px",
                          marginTop: "10px",
                          paddingLeft: "10px",
                        }}
                        placeholder={"출발지 상세주소 작성"}
                        onChange={(e) =>
                          commonStore.setLoadingDetailAdd(e.target.value)
                        }
                        value={commonStore.loadingDetailAdd}
                      />
                    </div>

                    <div
                      style={{
                        width: "100%",
                        height: "130px",
                        float: "left",
                      }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        도착지
                      </p>
                      <Form.Field
                        style={{
                          width: "33.3%",
                          float: "left",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "97%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시도 선택"}
                          value={commonStore.unloadingSelectedSido}
                          options={commonStore.unloadingSidoList}
                          onChange={(e, { value }) =>
                            this.props.fnUnloadingSelectSido(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "33.3%",
                          float: "left",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "97%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시군구 선택"}
                          value={commonStore.unloadingSelectedSigu}
                          options={commonStore.unloadingSiguList}
                          onChange={(e, { value }) =>
                            this.props.fnUnloadingSelectSigu(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "33.3%",
                          float: "left",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "97%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"읍명동 선택"}
                          value={commonStore.unloadingSelectedDong}
                          options={commonStore.unloadingDongList}
                          onChange={(e, { value }) =>
                            commonStore.setUnloadingSelectedDong(value)
                          }
                        />
                      </Form.Field>
                      <input
                        style={{
                          width: "99%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                          borderRadius: "0.28571429rem",
                          height: "38px",
                          marginTop: "10px",
                          paddingLeft: "10px",
                        }}
                        placeholder={"도착지 상세주소 작성"}
                        onChange={(e) =>
                          commonStore.setUnloadingDetailAdd(e.target.value)
                        }
                        value={commonStore.unloadingDetailAdd}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "80px",
                    }}
                  >
                    <Form.Field
                      style={{
                        minWidth: "50%",
                        float: "left",
                      }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        톤수
                      </p>
                      <Select
                        style={{
                          minWidth: "97%",
                          maxWidth: "50%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder={"톤수선택"}
                        value={commonStore.vcWeight}
                        options={vcWeightList}
                        onChange={(e, { value }) =>
                          commonStore.setVcWeight(value)
                        }
                      />
                    </Form.Field>
                    <Form.Field
                      style={{
                        minWidth: "50%",
                        float: "left",
                      }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        차종
                      </p>
                      <Select
                        style={{
                          minWidth: "98%",
                          maxWidth: "50%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder={"차종선택"}
                        value={commonStore.vcTyp}
                        options={vcTypList}
                        onChange={(e, { value }) => commonStore.setVcTyp(value)}
                      />
                    </Form.Field>
                  </div>
                  <div style={{ width: "100%", height: "70px" }}>
                    <Form.Field
                      style={{ width: "50%", float: "left", marginRight: "2%" }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        운송물건
                      </p>
                      <Select
                        style={{
                          minWidth: "97%",
                          float: "left",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder={"운송물건선택"}
                        value={commonStore.freightInfo}
                        options={freightInfoList}
                        onChange={(e, { value }) =>
                          commonStore.setFreightInfo(value)
                        }
                        disabled={this.props.textAreaEditable}
                      />
                    </Form.Field>
                    <Form.Field
                      style={{
                        width: "48%",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "31px",
                        height: "70px",
                      }}
                    >
                      <Checkbox
                        label="직접입력"
                        onClick={() => this.props.fnOnchangetextAreaEditable()}
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <Form style={{ width: "100%" }}>
                      <TextArea
                        disabled={!this.props.textAreaEditable}
                        style={{
                          resize: "none",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder="운송물건 직접입력"
                        value={commonStore.freightInfo}
                        onChange={(e, { value }) =>
                          commonStore.setFreightInfo(value)
                        }
                      />
                    </Form>
                  </div>

                  <div style={{ width: "100%", height: "70px" }}>
                    <Form style={{ width: "100%" }}>
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          marginBottom: "0px",
                        }}
                      >
                        연락처
                      </p>
                      <input
                        style={{
                          width: "100%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                          borderRadius: "0.28571429rem",
                          height: "38px",
                          marginTop: "10px",
                          paddingLeft: "10px",
                        }}
                        placeholder="연락처 입력"
                        onChange={(e) => commonStore.setHpNo(e.target.value)}
                        value={commonStore.hpNo}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </ModalContent>
            <ModalActions
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                borderTop: "0px",
                background: COLOR_WHITE,
              }}
            >
              <div
                onClick={() => this.props.fnInsertFreight()}
                style={{
                  width: "35%",
                  height: "50px",
                  fontSize: "20px",
                  background: COLOR_PRIMARY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLOR_WHITE,
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginRight: "3%",
                  fontWeight: "600",
                }}
              >
                <p>배차접수</p>
              </div>
              <div
                onClick={() => this.props.fnFreightModalClose()}
                style={{
                  width: "15%",
                  height: "50px",
                  fontSize: "20px",
                  background: COLOR_BTN_DEEP_GRAY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLOR_WHITE,
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <p>닫기</p>
              </div>
            </ModalActions>
          </Modal>
        </MediaQuery>
        <MediaQuery minWidth={769} maxWidth={1249}>
          <Modal
            onClose={() => this.props.fnFreightModalClose()}
            onOpen={() => this.props.fnFreightModalOpen()}
            open={this.props.freightModalOpen}
            style={{
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              borderBottomLeftRadius: "20px",
            }}
          >
            <ModalHeader
              style={{
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                height: "5rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  color: COLOR_WHITE,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    float: "left",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: "1.425rem",
                  }}
                >
                  <p>배차접수</p>
                </div>
                <div
                  style={{
                    width: "50%",
                    overflow: "hidden",
                    fontSize: "1.2rem",
                    textAlign: "right",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>1833-8852</p>
                  <p>에이치 전국화물</p>
                </div>
              </div>
            </ModalHeader>
            <ModalContent>
              <div>
                <div style={{ height: "500px" }}>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      height: "120px",
                    }}
                  >
                    <div
                      style={{
                        width: "49%",
                        height: "120px",
                        float: "left",
                        marginRight: "1%",
                      }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        출발지
                      </p>
                      <Form.Field
                        style={{
                          width: "32%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시도 선택"}
                          value={commonStore.selectedSido}
                          options={commonStore.sidoList}
                          onChange={(e, { value }) =>
                            this.props.fnSelectSido(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "32%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시군구 선택"}
                          value={commonStore.selectedSigu}
                          options={commonStore.siguList}
                          onChange={(e, { value }) =>
                            this.props.fnSelectSigu(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "33%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"읍명동 선택"}
                          value={commonStore.selectedDong}
                          options={commonStore.dongList}
                          onChange={(e, { value }) =>
                            commonStore.setSelectedDong(value)
                          }
                        />
                      </Form.Field>
                      <input
                        style={{
                          width: "96%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                          borderRadius: "0.28571429rem",
                          height: "38px",
                          marginTop: "10px",
                          paddingLeft: "10px",
                        }}
                        placeholder={"출발지 상세주소 작성"}
                        onChange={(e) =>
                          commonStore.setLoadingDetailAdd(e.target.value)
                        }
                        value={commonStore.loadingDetailAdd}
                      />
                    </div>

                    <div
                      style={{
                        width: "50%",
                        height: "120px",
                        float: "left",
                      }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        도착지
                      </p>
                      <Form.Field
                        style={{
                          width: "32%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시도 선택"}
                          value={commonStore.unloadingSelectedSido}
                          options={commonStore.unloadingSidoList}
                          onChange={(e, { value }) =>
                            this.props.fnUnloadingSelectSido(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "32%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시군구 선택"}
                          value={commonStore.unloadingSelectedSigu}
                          options={commonStore.unloadingSiguList}
                          onChange={(e, { value }) =>
                            this.props.fnUnloadingSelectSigu(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "33%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"읍명동 선택"}
                          value={commonStore.unloadingSelectedDong}
                          options={commonStore.unloadingDongList}
                          onChange={(e, { value }) =>
                            commonStore.setUnloadingSelectedDong(value)
                          }
                        />
                      </Form.Field>
                      <input
                        style={{
                          width: "96%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                          borderRadius: "0.28571429rem",
                          height: "38px",
                          marginTop: "10px",
                          paddingLeft: "10px",
                        }}
                        placeholder={"도착지 상세주소 작성"}
                        onChange={(e) =>
                          commonStore.setUnloadingDetailAdd(e.target.value)
                        }
                        value={commonStore.unloadingDetailAdd}
                      />
                    </div>
                  </div>

                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        톤수
                      </p>
                      <Select
                        style={{
                          width: "96%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder={"톤수선택"}
                        value={commonStore.vcWeight}
                        options={vcWeightList}
                        onChange={(e, { value }) =>
                          commonStore.setVcWeight(value)
                        }
                      />
                    </Form.Field>
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        차종
                      </p>
                      <Select
                        style={{
                          width: "96%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder={"차종선택"}
                        value={commonStore.vcTyp}
                        options={vcTypList}
                        onChange={(e, { value }) => commonStore.setVcTyp(value)}
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        운송물건
                      </p>
                      <Select
                        style={{
                          width: "96%",
                          float: "left",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder={"운송물건선택"}
                        value={commonStore.freightInfo}
                        options={freightInfoList}
                        onChange={(e, { value }) =>
                          commonStore.setFreightInfo(value)
                        }
                        disabled={this.props.textAreaEditable}
                      />
                    </Form.Field>
                    <Form.Field
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "31px",
                        height: "70px",
                      }}
                    >
                      <Checkbox
                        label="직접입력"
                        onClick={() => this.props.fnOnchangetextAreaEditable()}
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <Form style={{ width: "98%" }}>
                      <TextArea
                        disabled={!this.props.textAreaEditable}
                        style={{
                          resize: "none",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder="운송물건 직접입력"
                        value={commonStore.freightInfo}
                        onChange={(e, { value }) =>
                          commonStore.setFreightInfo(value)
                        }
                      />
                    </Form>
                  </div>

                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <Form style={{ width: "100%" }}>
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        연락처
                      </p>
                      <input
                        style={{
                          width: "50%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                          borderRadius: "0.28571429rem",
                          height: "38px",
                          marginTop: "10px",
                          paddingLeft: "10px",
                        }}
                        placeholder="연락처 입력"
                        onChange={(e) => commonStore.setHpNo(e.target.value)}
                        value={commonStore.hpNo}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </ModalContent>
            <ModalActions
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                borderTop: "0px",
                background: COLOR_WHITE,
              }}
            >
              <div
                onClick={() => this.props.fnInsertFreight()}
                style={{
                  width: "15%",
                  height: "50px",
                  fontSize: "20px",
                  background: COLOR_PRIMARY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLOR_WHITE,
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginRight: "3%",
                }}
              >
                <p>배차접수</p>
              </div>
              <div
                onClick={() => this.props.fnFreightModalClose()}
                style={{
                  width: "15%",
                  height: "50px",
                  fontSize: "20px",
                  background: COLOR_BTN_DEEP_GRAY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLOR_WHITE,
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <p>닫기</p>
              </div>
            </ModalActions>
          </Modal>
        </MediaQuery>
        <MediaQuery minWidth={1250}>
          <Modal
            onClose={() => this.props.fnFreightModalClose()}
            onOpen={() => this.props.fnFreightModalOpen()}
            open={this.props.freightModalOpen}
            style={{
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              borderBottomRightRadius: "20px",
              borderBottomLeftRadius: "20px",
            }}
          >
            <ModalHeader
              style={{
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                height: "5rem",
                background: COLOR_PRIMARY,
              }}
            >
              <div
                style={{
                  width: "100%",
                  color: COLOR_WHITE,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    float: "left",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: "1.25rem",
                  }}
                >
                  <p>배차접수</p>
                </div>
                <div
                  style={{
                    width: "50%",
                    overflow: "hidden",
                    fontSize: "1rem",
                    textAlign: "right",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>1833-8852</p>
                  <p>에이치 전국화물</p>
                </div>
              </div>
            </ModalHeader>
            <ModalContent>
              <div>
                <div style={{ height: "500px" }}>
                  <div
                    style={{
                      width: "100%",
                      marginBottom: "20px",
                      height: "120px",
                    }}
                  >
                    <div
                      style={{
                        width: "49%",
                        height: "120px",
                        float: "left",
                        marginRight: "1%",
                      }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        출발지
                      </p>
                      <Form.Field
                        style={{
                          width: "32%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시도 선택"}
                          value={commonStore.selectedSido}
                          options={commonStore.sidoList}
                          onChange={(e, { value }) =>
                            this.props.fnSelectSido(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "32%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시군구 선택"}
                          value={commonStore.selectedSigu}
                          options={commonStore.siguList}
                          onChange={(e, { value }) =>
                            this.props.fnSelectSigu(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "33%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"읍명동 선택"}
                          value={commonStore.selectedDong}
                          options={commonStore.dongList}
                          onChange={(e, { value }) =>
                            commonStore.setSelectedDong(value)
                          }
                        />
                      </Form.Field>
                      <input
                        style={{
                          width: "96%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                          borderRadius: "0.28571429rem",
                          height: "38px",
                          marginTop: "10px",
                          paddingLeft: "10px",
                        }}
                        placeholder={"출발지 상세주소 작성"}
                        onChange={(e) =>
                          commonStore.setLoadingDetailAdd(e.target.value)
                        }
                        value={commonStore.loadingDetailAdd}
                      />
                    </div>

                    <div
                      style={{
                        width: "50%",
                        height: "120px",
                        float: "left",
                      }}
                    >
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        도착지
                      </p>
                      <Form.Field
                        style={{
                          width: "32%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시도 선택"}
                          value={commonStore.unloadingSelectedSido}
                          options={commonStore.unloadingSidoList}
                          onChange={(e, { value }) =>
                            this.props.fnUnloadingSelectSido(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "32%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"시군구 선택"}
                          value={commonStore.unloadingSelectedSigu}
                          options={commonStore.unloadingSiguList}
                          onChange={(e, { value }) =>
                            this.props.fnUnloadingSelectSigu(value)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        style={{
                          width: "33%",
                          float: "left",
                          marginRight: "1%",
                        }}
                      >
                        <Select
                          style={{
                            minWidth: "90%",
                            background: COLOR_INPUT_GRAY,
                            border: "0px",
                          }}
                          placeholder={"읍명동 선택"}
                          value={commonStore.unloadingSelectedDong}
                          options={commonStore.unloadingDongList}
                          onChange={(e, { value }) =>
                            commonStore.setUnloadingSelectedDong(value)
                          }
                        />
                      </Form.Field>
                      <input
                        style={{
                          width: "96%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                          borderRadius: "0.28571429rem",
                          height: "38px",
                          marginTop: "10px",
                          paddingLeft: "10px",
                        }}
                        placeholder={"도착지 상세주소 작성"}
                        onChange={(e) =>
                          commonStore.setUnloadingDetailAdd(e.target.value)
                        }
                        value={commonStore.unloadingDetailAdd}
                      />
                    </div>
                  </div>

                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        톤수
                      </p>
                      <Select
                        style={{
                          width: "96%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder={"톤수선택"}
                        value={commonStore.vcWeight}
                        options={vcWeightList}
                        onChange={(e, { value }) =>
                          commonStore.setVcWeight(value)
                        }
                      />
                    </Form.Field>
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        차종
                      </p>
                      <Select
                        style={{
                          width: "96%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder={"차종선택"}
                        value={commonStore.vcTyp}
                        options={vcTypList}
                        onChange={(e, { value }) => commonStore.setVcTyp(value)}
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        운송물건
                      </p>
                      <Select
                        style={{
                          width: "96%",
                          float: "left",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder={"운송물건선택"}
                        value={commonStore.freightInfo}
                        options={freightInfoList}
                        onChange={(e, { value }) =>
                          commonStore.setFreightInfo(value)
                        }
                        disabled={this.props.textAreaEditable}
                      />
                    </Form.Field>
                    <Form.Field
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "31px",
                        height: "70px",
                      }}
                    >
                      <Checkbox
                        label="직접입력"
                        onClick={() => this.props.fnOnchangetextAreaEditable()}
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <Form style={{ width: "98%" }}>
                      <TextArea
                        disabled={!this.props.textAreaEditable}
                        style={{
                          resize: "none",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                        }}
                        placeholder="운송물건 직접입력"
                        value={commonStore.freightInfo}
                        onChange={(e, { value }) =>
                          commonStore.setFreightInfo(value)
                        }
                      />
                    </Form>
                  </div>

                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <Form style={{ width: "100%" }}>
                      <p style={{ fontWeight: "600", fontSize: "1rem" }}>
                        연락처
                      </p>
                      <input
                        style={{
                          width: "50%",
                          background: COLOR_INPUT_GRAY,
                          border: "0px",
                          borderRadius: "0.28571429rem",
                          height: "38px",
                          marginTop: "10px",
                          paddingLeft: "10px",
                        }}
                        placeholder="연락처 입력"
                        onChange={(e) => commonStore.setHpNo(e.target.value)}
                        value={commonStore.hpNo}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </ModalContent>
            <ModalActions
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                borderTop: "0px",
                background: COLOR_WHITE,
              }}
            >
              <div
                onClick={() => this.props.fnInsertFreight()}
                style={{
                  width: "15%",
                  height: "50px",
                  fontSize: "20px",
                  background: COLOR_PRIMARY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLOR_WHITE,
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginRight: "3%",
                }}
              >
                <p>배차접수</p>
              </div>
              <div
                onClick={() => this.props.fnFreightModalClose()}
                style={{
                  width: "15%",
                  height: "50px",
                  fontSize: "20px",
                  background: COLOR_BTN_DEEP_GRAY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLOR_WHITE,
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <p>닫기</p>
              </div>
            </ModalActions>
          </Modal>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default FreightInsertModal;
