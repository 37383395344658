import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import { COLOR_GRAY_GR08, COLOR_GRAY_GR05 } from "../../common/Color";
@inject((stores) => ({
  accStore: stores.AccStore,
}))
@observer
class SideBar extends Component {
  componentDidMount() {
    let el = document.getElementById("sideNav");
    el.addEventListener("mouseenter", this.fnOnToggle);

    el.addEventListener("mouseleave", this.fnOnToggleClose);
  }

  fnOnToggle = () => {
    this.props.onToggle();
  };

  fnOnToggleClose = () => {
    this.props.onToggleClose();
  };

  fnLogout = () => {
    const { accStore } = this.props;
    accStore.initAdminId();
    accStore.initAdminPw();
    sessionStorage.removeItem("adminId");
    window.location.href = "/";
  };

  render() {
    return (
      <SideNav
        expanded={this.props.expanded}
        //expanded={true}
        id="sideNav"
        style={{
          background: "#EFEFEF",
          width: "80px",
          boxShadow: "4px 4px 10px rgba(0,0,0,0.15)",
          overflow: "hidden",
        }}
        onSelect={(selected) => this.props.onSelect(selected)}
        onToggle={() => {
          this.props.onToggle();
        }}
      >
        <SideNav.Nav
          defaultSelected={this.props.selected}
          style={
            this.props.expanded == false
              ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }
              : null
          }
        >
          {/* {this.props.expanded == false ? (
            <Top_logo1
              width={52}
              height={42}
              style={{
                marginTop: "19px",
              }}
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Top_logo
                style={{
                  margin: "0 auto",
                  marginTop: "19px",
                  display: "block",
                }}
              />
            </div>
          )} */}
          <NavItem
            eventKey={"main"}
            onClick={() => this.props.history.push("/management/main")}
            style={{
              marginTop: this.props.expanded == false ? "13%" : "6%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="fas fa-home fa-2x"
                style={{
                  color:
                    this.props.selected === "main"
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05,
                }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected == "main"
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              홈
            </NavText>
          </NavItem>
          <NavItem
            eventKey={"map"}
            onClick={() => this.props.history.push("/management/map")}
            style={{
              marginTop: this.props.expanded == false ? "13%" : "6%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="fas fa-map-marked-alt fa-2x"
                style={{
                  color:
                    this.props.selected === "map"
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05,
                }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected == "map"
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              차량관제
            </NavText>
          </NavItem>

          <NavItem
            eventKey={"freight"}
            onClick={() => this.props.history.push("/management/freight/all")}
            style={{
              marginTop: this.props.expanded == false ? "13%" : "6%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="fas fa-truck fa-2x"
                style={{
                  color:
                    this.props.selected === "freight"
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05,
                }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected == "freight"
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              운송현황
            </NavText>
          </NavItem>

          <NavItem
            eventKey={"driver"}
            onClick={() => this.props.history.push("/management/driver")}
            style={{
              marginTop: this.props.expanded == false ? "13%" : "6%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="fas fa-user fa-2x"
                style={{
                  color:
                    this.props.selected === "driver"
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05,
                }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected == "driver"
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              차주관리
            </NavText>
          </NavItem>

          <NavItem
            eventKey={"comp"}
            onClick={() => this.props.history.push("/management/comp")}
            style={{
              marginTop: this.props.expanded == false ? "13%" : "6%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="far fa-building fa-2x"
                style={{
                  color:
                    this.props.selected === "comp"
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05,
                }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected == "comp"
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              거래처관리
            </NavText>
          </NavItem>

          <NavItem
            eventKey={"corporation"}
            onClick={() => this.props.history.push("/management/corporation")}
            style={{
              marginTop: this.props.expanded == false ? "13%" : "6%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="fas fa-list fa-2x"
                style={{
                  color:
                    this.props.selected === "corporation"
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05,
                }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected == "corporation"
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              기업거래관리
            </NavText>
          </NavItem>

          <NavItem
            eventKey={"settlement"}
            onClick={() => this.props.history.push("/management/settlement")}
            style={{
              marginTop: this.props.expanded == false ? "13%" : "6%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <NavIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="fas fa-solid fa-receipt fa-2x"
                style={{
                  color:
                    this.props.selected === "settlement"
                      ? COLOR_GRAY_GR08
                      : COLOR_GRAY_GR05,
                }}
              ></i>
            </NavIcon>
            <NavText
              style={{
                color:
                  this.props.selected == "settlement"
                    ? COLOR_GRAY_GR08
                    : COLOR_GRAY_GR05,
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              정산관리
            </NavText>
          </NavItem>

          <NavItem
            eventKey="/"
            style={{
              position: "absolute",
              bottom: 21,
              width: this.props.expanded ? "100%" : "80px",
              marginTop: "35px",
              paddingLeft: "10px",
              height: "50px",
            }}
          >
            <NavIcon
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#2C2E32",
                  fontSize: "18px",
                  fontWeight: "500",
                  float: "left",
                }}
              >
                {sessionStorage.getItem("adminId")}
              </div>
            </NavIcon>
            <NavText style={{ width: "100%" }}>
              <div
                onClick={() => this.fnLogout()}
                style={{
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "50px",
                  alignItems: "center",
                  paddingRight: "25px",
                  color: COLOR_GRAY_GR08,
                }}
              >
                로그아웃
              </div>
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    );
  }
}

export default withRouter(SideBar);
