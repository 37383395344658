import { apiAddress } from "../ApiPlatform";

export const API_INSERT_FREIGHT = apiAddress + "freight/insertFreight";
export const API_SELECT_FREIGHT_ALLLIST =
  apiAddress + "freight/selectFreightAllList";
export const API_SELECT_FREIGHT_DIVLIST =
  apiAddress + "freight/selectFreightDivList";

export const API_UPDATE_FREIGHT = apiAddress + "freight/updateFreight";

export const API_UPDATE_FREIGHT_STATUS =
  apiAddress + "freight/updateFreightStatus";

export const API_UPDATE_FREIGHT_DRIVER_INFO =
  apiAddress + "freight/updateFreightDriverInfo";

export const API_UPDATE_FREIGHT_COMP_INFO =
  apiAddress + "freight/updateFreightCompInfo";

export const API_UPDATE_FREIGHT_COMPLETE =
  apiAddress + "freight/updateFreightComplete";

export const API_SELECT_FREIGHT_COMPLETE_LIST_EXCEL =
  apiAddress + "freight/selectCompleteFreightExcel";

export const API_SELECT_COMPLETE_FREIGHT_LIST =
  apiAddress + "freight/selectCompleteFreightListForDriver";

export const API_SELECT_COMPLETE_FREIGHT_LIST_BY_DATE =
  apiAddress + "freight/selectCompleteFreightListByDate";

export const API_UPDATE_SETTLEMENT_BULK =
  apiAddress + "freight/updateSettlementBulk";

export const API_SELECT_COMPLETE_FREIGHT_LIST_BY_DRIVER_ID =
  apiAddress + "freight/selectCompleteFreightListByDriverId";
