import React, { Component, Fragment } from "react";
import { useRef, useState } from "react";
import {
  ControlledMenu,
  MenuItem,
  useHover,
  useMenuState,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { ReactComponent as Top_logo } from "../../assets/top_logo.svg";
import { ReactComponent as Chevron } from "../../assets/Chevron.svg";
import "./Header.css";
import { inject, observer } from "mobx-react";
import { COLOR_FONT_BLACK, COLOR_PRIMARY } from "../../common/Color";
import { slide as Menu } from "react-burger-menu";
import MediaQuery from "react-responsive";
import Sidebar from "react-side-bar";
import MobileSideBar from "./MobileSideBar";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class Header extends React.Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Fragment>
        <MediaQuery maxWidth={480}>
          <div
            style={{
              width: "100%",
              height: "100px",
            }}
          >
            <div
              style={{
                width: "40%",
                float: "left",
                height: "100px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Top_logo
                //width={"100%"}
                style={{ marginLeft: "10%", cursor: "pointer" }}
                onClick={() => this.props.fnRouterChage("/")}
              />
            </div>
            <div>
              <MobileSideBar
                fnRouterChage={this.props.fnRouterChage}
                path={commonStore.path}
              />
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={481} maxWidth={1249}>
          <div
            style={{
              width: "100%",
              height: "100px",
            }}
          >
            <div
              style={{
                width: "40%",
                float: "left",
                height: "100px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Top_logo
                //width={"100%"}
                style={{ marginLeft: "10%", cursor: "pointer" }}
                onClick={() => this.props.fnRouterChage("/")}
              />
            </div>
            <div>
              <MobileSideBar
                path={commonStore.path}
                fnRouterChage={this.props.fnRouterChage}
              />
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1250}>
          <div
            style={{
              width: "100%",
              height: "100px",
            }}
          >
            <div
              style={{
                width: "30%",
                float: "left",
                height: "100px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Top_logo
                width={"50%"}
                style={{ marginLeft: "30%", cursor: "pointer" }}
                onClick={() => this.props.fnRouterChage("/")}
              />
            </div>
            <div
              style={{
                width: "50%",
                float: "left",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <div style={{ width: "20%", float: "left", cursor: "pointer" }}>
                <ServiceMenu
                  fnRouterChage={this.props.fnRouterChage}
                  path={commonStore.path}
                />
              </div>
              <div style={{ width: "20%", float: "left", cursor: "pointer" }}>
                <FreightInfoMenu
                  fnRouterChage={this.props.fnRouterChage}
                  path={commonStore.path}
                />
              </div>
              <div style={{ width: "20%", float: "left", cursor: "pointer" }}>
                <QuoteMenu fnFreightModalOpen={this.props.fnFreightModalOpen} />
              </div>
              <div style={{ width: "20%", float: "left", cursor: "pointer" }}>
                <Corporation
                  fnRouterChage={this.props.fnRouterChage}
                  path={commonStore.path}
                />
              </div>
              <div style={{ width: "20%", float: "left", cursor: "pointer" }}>
                <CompanyMenu
                  fnRouterChage={this.props.fnRouterChage}
                  path={commonStore.path}
                />
              </div>
            </div>
            <div
              style={{
                width: "20%",
                float: "left",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                  fontSize: "22px",
                }}
              >
                24시간 전국화물문의
              </p>
              <p
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                  fontSize: "22px",
                }}
              >
                1833-8852
              </p>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

const ServiceMenu = (props) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  const fnRouterChage = props.fnRouterChage;
  const path = props.path;
  return (
    <>
      <div
        ref={ref}
        {...anchorProps}
        style={{
          fontWeight: "600",
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        서비스 안내
        <Chevron style={{ marginLeft: "3%" }} />
      </div>

      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
        menuClassName={"munuWrapper"}
      >
        <MenuItem
          onClick={() => fnRouterChage("BasicFreight")}
          style={{
            fontSize: "1.2rem",
            fontWeight: path == "BasicFreight" ? "700" : "400",
            color: path == "BasicFreight" ? COLOR_PRIMARY : COLOR_FONT_BLACK,
          }}
        >
          개인화물
        </MenuItem>
        <MenuItem
          onClick={() => fnRouterChage("AllLocaFreight")}
          style={{
            fontSize: "1.2rem",
            fontWeight: path == "AllLocaFreight" ? "700" : "400",
            color: path == "AllLocaFreight" ? COLOR_PRIMARY : COLOR_FONT_BLACK,
          }}
        >
          전국화물
        </MenuItem>
        <MenuItem
          onClick={() => fnRouterChage("AllVcInfo")}
          style={{
            fontSize: "1.2rem",
            fontWeight: path == "AllVcInfo" ? "700" : "400",
            color: path == "AllVcInfo" ? COLOR_PRIMARY : COLOR_FONT_BLACK,
          }}
        >
          차량제원
        </MenuItem>
      </ControlledMenu>
    </>
  );
};

const FreightInfoMenu = (props) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  const fnRouterChage = props.fnRouterChage;
  const path = props.path;
  return (
    <>
      <div
        ref={ref}
        {...anchorProps}
        style={{
          fontWeight: "600",
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        화물운송 안내
        <Chevron style={{ marginLeft: "3%" }} />
      </div>

      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
        menuClassName={"munuWrapper"}
      >
        <MenuItem
          onClick={() => fnRouterChage("VcInfo1")}
          style={{
            fontSize: "1.2rem",
            fontWeight: path == "VcInfo1" ? "700" : "400",
            color: path == "VcInfo1" ? COLOR_PRIMARY : COLOR_FONT_BLACK,
          }}
        >
          1톤(40,000원~)
        </MenuItem>
        <MenuItem
          onClick={() => fnRouterChage("VcInfo2")}
          style={{
            fontSize: "1.2rem",
            fontWeight: path == "VcInfo2" ? "700" : "400",
            color: path == "VcInfo2" ? COLOR_PRIMARY : COLOR_FONT_BLACK,
          }}
        >
          2.5톤(85,000원~)
        </MenuItem>
        <MenuItem
          onClick={() => fnRouterChage("VcInfo5")}
          style={{
            fontSize: "1.2rem",
            fontWeight: path == "VcInfo5" ? "700" : "400",
            color: path == "VcInfo5" ? COLOR_PRIMARY : COLOR_FONT_BLACK,
          }}
        >
          5톤(110,000원~)
        </MenuItem>
      </ControlledMenu>
    </>
  );
};

const QuoteMenu = (props) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  const fnFreightModalOpen = props.fnFreightModalOpen;
  return (
    <>
      <div
        ref={ref}
        {...anchorProps}
        style={{
          fontWeight: "600",
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => fnFreightModalOpen()}
      >
        실시간 견적
      </div>
    </>
  );
};

const Corporation = (props) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  const fnRouterChage = props.fnRouterChage;
  return (
    <>
      <div
        ref={ref}
        {...anchorProps}
        style={{
          fontWeight: "600",
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => fnRouterChage("corporation")}
      >
        기업거래 문의
      </div>
    </>
  );
};

const CompanyMenu = (props) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  const fnRouterChage = props.fnRouterChage;
  const path = props.path;
  return (
    <>
      <div
        ref={ref}
        {...anchorProps}
        style={{
          fontWeight: "600",
          fontSize: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        회사소개
        <Chevron style={{ marginLeft: "3%" }} />
      </div>

      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
        menuClassName={"munuWrapper"}
      >
        <MenuItem
          onClick={() => fnRouterChage("introduce")}
          style={{
            fontSize: "1.2rem",
            fontWeight: path == "introduce" ? "700" : "400",
            color: path == "introduce" ? COLOR_PRIMARY : COLOR_FONT_BLACK,
          }}
        >
          인사말씀
        </MenuItem>
        <MenuItem
          onClick={() => fnRouterChage("serviceInfo")}
          style={{
            fontSize: "1.2rem",
            fontWeight: path == "serviceInfo" ? "700" : "400",
            color: path == "serviceInfo" ? COLOR_PRIMARY : COLOR_FONT_BLACK,
          }}
        >
          배송시스템
        </MenuItem>
      </ControlledMenu>
    </>
  );
};

export default Header;
