import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  AccordionTitle,
  AccordionContent,
  Accordion,
  Icon,
} from "semantic-ui-react";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_BLACK,
  COLOR_FONT_GRAY,
  COLOR_GRAY_GR06,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import { inject, observer } from "mobx-react";
import { PrivacyTremsList } from "../../common/TremsData";
import MediaQuery from "react-responsive";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class Privacy extends React.Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { commonStore } = this.props;

    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                고객의 권리보호와 산업발전에 기여하는
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  color: COLOR_WHITE,
                }}
              >
                에이치전국화물 입니다.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "50px",
                    color:
                      commonStore.path == "terms"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "terms" ? "700" : "400",
                    fontSize: "1.225rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("terms")}
                >
                  이용약관
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "50px",

                    color:
                      commonStore.path == "privacy"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "privacy" ? "700" : "400",
                    fontSize: "1.225rem",
                    float: "left",
                    borderRightWidth:
                      commonStore.path == "terms" ? "0px" : "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("privacy")}
                >
                  개인정보 처리방침
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "50px",
                    color:
                      commonStore.path == "locationTerm"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "locationTerm" ? "700" : "400",
                    fontSize: "1.225rem",
                    float: "left",
                    cursor: "pointer",
                    wordBreak: "keep-all",
                    textAlign: "center",
                  }}
                  onClick={() => this.props.fnRouterChage("locationTerm")}
                >
                  위치기반서비스 이용약관
                </li>
              </ul>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                개인정보 처리방침
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <Accordion
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {PrivacyTremsList.map((data, i) => (
                  <Fragment key={i}>
                    <AccordionTitle
                      active={activeIndex === i}
                      index={i}
                      onClick={this.handleClick}
                      style={{
                        width: "80%",
                        height: "5.125rem",
                        background: COLOR_BACKGROUND_GRAY,
                        borderRadius: "10px",
                        marginBottom: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "1.125rem",
                      }}
                    >
                      <div style={{ width: "90%" }}>{data.title}</div>
                      {activeIndex === i ? (
                        <Icon
                          name="minus"
                          style={{ float: "right" }}
                          size="large"
                        />
                      ) : (
                        <Icon
                          name="plus"
                          style={{ float: "right" }}
                          size="large"
                        />
                      )}
                    </AccordionTitle>
                    <AccordionContent
                      active={activeIndex === i}
                      style={{ marginBottom: "40px", marginTop: "40px" }}
                    >
                      <p>{data.content}</p>
                    </AccordionContent>
                  </Fragment>
                ))}
              </Accordion>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769} maxWidth={1249}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2rem",
                  color: COLOR_WHITE,
                }}
              >
                고객의 권리보호와 산업발전에 기여하는 에이치전국화물 입니다.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "12.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "terms"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "terms" ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      commonStore.path == "terms" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "terms" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("terms")}
                >
                  이용약관
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "12.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "privacy"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "privacy"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "privacy" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "privacy" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("privacy")}
                >
                  개인정보 처리방침
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "12.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "locationTerm"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "locationTerm"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "locationTerm"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "locationTerm" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("locationTerm")}
                >
                  위치기반서비스 이용약관
                </li>
              </ul>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                개인정보 처리방침
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <Accordion
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {PrivacyTremsList.map((data, i) => (
                  <Fragment key={i}>
                    <AccordionTitle
                      active={activeIndex === i}
                      index={i}
                      onClick={this.handleClick}
                      style={{
                        width: "80%",
                        height: "5.125rem",
                        background: COLOR_BACKGROUND_GRAY,
                        borderRadius: "10px",
                        marginBottom: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "1.125rem",
                      }}
                    >
                      <div style={{ width: "90%" }}>{data.title}</div>
                      {activeIndex === i ? (
                        <Icon
                          name="minus"
                          style={{ float: "right" }}
                          size="large"
                        />
                      ) : (
                        <Icon
                          name="plus"
                          style={{ float: "right" }}
                          size="large"
                        />
                      )}
                    </AccordionTitle>
                    <AccordionContent
                      active={activeIndex === i}
                      style={{ marginBottom: "40px", marginTop: "40px" }}
                    >
                      <p>{data.content}</p>
                    </AccordionContent>
                  </Fragment>
                ))}
              </Accordion>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1250}>
          <div
            style={{
              width: "100%",
              height: "auto",
              overflowY: "scroll",
              marginBottom: "70px",
            }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                }}
              >
                고객의 권리보호와 산업발전에 기여하는 에이치전국화물 입니다.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "12.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "terms"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "terms" ? COLOR_PRIMARY : COLOR_WHITE,
                    color:
                      commonStore.path == "terms" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "terms" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("terms")}
                >
                  이용약관
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "12.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "privacy"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "privacy"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "privacy" ? COLOR_WHITE : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "privacy" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("privacy")}
                >
                  개인정보 처리방침
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "12.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "locationTerm"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "locationTerm"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "locationTerm"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "locationTerm" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("locationTerm")}
                >
                  위치기반서비스 이용약관
                </li>
              </ul>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                개인정보 처리방침
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <Accordion
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {PrivacyTremsList.map((data, i) => (
                  <Fragment key={i}>
                    <AccordionTitle
                      active={activeIndex === i}
                      index={i}
                      onClick={this.handleClick}
                      style={{
                        width: "60%",
                        height: "5.125rem",
                        background: COLOR_BACKGROUND_GRAY,
                        borderRadius: "10px",
                        marginBottom: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "1.125rem",
                      }}
                    >
                      <div style={{ width: "90%" }}>{data.title}</div>
                      {activeIndex === i ? (
                        <Icon
                          name="minus"
                          style={{ float: "right" }}
                          size="large"
                        />
                      ) : (
                        <Icon
                          name="plus"
                          style={{ float: "right" }}
                          size="large"
                        />
                      )}
                    </AccordionTitle>
                    <AccordionContent
                      active={activeIndex === i}
                      style={{ marginBottom: "40px", marginTop: "40px" }}
                    >
                      <p>{data.content}</p>
                    </AccordionContent>
                  </Fragment>
                ))}
              </Accordion>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(Privacy);
