import React, { Component, Fragment } from "react";
import { Table, TextArea, Button, Form, Select } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import moment from "moment";

import CommonAlert from "../../../Component/Common/CommonAlert";
import {
  COLOR_BLACK,
  COLOR_GRAY_GR05,
  COLOR_GRAY_GR07,
} from "../../../common/Color";
@inject((stores) => ({
  commonStore: stores.CommonStore,
  freightStore: stores.FreightStore,
}))
@observer
class FreightManagementDetailContainer extends Component {
  render() {
    const { freightStore, commonStore } = this.props;
    return (
      <div
        style={{
          paddingTop: "20px",
          paddingLeft: "37px",
          boxShadow: "4px 4px 10px rgba(5,5,5,0.05)",
          marginRight: "44px",
          marginLeft: "36px",
          borderRadius: "10px",
          height: "calc(100vh - 100px)",
        }}
      >
        {freightStore.selectedFreight != null &&
        (freightStore.selectedFreight.freightCd == "배차대기" ||
          freightStore.selectedFreight.freightCd == "배차완료") ? (
          <Fragment>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                color="red"
                onClick={() => {
                  this.props.fnDeleteFreightModalOpen();
                }}
              >
                운송취소
              </Button>
              <Button
                color="yellow"
                onClick={() => {
                  this.props.fnUpdateViewOpen();
                }}
              >
                운송내역 변경
              </Button>
              <Button
                color="teal"
                onClick={() => {
                  commonStore.searchDriverModalOepn();
                }}
              >
                기사배정
              </Button>
              <Button
                color="purple"
                onClick={() => {
                  commonStore.searchCompModalOpen();
                }}
              >
                거래처지정
              </Button>
              <Button
                positive
                onClick={() => {
                  this.props.fnUpdateFreightComplete();
                }}
              >
                운송완료
              </Button>
            </div>
          </Fragment>
        ) : freightStore.selectedFreight != null &&
          freightStore.selectedFreight.freightCd == "운송완료" ? (
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="red"
              onClick={() => {
                this.props.fnDeleteFreightModalOpen();
              }}
            >
              취소처리
            </Button>
            <Button
              color="yellow"
              onClick={() => {
                this.props.fnUpdateViewOpen();
              }}
            >
              운송내역 변경
            </Button>
            <Button
              color="teal"
              onClick={() => {
                commonStore.searchDriverModalOepn();
              }}
            >
              기사변경
            </Button>
            <Button
              color="purple"
              onClick={() => {
                commonStore.searchCompModalOpen();
              }}
            >
              거래처변경
            </Button>
          </div>
        ) : null}
        {freightStore.selectedFreight == null ? null : (
          <React.Fragment>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "700",
                color: COLOR_BLACK,
              }}
            >
              운송번호 : {freightStore.selectedFreight.freightNo}
            </p>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: COLOR_GRAY_GR05,
              }}
            >
              등록일시 :{" "}
              {moment(freightStore.selectedFreight.registDt).format(
                "YYYY-MM-DD HH:mm"
              )}
            </p>
            <h3>배차 정보</h3>
            <table border="0px" style={{ marginTop: "20px", width: "70%" }}>
              <tr
                style={{
                  height: "35px",
                  color: COLOR_GRAY_GR07,
                  fontSize: "14px",
                }}
              >
                <td
                  style={{
                    fontWeight: "700",
                    width: "20%",
                  }}
                >
                  운송상태
                </td>
                <td style={{ fontWeight: "400" }}>
                  {freightStore.selectedFreight.freightCd}
                </td>
              </tr>

              <tr
                style={{
                  height: "35px",
                  color: COLOR_GRAY_GR07,
                  fontSize: "14px",
                }}
              >
                <td
                  style={{
                    fontWeight: "700",
                    width: "20%",
                  }}
                >
                  운송료
                </td>
                <td
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {freightStore.selectedFreight.logisFee != undefined
                    ? freightStore.selectedFreight.logisFee
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                    : "미정"}
                </td>
              </tr>
              <tr
                style={{
                  height: "35px",
                  color: COLOR_GRAY_GR07,
                  fontSize: "14px",
                }}
              >
                <td
                  style={{
                    fontWeight: "700",
                    width: "20%",
                  }}
                >
                  수수료
                </td>
                <td
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {freightStore.selectedFreight.commission != undefined
                    ? freightStore.selectedFreight.commission
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                    : "미정"}
                </td>
              </tr>
              <tr
                style={{
                  height: "35px",
                  color: COLOR_GRAY_GR07,
                  fontSize: "14px",
                }}
              >
                <td
                  style={{
                    fontWeight: "700",
                    width: "20%",
                  }}
                >
                  총액
                </td>
                <td
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {freightStore.selectedFreight.totalFee != undefined
                    ? freightStore.selectedFreight.totalFee
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"
                    : "미정"}
                </td>
              </tr>
              <tr
                style={{
                  height: "35px",
                  color: COLOR_GRAY_GR07,
                  fontSize: "14px",
                }}
              >
                <td
                  style={{
                    fontWeight: "700",
                    width: "20%",
                  }}
                >
                  상차지주소
                </td>
                <td
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {freightStore.selectedFreight.loadingDetailAdd == null ||
                  freightStore.selectedFreight.loadingDetailAdd == undefined
                    ? freightStore.selectedFreight.loadingSido +
                      freightStore.selectedFreight.loadingSigu +
                      freightStore.selectedFreight.loadingDong
                    : freightStore.selectedFreight.loadingSido +
                      freightStore.selectedFreight.loadingSigu +
                      freightStore.selectedFreight.loadingDong +
                      " " +
                      freightStore.selectedFreight.loadingDetailAdd}
                </td>
              </tr>

              <tr
                style={{
                  height: "35px",
                  color: COLOR_GRAY_GR07,
                  fontSize: "14px",
                }}
              >
                <td
                  style={{
                    fontWeight: "700",
                    width: "20%",
                  }}
                >
                  하차지 주소
                </td>
                <td
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {freightStore.selectedFreight.unloadingDetailAdd == null ||
                  freightStore.selectedFreight.unloadingDetailAdd == undefined
                    ? freightStore.selectedFreight.unloadingSido +
                      freightStore.selectedFreight.unloadingSigu +
                      freightStore.selectedFreight.unloadingDong
                    : freightStore.selectedFreight.unloadingSido +
                      freightStore.selectedFreight.unloadingSigu +
                      freightStore.selectedFreight.unloadingDong +
                      " " +
                      freightStore.selectedFreight.unloadingDetailAdd}
                </td>
              </tr>

              <tr
                style={{
                  height: "35px",
                  color: COLOR_GRAY_GR07,
                  fontSize: "14px",
                }}
              >
                <td
                  style={{
                    fontWeight: "700",
                    width: "20%",
                  }}
                >
                  화주 연락처
                </td>
                <td
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {freightStore.selectedFreight.hpNo}
                </td>
              </tr>

              <tr
                style={{
                  height: "35px",
                  color: COLOR_GRAY_GR07,
                  fontSize: "14px",
                }}
              >
                <td
                  style={{
                    fontWeight: "700",
                    width: "20%",
                  }}
                >
                  화물정보
                </td>
                <td
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {freightStore.selectedFreight.freightInfo}
                </td>
              </tr>

              <tr
                style={{
                  height: "35px",
                  color: COLOR_GRAY_GR07,
                  fontSize: "14px",
                }}
              >
                <td
                  style={{
                    fontWeight: "700",
                    width: "20%",
                  }}
                >
                  요청 차량 종류
                </td>
                <td
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {freightStore.selectedFreight.vcTyp}
                </td>
              </tr>
            </table>

            <div style={{ width: "100%", marginTop: "20px" }}>
              <div style={{ width: "48%", float: "left" }}>
                <h3>차량 정보</h3>
                <table style={{ width: "100%" }}>
                  <tr
                    style={{
                      height: "35px",
                      color: COLOR_GRAY_GR07,
                      fontSize: "14px",
                      width: "100%",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "700",
                        width: "30%",
                      }}
                    >
                      차량번호
                    </td>
                    <td
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {freightStore.selectedFreight.driverId}
                    </td>
                  </tr>

                  <tr
                    style={{
                      height: "35px",
                      color: COLOR_GRAY_GR07,
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "700",
                        width: "20%",
                      }}
                    >
                      차주이름
                    </td>
                    <td
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {freightStore.selectedFreight.driverNm}
                    </td>
                  </tr>

                  <tr
                    style={{
                      height: "35px",
                      color: COLOR_GRAY_GR07,
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "700",
                        width: "20%",
                      }}
                    >
                      트럭커 연락처
                    </td>
                    <td
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {freightStore.selectedFreight.driverHpNo}
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: "35px",
                      color: COLOR_GRAY_GR07,
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "700",
                        width: "20%",
                      }}
                    >
                      배차 차량 종류
                    </td>
                    <td
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {freightStore.selectedFreight.driverVcTyp}
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: "35px",
                      color: COLOR_GRAY_GR07,
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "700",
                        width: "20%",
                      }}
                    >
                      톤수
                    </td>
                    <td
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {freightStore.selectedFreight.driverVcWeight}
                    </td>
                  </tr>
                  <tr
                    style={{
                      height: "35px",
                      color: COLOR_GRAY_GR07,
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "700",
                        width: "20%",
                      }}
                    >
                      정보망
                    </td>
                    <td
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {freightStore.selectedFreight.networkCd}
                    </td>
                  </tr>
                </table>
              </div>
              <div style={{ width: "48%", float: "left" }}>
                <h3>거래처 정보</h3>
                <table style={{ width: "100%" }}>
                  <tr
                    style={{
                      height: "35px",
                      color: COLOR_GRAY_GR07,
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "700",
                        width: "20%",
                      }}
                    >
                      상호명
                    </td>
                    <td
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {freightStore.selectedFreight.compNm}
                    </td>
                  </tr>

                  <tr
                    style={{
                      height: "35px",
                      color: COLOR_GRAY_GR07,
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "700",
                        width: "20%",
                      }}
                    >
                      사업자번호
                    </td>
                    <td
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {freightStore.selectedFreight.compBsNo}
                    </td>
                  </tr>

                  <tr
                    style={{
                      height: "35px",
                      color: COLOR_GRAY_GR07,
                      fontSize: "14px",
                    }}
                  >
                    <td
                      style={{
                        fontWeight: "700",
                        width: "20%",
                      }}
                    >
                      연락처
                    </td>
                    <td
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {freightStore.selectedFreight.compHpNo}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <CommonAlert />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default FreightManagementDetailContainer;
