import React, { Component } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import { API_SELECT_GEOLOCATION_LIST } from "../../common/ApiAddress/DriverAPI";
import { inject, observer } from "mobx-react";

const { kakao } = window;
@inject((stores) => ({
  driverStore: stores.DriverStore,
  commonStore: stores.CommonStore,
}))
@observer
class MapManagement extends React.Component {
  componentDidMount() {
    this.fnSelectGeoLocationList();
  }

  fnSelectGeoLocationList = () => {
    const { driverStore, commonStore } = this.props;
    let that = this;
    axios({
      url: API_SELECT_GEOLOCATION_LIST,
      method: "post",
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);
          driverStore.setGeoLocationList(response.data.data);
          if (response.data.data.length > 0) {
            that.fnShowMarker();
          }
        } else {
          that.fnShowMarker();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnShowMarker = () => {
    const { driverStore, commonStore } = this.props;
    var mapContainer = document.getElementById("map"), // 지도를 표시할 div
      mapOption = {
        center: new kakao.maps.LatLng(36.9176, 127.959), //지도의 중심좌표.
        level: 12, //지도의 레벨(확대, 축소 정도)
      };

    var map = new kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다

    var positions = [];
    for (let i = 0; i < driverStore.geoLocationList.length; i++) {
      let position = {};
      let isEmpty =
        driverStore.geoLocationList[i].isEmpty == "Y" ? "공차" : "운행중";
      position.title =
        "<div style='width:300px;display:flex;justify-content:center;align-items:center;height:50px'>" +
        driverStore.geoLocationList[i].driverId +
        " / " +
        driverStore.geoLocationList[i].driverNm +
        " / " +
        driverStore.geoLocationList[i].vcWeight +
        " / " +
        driverStore.geoLocationList[i].vcTyp +
        " / " +
        driverStore.geoLocationList[i].hpNo +
        " / " +
        isEmpty +
        "</div>";
      position.latlng = new kakao.maps.LatLng(
        driverStore.geoLocationList[i].latitude,
        driverStore.geoLocationList[i].longitude
      );
      positions.push(position);
    }
    for (var i = 0; i < positions.length; i++) {
      // 마커를 생성합니다
      var marker = new kakao.maps.Marker({
        map: map, // 마커를 표시할 지도
        position: positions[i].latlng, // 마커를 표시할 위치
        title: positions[i].title, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
        //image: markerImage, // 마커 이미지
      });

      // 마커에 표시할 인포윈도우를 생성합니다
      var infowindow = new kakao.maps.InfoWindow({
        content: positions[i].title, // 인포윈도우에 표시할 내용
      });

      // 마커에 mouseover 이벤트와 mouseout 이벤트를 등록합니다
      // 이벤트 리스너로는 클로저를 만들어 등록합니다
      // for문에서 클로저를 만들어 주지 않으면 마지막 마커에만 이벤트가 등록됩니다
      kakao.maps.event.addListener(
        marker,
        "mouseover",
        this.makeOverListener(map, marker, infowindow)
      );
      kakao.maps.event.addListener(
        marker,
        "mouseout",
        this.makeOutListener(infowindow)
      );
    }
  };

  // 인포윈도우를 표시하는 클로저를 만드는 함수입니다
  makeOverListener = (map, marker, infowindow) => {
    return function () {
      infowindow.open(map, marker);
    };
  };

  // 인포윈도우를 닫는 클로저를 만드는 함수입니다
  makeOutListener = (infowindow) => {
    return function () {
      infowindow.close();
    };
  };

  render() {
    return (
      <div style={{ padding: "20px" }}>
        <div
          id="map"
          style={{ width: "100%", height: "calc(100vh - 50px)" }}
        ></div>
      </div>
    );
  }
}

export default withRouter(MapManagement);
