import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { Button, Modal, Tab } from "semantic-ui-react";
import axios from "axios";
import FreightAllList from "./FreightAllList";
import FreightDivList from "./FreightDivList";
import FreightManagementDetailContainer from "./FreightManagementDetailContainer";
import CorfirmModalForDelete from "../../../Component/Modal/ConfirmModalForDelete";
import {
  API_INSERT_FREIGHT,
  API_SELECT_FREIGHT_ALLLIST,
  API_SELECT_FREIGHT_DIVLIST,
  API_UPDATE_FREIGHT,
  API_UPDATE_FREIGHT_COMPLETE,
  API_UPDATE_FREIGHT_COMP_INFO,
  API_UPDATE_FREIGHT_DRIVER_INFO,
  API_UPDATE_FREIGHT_STATUS,
} from "../../../common/ApiAddress/FrightAPI";
import FreightManagementUpdateContainer from "./FreightManagementUpdateContainer";
import {
  API_SELECT_DONG,
  API_SELECT_SIDO,
  API_SELECT_SIGU,
} from "../../../common/ApiAddress/CommonAPI";
import SearchDriverModal from "../../../Component/Modal/SearchDriverModal";
import SearchCompModal from "../../../Component/Modal/SearchCompModal";
import CompleteFreightExcelModal from "../../../Component/Modal/CompleteFreightExcelModal";
import OrderManagement from "../OrderManagement";
import DatePicker, { registerLocale } from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import CommonAlert from "../../../Component/Common/CommonAlert";
import moment from "moment";

@inject((stores) => ({
  commonStore: stores.CommonStore,
  freightStore: stores.FreightStore,
  driverStore: stores.DriverStore,
  compStore: stores.CompStore,
}))
@observer
class FreightManagement extends React.Component {
  state = {
    detailView: false,
    updateView: false,
    searchTyp: null,
    searchKeyword: null,
    resultList: [],
    updateLoadingDateMultiModal: false,
    startDate: null,
    endDate: null,
  };

  componentDidMount() {
    let prevPath = this.props.location.pathname;
    let pathSplit = prevPath.split("/");
    let name = pathSplit[pathSplit.length - 1];
    this.fnSelectFreightAllList();
    this.fnSelectSido();
  }

  fnRouterChage = (path) => {
    this.props.history.push(path);
  };

  fnSelectFreightAllList = (currentPage) => {
    const { freightStore } = this.props;
    let params = {};
    if (currentPage == undefined || currentPage == 1) {
      params.startCnt = 0;
      params.endCnt = 20;
    } else {
      params.startCnt = (currentPage - 1) * 20;
      params.endCnt = 20;
    }

    if (this.state.startDate == null) {
      params.startDate = moment().format("YYYY-MM-DD 00:00:00");
      params.endDate = moment().add("day", 1).format("YYYY-MM-DD 00:00:00");
    } else {
      params.startDate = moment(this.state.startDate).format(
        "YYYY-MM-DD 00:00:00"
      );
      params.endDate = moment(this.state.endDate)
        .add("day", 1)
        .format("YYYY-MM-DD 00:00:00");
    }
    axios({
      url: API_SELECT_FREIGHT_ALLLIST,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          freightStore.setFreightList(response.data.data);
          freightStore.setTotalPage(response.data.totalPageNum);
        } else {
          freightStore.initFreightList();
          freightStore.initTotalPage();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectFreightDivList = (currentPage, name) => {
    const { freightStore } = this.props;
    let params = {};
    if (name == "reserved") {
      params.freightCd = "배차대기";
    }
    if (name == "rescomplete") {
      params.freightCd = "배차완료";
    }

    if (name == "transcomplete") {
      params.freightCd = "운송완료";
    }

    if (name == "transcancle") {
      params.freightCd = "운송취소";
    }

    if (currentPage == undefined || currentPage == 1) {
      params.startCnt = 0;
      params.endCnt = 20;
    } else {
      params.startCnt = (currentPage - 1) * 20;
      params.endCnt = 20;
    }
    axios({
      url: API_SELECT_FREIGHT_DIVLIST,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          freightStore.setFreightList(response.data.data);
          freightStore.setTotalPage(response.data.totalPageNum);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //신규배차 버튼
  fnInitForm = () => {
    const { commonStore, freightStore } = this.props;
    commonStore.initSelectedSido();
    commonStore.initSelectedSigu();
    commonStore.initSelectedDong();
    commonStore.initSelectedDong();
    commonStore.initUnloadingSelectedSido();
    commonStore.initUnloadingSelectedSigu();
    commonStore.intiUnloadingSelectedDong();
    commonStore.initVcWeight();
    commonStore.initVcTyp();
    commonStore.initFreightInfo();
    commonStore.initHpNo();
    commonStore.initLoadingDetailAdd();
    commonStore.initUnloadingDetailAdd();
    commonStore.initLoadingCd();
    commonStore.initUnloadingCd();
    commonStore.initPayCd();
    commonStore.initLogisFee();
    commonStore.initCommission();
    commonStore.initTotalFee();
    commonStore.initLogisCd();
    commonStore.initNetworkCd();
    freightStore.initSelectedFreight();
  };

  //배차 선택
  fnSelectedFreight = (value) => {
    const { commonStore, freightStore } = this.props;
    freightStore.setSelectedFreight(value);

    this.fnSelectSido();
    let selectedFreight = { ...freightStore.selectedFreight };

    commonStore.setFreightCd(selectedFreight.freightCd);

    commonStore.setSelectedSido(selectedFreight.loadingSido);
    this.fnSelectSigu(selectedFreight.loadingSido);
    commonStore.setSelectedSigu(selectedFreight.loadingSigu);
    this.fnSelectDong(selectedFreight.loadingSigu);
    commonStore.setSelectedDong(selectedFreight.loadingDong);
    commonStore.setLoadingDetailAdd(selectedFreight.loadingDetailAdd);

    commonStore.setUnloadingSelectedSido(selectedFreight.unloadingSido);
    this.fnUnloadingSelectSigu(selectedFreight.unloadingSido);
    commonStore.setUnloadingSelectedSigu(selectedFreight.unloadingSigu);
    this.fnUnloadingSelectDong(selectedFreight.unloadingSigu);
    commonStore.setUnloadingSelectedDong(selectedFreight.unloadingDong);
    commonStore.setUnloadingDetailAdd(selectedFreight.unloadingDetailAdd);

    commonStore.setVcWeight(selectedFreight.vcWeight);
    commonStore.setVcTyp(selectedFreight.vcTyp);
    commonStore.setFreightInfo(selectedFreight.freightInfo);
    commonStore.setHpNo(selectedFreight.hpNo);

    if (selectedFreight.logisFee == undefined) {
      commonStore.initLogisFee();
    } else {
      commonStore.setLogisFee(selectedFreight.logisFee);
    }

    if (selectedFreight.commission == undefined) {
      commonStore.initCommission();
    } else {
      commonStore.setCommission(selectedFreight.commission);
    }

    if (selectedFreight.totalFee == undefined) {
      commonStore.initTotalFee();
    } else {
      commonStore.setTotalFee(selectedFreight.totalFee);
    }

    commonStore.setNetworkCd(selectedFreight.networkCd);
    commonStore.setLoadingCd(selectedFreight.loadingCd);
    commonStore.setUnloadingCd(selectedFreight.unloadingCd);
    commonStore.setPayCd(selectedFreight.payCd);
    commonStore.setLogisCd(selectedFreight.logisCd);
  };

  //운송 취소 모달 열기
  fnDeleteFreightModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForDeleteOpen("선택된 운송건을 취소하시겠습니까?");
  };

  //운송 취소 모달 닫기
  fnDeleteFreightModalClose = () => {
    const { commonStore } = this.props;
    commonStore.confirmModalForDeleteClose();
  };

  //운송취소
  fnDeleteFreight = () => {
    const { freightStore } = this.props;
    const that = this;
    let params = {};
    params.freightNo = freightStore.selectedFreight.freightNo;
    params.freightCd = "운송취소";
    let frieghtInfo = { ...freightStore.selectedFreight };
    params.freightInfo = frieghtInfo;

    axios({
      url: API_UPDATE_FREIGHT_STATUS,
      method: "post",
      data: params,
    })
      .then((response) => {
        if (response.data.resultCode == 100) {
          that.fnGetMsgAuth(params, "C");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //시도찾기
  fnSelectSido = () => {
    const { commonStore } = this.props;

    axios({
      url: API_SELECT_SIDO,
      method: "post",
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.setSidoList(response.data.data);
          commonStore.setUnloadingSidoList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //시구 찾기
  fnSelectSigu = (value) => {
    let params = {};
    const { commonStore } = this.props;
    let that = this;
    commonStore.setSelectedSido(value);
    commonStore.initSelectedSigu(value);
    commonStore.initSelectedDong(value);
    params.sido = value;
    axios({
      url: API_SELECT_SIGU,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.setSiguList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //읍면동 찾기
  fnSelectDong = (value) => {
    let params = {};
    const { commonStore } = this.props;
    let that = this;
    commonStore.setSelectedSigu(value);
    commonStore.initSelectedDong(value);
    params.sido = commonStore.selectedSido;
    params.sigu = value;
    axios({
      url: API_SELECT_DONG,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.setDongList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUnloadingSelectSigu = (value) => {
    let params = {};
    const { commonStore } = this.props;
    let that = this;
    commonStore.setUnloadingSelectedSido(value);
    commonStore.initUnloadingSelectedSigu(value);
    commonStore.intiUnloadingSelectedDong(value);
    params.sido = value;
    axios({
      url: API_SELECT_SIGU,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.setUnloadingSiguList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUnloadingSelectDong = (value) => {
    let params = {};
    const { commonStore } = this.props;
    let that = this;
    commonStore.setUnloadingSelectedSigu(value);
    commonStore.intiUnloadingSelectedDong(value);
    params.sido = commonStore.unloadingSelectedSido;
    params.sigu = value;
    axios({
      url: API_SELECT_DONG,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.setUnloadingDongList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUpsertFreight = () => {
    const { freightStore } = this.props;
    if (freightStore.selectedFreight == null) {
      this.fnInsertFreight();
    } else {
      this.fnUpdateFreight();
    }
  };

  fnInsertFreight = () => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.freightCd = "배차대기";
    params.loadingSido = commonStore.selectedSido;
    params.loadingSigu = commonStore.selectedSigu;
    params.loadingDong = commonStore.selectedDong;
    params.loadingDetailAdd = commonStore.loadingDetailAdd;

    params.unloadingSido = commonStore.unloadingSelectedSido;
    params.unloadingSigu = commonStore.unloadingSelectedSigu;
    params.unloadingDong = commonStore.unloadingSelectedDong;
    params.unloadingDetailAdd = commonStore.unloadingDetailAdd;

    params.vcTyp = commonStore.vcTyp;
    params.vcWeight = commonStore.vcWeight;
    params.freightInfo = commonStore.freightInfo;
    params.hpNo = commonStore.hpNo;

    params.loadingCd = commonStore.loadingCd;
    params.unloadingCd = commonStore.unloadingCd;
    params.payCd = commonStore.payCd;

    if (commonStore.payCd == "인수증") {
      params.settlementYn = "N";
    } else {
      params.settlementYn = "Y";
    }

    params.logisFee = commonStore.logisFee;
    params.commission = commonStore.commission;
    params.totalFee =
      Number(commonStore.logisFee) + Number(commonStore.commission);

    params.networkCd = commonStore.networkCd;
    params.logisCd = commonStore.logisCd;

    if (commonStore.selectedSido == null || commonStore.selectedSido == "") {
      commonStore.commonAlertOpen("출발지 시도를 선택해주세요.");
    }
    if (commonStore.selectedSigu == null || commonStore.selectedSigu == "") {
      commonStore.commonAlertOpen("출발지 시군구를 선택해주세요.");
    }
    if (commonStore.selectedDong == null || commonStore.selectedDong == "") {
      commonStore.commonAlertOpen("출발지 읍면동을 선택해주세요.");
    }
    if (
      commonStore.loadingDetailAdd == null ||
      commonStore.loadingDetailAdd == ""
    ) {
      commonStore.commonAlertOpen("출발지 상세주소를 기입해주세요.");
    }

    if (
      commonStore.unloadingSelectedSido == null ||
      commonStore.unloadingSelectedSido == ""
    ) {
      commonStore.commonAlertOpen("도착지 시도를 선택해주세요.");
    }
    if (
      commonStore.unloadingSelectedSigu == null ||
      commonStore.unloadingSelectedSigu == ""
    ) {
      commonStore.commonAlertOpen("도착지 시군구를 선택해주세요.");
    }
    if (
      commonStore.unloadingSelectedDong == null ||
      commonStore.unloadingSelectedDong == ""
    ) {
      commonStore.commonAlertOpen("도착지 읍면동을 선택해주세요.");
    }
    if (
      commonStore.unloadingDetailAdd == null ||
      commonStore.unloadingDetailAdd == ""
    ) {
      commonStore.commonAlertOpen("도착지 상세주소를 기입해주세요.");
    }

    if (commonStore.vcTyp == null || commonStore.vcTyp == "") {
      commonStore.commonAlertOpen("차량종류를 선택해주세요");
    }

    if (commonStore.vcWeight == null || commonStore.vcWeight == "") {
      commonStore.commonAlertOpen("차량톤수를 선택해주세요");
    }

    if (commonStore.freightInfo == null || commonStore.freightInfo == "") {
      commonStore.commonAlertOpen("화물정보를 입력해주세요");
    }

    if (commonStore.hpNo == null || commonStore.hpNo == "") {
      commonStore.commonAlertOpen("화주연락처를 압력해주세요");
    }

    axios({
      url: API_INSERT_FREIGHT,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.commonAlertOpen("배차가 성공적으로 접수되었습니다.");
          that.fnSelectFreightAllList();
        } else {
          commonStore.commonAlertOpen(
            "배차 접수에 실패했습니다. 관리자에 문의해 주세요."
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUpdateFreight = () => {
    let params = {};
    const { commonStore, freightStore } = this.props;
    let that = this;

    params.freightNo = freightStore.selectedFreight.freightNo;
    params.freightCd = commonStore.freightCd;

    params.loadingSido = commonStore.selectedSido;
    params.loadingSigu = commonStore.selectedSigu;
    params.loadingDong = commonStore.selectedDong;
    params.loadingDetailAdd = commonStore.loadingDetailAdd;

    params.unloadingSido = commonStore.unloadingSelectedSido;
    params.unloadingSigu = commonStore.unloadingSelectedSigu;
    params.unloadingDong = commonStore.unloadingSelectedDong;
    params.unloadingDetailAdd = commonStore.unloadingDetailAdd;

    params.vcTyp = commonStore.vcTyp;
    params.vcWeight = commonStore.vcWeight;
    params.freightInfo = commonStore.freightInfo;
    params.hpNo = commonStore.hpNo;

    params.loadingCd = commonStore.loadingCd;
    params.unloadingCd = commonStore.unloadingCd;
    params.payCd = commonStore.payCd;
    if (commonStore.payCd == "인수증") {
      params.settlementYn = "N";
    } else {
      params.settlementYn = "Y";
    }

    params.logisFee = commonStore.logisFee;
    params.commission = commonStore.commission;
    params.totalFee =
      Number(commonStore.logisFee) + Number(commonStore.commission);

    params.networkCd = commonStore.networkCd;
    params.logisCd = commonStore.logisCd;

    axios({
      url: API_UPDATE_FREIGHT,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnSelectFreightAllList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //기사정보 업데이트
  fnUpdateDriverInfo = () => {
    let params = {};
    const { commonStore, freightStore, driverStore } = this.props;
    let that = this;
    params.freightNo = freightStore.selectedFreight.freightNo;
    params.driverNo = driverStore.selectedDriver.driverNo;
    params.driverId = driverStore.selectedDriver.driverId;
    params.driverNm = driverStore.selectedDriver.driverNm;
    params.driverHpNo = driverStore.selectedDriver.hpNo;
    params.driverVcTyp = driverStore.selectedDriver.vcTyp;
    params.driverVcWeight = driverStore.selectedDriver.vcWeight;
    params.driverBsNo = driverStore.selectedDriver.bsNo;
    params.isEmpty = "N";

    let frieghtInfo = { ...freightStore.selectedFreight };
    params.freightInfo = frieghtInfo;
    params.freightInfo.driverHpNo = driverStore.selectedDriver.hpNo;
    params.freightInfo.driverNm = driverStore.selectedDriver.driverNm;
    params.freightInfo.driverId = driverStore.selectedDriver.driverId;

    axios({
      url: API_UPDATE_FREIGHT_DRIVER_INFO,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          //문자전송토큰취득
          that.fnGetMsgAuth(params, "A");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //거래처 정보 업데이트
  fnUpdateCompInfo = () => {
    let params = {};
    const { commonStore, freightStore, compStore } = this.props;
    let that = this;
    params.freightNo = freightStore.selectedFreight.freightNo;
    params.compNo = compStore.selectedComp.compNo;
    params.compNm = compStore.selectedComp.compNm;
    params.compBsNo = compStore.selectedComp.compBsNo;
    params.compHpNo = compStore.selectedComp.compHpNo;

    axios({
      url: API_UPDATE_FREIGHT_COMP_INFO,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.searchCompModalClose();
          that.fnSelectFreightAllList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //운송완료 처리
  fnUpdateFreightComplete = () => {
    let params = {};
    const { freightStore } = this.props;
    let that = this;
    params.freightNo = freightStore.selectedFreight.freightNo;
    params.freightCd = "운송완료";

    axios({
      url: API_UPDATE_FREIGHT_COMPLETE,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnSelectFreightAllList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSetDate = (update) => {
    console.log(update);
    this.setState({
      startDate: update[0],
      endDate: update[1],
    });
  };

  fnGetMsgAuth = (obj, type) => {
    let that = this;
    let params = obj.freightInfo;
    params.type = type;
    axios({
      url: "https://htruck.co.kr/exapi/msgAuth",
      method: "post",
      data: params,
    })
      .then((response) => {
        if (params.type == "A") {
          that.fnSendMsgForAssign(params, response.data);
          that.fnSendMsgCustomerForAssign(params, response.data);
        } else if (params.type == "C") {
          that.fnSendMsgForCancel(params, response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSendMsgForAssign = (msgData, authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content =
      "[배차정보] \n ●상차지\n" +
      msgData.loadingSido +
      msgData.loadingSigu +
      msgData.loadingDong +
      "\n";

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != "") {
      content = content + msgData.loadingDetailAdd + "\n";
    }

    content =
      content +
      "●하차지\n" +
      msgData.unloadingSido +
      msgData.unloadingSigu +
      msgData.unloadingDong +
      "\n";

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ""
    ) {
      content = content + msgData.unloadingDetailAdd + "\n";
    }

    content = content + "화물정보 : " + msgData.freightInfo + "\n";
    content = content + "상차방법 : " + msgData.freightInfo + "\n";
    content = content + "하차방법 : " + msgData.freightInfo + "\n";
    content = content + "담당자 번호 : " + msgData.hpNo + "\n";

    //phone.push("01031254802");
    phone.push("01088521982");
    //phone.push(msgData.driverHpNo);
    id.push("ttSystem");
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    axios({
      url: "https://htruck.co.kr/exapi/msgSendLmsforTrucker",
      method: "get",
      params: params,
    })
      .then(function (response) {
        if (response.data.code == "1000") {
          commonStore.searchDriverModalClose();
          that.fnSelectFreightAllList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSendMsgCustomerForAssign = (msgData, authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content =
      "[차량배정안내] \n " +
      "다음 배송건에 대해서 아래 기사님이 배정되었습니다.\n" +
      "●상차지\n" +
      msgData.loadingSido +
      msgData.loadingSigu +
      msgData.loadingDong +
      "\n";

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != "") {
      content = content + msgData.loadingDetailAdd + "\n";
    }

    content =
      content +
      "●하차지\n" +
      msgData.unloadingSido +
      msgData.unloadingSigu +
      msgData.unloadingDong +
      "\n";

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ""
    ) {
      content = content + msgData.unloadingDetailAdd + "\n";
    }

    content = content + "기사명 : " + msgData.driverNm + "\n";
    content = content + "기사 연락처: " + msgData.driverHpNo + "\n";
    content = content + "차량번호: " + msgData.driverId + "\n";
    content = content + "빠른시간 내 입차하여 운송하도록 하겠습니다." + "\n";

    //phone.push("01031254802");
    phone.push("01088521982");
    //phone.push(msgData.hpNo);
    id.push("ttSystem");
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    axios({
      url: "https://htruck.co.kr/exapi/msgSendLmsforTrucker",
      method: "get",
      params: params,
    })
      .then(function (response) {
        if (response.data.code == "1000") {
          commonStore.searchDriverModalClose();
          that.fnSelectFreightAllList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSendMsgForCancel = (msgData, authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content =
      "[배차취소안내] \n ●상차지\n" +
      msgData.loadingSido +
      msgData.loadingSigu +
      msgData.loadingDong +
      "\n";

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != "") {
      content = content + msgData.loadingDetailAdd + "\n";
    }

    content =
      content +
      "●하차지\n" +
      msgData.unloadingSido +
      msgData.unloadingSigu +
      msgData.unloadingDong +
      "\n";

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ""
    ) {
      content = content + msgData.unloadingDetailAdd + "\n";
    }

    content = content + "위 배차건의 배정이 취소 되었습니다." + "\n";

    //phone.push("01031254802");
    phone.push("01088521982");
    //phone.push(msgData.driverHpNo);
    id.push("ttSystem");
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    axios({
      url: "https://htruck.co.kr/exapi/msgSendLmsforTrucker",
      method: "get",
      params: params,
    })
      .then(function (response) {
        if (response.data.code == "1000") {
          that.fnDeleteFreightModalClose();
          that.fnSelectFreightAllList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    let currentPage;

    switch (this.props.location.pathname) {
      case "/management/freight/all":
        currentPage = (
          <FreightAllList
            fnSelectedFreight={this.fnSelectedFreight}
            fnSelectFreightAllList={this.fnSelectFreightAllList}
          />
        );
        break;
    }

    const viewPage = () => {
      const { commonStore, freightStore } = this.props;
      return (
        <Fragment>
          <div
            style={{
              width: "100%",
              height: "clac(100vh - 200px)",
              float: "left",
              padding: "20px",
            }}
          >
            <div>
              <OrderManagement
                fnSelectSigu={this.fnSelectSigu}
                fnSelectDong={this.fnSelectDong}
                fnUnloadingSelectSigu={this.fnUnloadingSelectSigu}
                fnUnloadingSelectDong={this.fnUnloadingSelectDong}
              />
            </div>
            {/* 툴박스 */}
            <div style={{ width: "100%" }}>
              <Button color="blue" onClick={() => this.fnInitForm()}>
                신규배차
              </Button>
              <Button positive onClick={() => this.fnUpsertFreight()}>
                배차저장
              </Button>
              <Button negative onClick={() => this.fnDeleteFreightModalOpen()}>
                배차취소
              </Button>
              <DatePicker
                className="freightCalendar"
                locale="ko"
                selectsRange={true}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(update) => {
                  this.fnSetDate(update);
                }}
                dateFormat={"yyyy-MM-dd"}
              />
              <Button
                color="teal"
                style={{ marginLeft: "4px" }}
                onClick={() => {
                  this.fnSelectFreightAllList();
                }}
              >
                조회
              </Button>
              <Button
                disabled={freightStore.selectedFreight == null ? true : false}
                color={freightStore.selectedFreight == null ? "grey" : "brown"}
                onClick={() => {
                  commonStore.searchDriverModalOepn();
                }}
              >
                기사배정
              </Button>
              <Button
                disabled={freightStore.selectedFreight == null ? true : false}
                color={freightStore.selectedFreight == null ? "grey" : "purple"}
                onClick={() => {
                  commonStore.searchCompModalOpen();
                }}
              >
                거래처지정
              </Button>
            </div>
            <div
              style={{
                width: "100%",
                height: "510px",
                marginTop: "10px",
                overflow: "scroll",
                overflowX: "scroll",
              }}
            >
              {currentPage}
            </div>
          </div>
        </Fragment>
      );
    };

    return (
      <React.Fragment>
        {viewPage()}

        <CorfirmModalForDelete
          fnConfirmNo={this.fnDeleteFreightModalClose}
          fnConfirmYes={this.fnDeleteFreight}
        />
        <SearchDriverModal fnUpdateDriverInfo={this.fnUpdateDriverInfo} />
        <SearchCompModal fnUpdateCompInfo={this.fnUpdateCompInfo} />
        <CompleteFreightExcelModal />
        <CommonAlert />
      </React.Fragment>
    );
  }
}

export default withRouter(FreightManagement);
