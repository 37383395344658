import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_BTN_DEEP_GRAY,
  COLOR_FONT_BLACK,
  COLOR_FONT_GRAY,
  COLOR_GRAY_GR00,
  COLOR_GRAY_GR01,
  COLOR_GRAY_GR02,
  COLOR_GRAY_GR05,
  COLOR_PINK_PRIMARY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import { API_SELECT_DASHBOARD } from "../../common/ApiAddress/CommonAPI";
import axios from "axios";
import { Table } from "semantic-ui-react";
import moment, { max, min } from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  scales,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
@inject((stores) => ({
  dbStore: stores.DashBoardStore,
  quoteStore: stores.QuoteStore,
}))
@observer
class DashBoard extends Component {
  state = {
    msgCharge: 0,
  };

  componentDidMount() {
    this.fnSelectDashBoar();
    this.fnGetAuth();
  }
  fnSelectDashBoar = () => {
    const { dbStore } = this.props;
    let params = {};
    params.startCnt = 0;
    params.endCnt = 5;
    axios({
      url: API_SELECT_DASHBOARD,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          console.log(response.data.data);

          dbStore.setFreightList1(response.data.data[0]);
          dbStore.setFreightList2(response.data.data[1]);
          dbStore.setFreightList3(response.data.data[2]);
          dbStore.setFreightList4(response.data.data[3]);

          dbStore.setQuoteList(response.data.data[4]);
          dbStore.setDriverList(response.data.data[5]);
          dbStore.setCompList(response.data.data[6]);
          dbStore.setMonthlyIncomeList(response.data.data[7]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnGetAuth = () => {
    let that = this;
    axios({
      url: "https://htruck.co.kr/exapi/msgAuth",
      method: "post",
      //data: params,
    })
      .then((response) => {
        that.fnGetChargeForMsg(response.data.access_token);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnGetChargeForMsg = (acessToken) => {
    let params = {};
    let that = this;
    params.access_token = acessToken;
    axios({
      url: "https://htruck.co.kr/exapi/charge",
      method: "get",
      params: params,
    })
      .then((response) => {
        that.setState({
          msgCharge: response.data.use_cdr,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { dbStore, quoteStore } = this.props;

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        // title: {
        //   display: true,
        //   text: "Chart.js Bar Chart",
        // },
      },
      // scales: {
      //   y: {
      //     suggestedMin: 0,
      //     suggestedMax: 80000000,
      //   },
      // },
    };

    const labels = dbStore.monthlyIncomeList.map((data) => data.m);

    const data = {
      labels,
      datasets: [
        {
          label: "매출액",
          data: dbStore.monthlyIncomeList.map((data) => data.income),
          backgroundColor: "rgba(255, 99, 132, 0.8)",
        },
      ],
    };
    return (
      <div
        style={{
          padding: "10px",
          width: "100%",
          background: COLOR_GRAY_GR01,
          height: "100vh",
        }}
      >
        <div style={{ width: "100%", marginTop: "10px", height: "31%" }}>
          <div
            style={{
              width: "47%",
              paddingTop: "20px",
              boxShadow: "10px 10px 10px rgba(10,5,5,0.08)",
              marginLeft: "36px",
              borderRadius: "10px",
              height: "280px",
              float: "left",
              background: COLOR_WHITE,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  float: "left",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    paddingLeft: "20px",
                    marginBottom: "0px",
                  }}
                >
                  배차 현황
                </p>
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "700",
                    paddingLeft: "5px",
                    color: COLOR_FONT_GRAY,
                  }}
                >
                  {"(" + moment().format("YYYY-MM-DD") + " 기준)"}
                </span>
              </div>
              <div style={{ width: "50%", float: "left", textAlign: "right" }}>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    paddingRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.props.onSelect("freight");
                    this.props.history.push("/management/freight/all");
                  }}
                >
                  바로가기
                </p>
              </div>
            </div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
                paddingLeft: "20px",
                marginBottom: "0px",
              }}
            >
              문자 잔액 : {this.state.msgCharge}원
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80%",
              }}
            >
              <div
                style={{
                  width: "25%",
                  textAlign: "center",
                  color: COLOR_BTN_DEEP_GRAY,
                }}
              >
                <p style={{ fontSize: "25px", fontWeight: "600" }}>배차대기</p>
                <p style={{ fontSize: "25px", fontWeight: "600" }}>
                  {dbStore.freightList1.length == 0 ||
                  dbStore.freightList1[0].count == undefined
                    ? "0건"
                    : dbStore.freightList1[0].count}
                </p>
              </div>

              <div style={{ width: "25%", textAlign: "center" }}>
                <p style={{ fontSize: "25px", fontWeight: "600" }}>배차완료</p>
                <p style={{ fontSize: "25px", fontWeight: "600" }}>
                  {dbStore.freightList2.length == 0 ||
                  dbStore.freightList2[0].count == undefined
                    ? "0건"
                    : dbStore.freightList2[0].count}
                </p>
              </div>
              <div
                style={{
                  width: "25%",
                  textAlign: "center",
                  color: COLOR_PRIMARY,
                }}
              >
                <p style={{ fontSize: "25px", fontWeight: "600" }}>운송완료</p>
                <p style={{ fontSize: "25px", fontWeight: "600" }}>
                  {dbStore.freightList3.length == 0 ||
                  dbStore.freightList3[0].count == undefined
                    ? "0건"
                    : dbStore.freightList3[0].count}
                </p>
              </div>
              <div
                style={{
                  width: "25%",
                  textAlign: "center",
                  color: COLOR_PINK_PRIMARY,
                }}
              >
                <p style={{ fontSize: "25px", fontWeight: "600" }}>운송취소</p>
                <p style={{ fontSize: "25px", fontWeight: "600" }}>
                  {dbStore.freightList4.length == 0 ||
                  dbStore.freightList4[0].count == undefined
                    ? "0건"
                    : dbStore.freightList4[0].count}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "47%",
              paddingTop: "20px",
              paddingLeft: "20px",
              boxShadow: "4px 4px 10px rgba(5,5,5,0.05)",
              marginLeft: "36px",
              borderRadius: "10px",
              height: "280px",
              float: "left",
              background: COLOR_WHITE,
              paddingRight: "20px",
            }}
          >
            <p style={{ fontSize: "20px", fontWeight: "700" }}>
              {"기업거래문의(" + dbStore.quoteList.length + "건 )"}
            </p>
            <Table celled style={{ textAlign: "center" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>회사명</Table.HeaderCell>
                  <Table.HeaderCell>등록일</Table.HeaderCell>
                  <Table.HeaderCell>연락유무</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {dbStore.quoteList == null || dbStore.quoteList.length == 0 ? (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="3" textAlign="center">
                      등록된 문의가 없습니다.
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : (
                <Table.Body>
                  {dbStore.quoteList.map((data, i) => (
                    <React.Fragment key={data.id}>
                      <Table.Row
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.props.history.push("/management/corporation");
                          quoteStore.SetSelectedCorporation(data);
                        }}
                      >
                        <Table.Cell>{data.companyNm}</Table.Cell>
                        <Table.Cell>
                          {moment(data.registDt).format("YYYY-MM-DD")}
                        </Table.Cell>
                        <Table.Cell>{data.contactYn}</Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  ))}
                </Table.Body>
              )}
            </Table>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            height: "62%",
            float: "left",
          }}
        >
          <div
            style={{
              width: "47%",
              paddingTop: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              boxShadow: "10px 10px 10px rgba(10,5,5,0.08)",
              marginLeft: "36px",
              borderRadius: "10px",
              height: "560px",
              float: "left",
              background: COLOR_WHITE,
            }}
          >
            <p style={{ fontSize: "20px", fontWeight: "700" }}>
              월별 매출 추이
            </p>
            <Bar options={options} data={data} />
          </div>
          <div>
            <div
              style={{
                width: "47%",
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                boxShadow: "10px 10px 10px rgba(10,5,5,0.08)",
                marginLeft: "36px",
                borderRadius: "10px",
                height: "275px",
                float: "left",
                background: COLOR_WHITE,
              }}
            >
              <p style={{ fontSize: "20px", fontWeight: "700" }}>Top5 트럭커</p>
              <Table celled style={{ textAlign: "center" }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>기사명</Table.HeaderCell>
                    <Table.HeaderCell>차량번호</Table.HeaderCell>
                    <Table.HeaderCell>연락처</Table.HeaderCell>
                    <Table.HeaderCell>차종</Table.HeaderCell>
                    <Table.HeaderCell>톤수</Table.HeaderCell>
                    <Table.HeaderCell>운송횟수</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {dbStore.driverList == null ||
                dbStore.driverList.length == 0 ? (
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell colSpan="6" textAlign="center">
                        등록된 기사가 없습니다.
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                ) : (
                  <Table.Body>
                    {dbStore.driverList.map((data, i) => (
                      <React.Fragment key={data.driverNo}>
                        <Table.Row>
                          <Table.Cell>{data.driverNm}</Table.Cell>
                          <Table.Cell>{data.driverId}</Table.Cell>
                          <Table.Cell>{data.driverHpNo}</Table.Cell>
                          <Table.Cell>{data.driverVcTyp}</Table.Cell>
                          <Table.Cell>{data.driverVcWeight}</Table.Cell>
                          <Table.Cell>{data.count}</Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    ))}
                  </Table.Body>
                )}
              </Table>
            </div>
            <div
              style={{
                width: "47%",
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                boxShadow: "10px 10px 10px rgba(10,5,5,0.08)",
                marginLeft: "36px",
                borderRadius: "10px",
                height: "275px",
                float: "left",
                background: COLOR_WHITE,
                marginTop: "10px",
              }}
            >
              <p style={{ fontSize: "20px", fontWeight: "700" }}>Top5 거래처</p>
              <Table celled style={{ textAlign: "center" }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>거래처명</Table.HeaderCell>
                    <Table.HeaderCell>연락처</Table.HeaderCell>
                    <Table.HeaderCell>거래횟수</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {dbStore.compList == null || dbStore.compList.length == 0 ? (
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell colSpan="3" textAlign="center">
                        등록된 기사가 없습니다.
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                ) : (
                  <Table.Body>
                    {dbStore.compList.map((data, i) => (
                      <React.Fragment key={data.compNm}>
                        <Table.Row>
                          <Table.Cell>{data.compNm}</Table.Cell>
                          <Table.Cell>{data.compHpNo}</Table.Cell>
                          <Table.Cell>{data.count}</Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    ))}
                  </Table.Body>
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(DashBoard);
