import React, { Component, Fragment } from "react";
import {
  TextArea,
  Button,
  Form,
  Select,
  Input,
  Checkbox,
} from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import {
  freightInfoList,
  vcTypList,
  vcWeightList,
  networkCdList,
  freightCdList,
} from "../../../common/VcData";
import CommonAlert from "../../../Component/Common/CommonAlert";
import { COLOR_BLACK, COLOR_GRAY_GR07 } from "../../../common/Color";
@inject((stores) => ({
  commonStore: stores.CommonStore,
  freightStore: stores.FreightStore,
}))
@observer
class FreightManagementUpdateContainer extends Component {
  state = {
    textAreaEditable: false,
  };
  render() {
    const { freightStore, commonStore } = this.props;
    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            width: "60%",
            paddingTop: "20px",
            paddingLeft: "37px",
            boxShadow: "4px 4px 10px rgba(5,5,5,0.05)",
            borderRadius: "10px",
            margin: "0 auto",
            color: "black",
          }}
        >
          <Fragment>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
              }}
            >
              <Button
                positive
                onClick={() => {
                  this.props.fnUpdateFreight();
                }}
              >
                수정완료
              </Button>
              <Button
                negative
                onClick={() => {
                  this.props.fnUpdateViewClose();
                }}
              >
                수정취소
              </Button>
            </div>
          </Fragment>
          {freightStore.selectedFreight == null ? null : (
            <React.Fragment>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  color: COLOR_BLACK,
                }}
              >
                운송번호 : {freightStore.selectedFreight.freightNo}
              </p>
              <div>
                <div style={{ height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "70px",
                      marginTop: "20px",
                    }}
                  >
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p>운송상태 선택</p>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"운송상태 선택"}
                        value={commonStore.freightCd}
                        options={freightCdList}
                        onChange={(e, { value }) =>
                          commonStore.setFreightCd(value)
                        }
                      />
                    </Form.Field>
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p>정보망 선택</p>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"정보망 선택"}
                        value={commonStore.networkCd}
                        options={networkCdList}
                        onChange={(e, { value }) =>
                          commonStore.setNetworkCd(value)
                        }
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{ width: "100%", height: "70px", marginTop: "20px" }}
                  >
                    <p>출발지</p>
                    <Form.Field style={{ width: "33%", float: "left" }}>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"시도 선택"}
                        value={commonStore.selectedSido}
                        options={commonStore.sidoList}
                        onChange={(e, { value }) => this.fnSelectSido(value)}
                      />
                    </Form.Field>
                    <Form.Field style={{ width: "33%", float: "left" }}>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"시군구 선택"}
                        value={commonStore.selectedSigu}
                        options={commonStore.siguList}
                        onChange={(e, { value }) => this.fnSelectSigu(value)}
                      />
                    </Form.Field>
                    <Form.Field style={{ width: "33%", float: "left" }}>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"읍명동 선택"}
                        value={commonStore.selectedDong}
                        options={commonStore.dongList}
                        onChange={(e, { value }) =>
                          commonStore.setSelectedDong(value)
                        }
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "50px",
                      marginTop: "20px",
                    }}
                  >
                    <Form.Field>
                      <Input
                        style={{ width: "98%" }}
                        placeholder={"출발지 상세주소 작성"}
                        onChange={(e) =>
                          commonStore.setLoadingDetailAdd(e.target.value)
                        }
                        value={commonStore.loadingDetailAdd}
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "70px",
                      marginTop: "20px",
                    }}
                  >
                    <p>도착지</p>
                    <Form.Field style={{ width: "33%", float: "left" }}>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"시도 선택"}
                        value={commonStore.unloadingSelectedSido}
                        options={commonStore.unloadingSidoList}
                        onChange={(e, { value }) =>
                          this.fnUnloadingSelectSido(value)
                        }
                      />
                    </Form.Field>
                    <Form.Field style={{ width: "33%", float: "left" }}>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"시군구 선택"}
                        value={commonStore.unloadingSelectedSigu}
                        options={commonStore.unloadingSiguList}
                        onChange={(e, { value }) =>
                          this.fnUnloadingSelectSigu(value)
                        }
                      />
                    </Form.Field>
                    <Form.Field style={{ width: "33%", float: "left" }}>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"읍명동 선택"}
                        value={commonStore.unloadingSelectedDong}
                        options={commonStore.unloadingDongList}
                        onChange={(e, { value }) =>
                          commonStore.setUnloadingSelectedDong(value)
                        }
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "50px",
                      marginTop: "20px",
                    }}
                  >
                    <Form.Field>
                      <Input
                        style={{ width: "98%" }}
                        placeholder={"도착지 상세주소 작성"}
                        onChange={(e) =>
                          commonStore.setUnloadingDetailAdd(e.target.value)
                        }
                        value={commonStore.unloadingDetailAdd}
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "70px",
                      marginTop: "20px",
                    }}
                  >
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p>톤수</p>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"톤수선택"}
                        value={commonStore.vcWeight}
                        options={vcWeightList}
                        onChange={(e, { value }) =>
                          commonStore.setVcWeight(value)
                        }
                      />
                    </Form.Field>
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p>차종</p>
                      <Select
                        style={{ width: "96%" }}
                        placeholder={"차종선택"}
                        value={commonStore.vcTyp}
                        options={vcTypList}
                        onChange={(e, { value }) => commonStore.setVcTyp(value)}
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "70px",
                      marginTop: "20px",
                    }}
                  >
                    <Form.Field style={{ width: "50%", float: "left" }}>
                      <p>운송물건</p>
                      <Select
                        style={{ width: "96%", float: "left" }}
                        placeholder={"운송물건선택"}
                        value={commonStore.freightInfo}
                        options={freightInfoList}
                        onChange={(e, { value }) =>
                          commonStore.setFreightInfo(value)
                        }
                        disabled={this.state.textAreaEditable}
                      />
                    </Form.Field>
                    <Form.Field
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        paddingTop: "31px",
                        height: "70px",
                      }}
                    >
                      <Checkbox
                        label="직접입력"
                        onClick={() =>
                          this.setState({
                            textAreaEditable: !this.state.textAreaEditable,
                          })
                        }
                      />
                    </Form.Field>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "70px",
                      marginTop: "20px",
                    }}
                  >
                    <Form style={{ width: "98%" }}>
                      <TextArea
                        disabled={!this.state.textAreaEditable}
                        style={{ resize: "none" }}
                        placeholder="운송물건 직접입력"
                        value={commonStore.freightInfo}
                        onChange={(e, { value }) =>
                          commonStore.setFreightInfo(value)
                        }
                      />
                    </Form>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "70px",
                      marginTop: "20px",
                    }}
                  >
                    <Form style={{ width: "100%" }}>
                      <p>연락처</p>
                      <Input
                        style={{ width: "50%" }}
                        placeholder="연락처 입력"
                        onChange={(e) => commonStore.setHpNo(e.target.value)}
                        value={commonStore.hpNo}
                      />
                    </Form>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "250px",
                      marginTop: "20px",
                    }}
                  >
                    <Form
                      style={{ width: "32%", float: "left", marginRight: "1%" }}
                    >
                      <p>운송료</p>
                      <Input
                        style={{ width: "100%" }}
                        placeholder="운송료 입력"
                        onChange={(e) =>
                          commonStore.setLogisFee(e.target.value)
                        }
                        value={commonStore.logisFee}
                      />
                    </Form>
                    <Form
                      style={{ width: "32%", float: "left", marginRight: "1%" }}
                    >
                      <p>수수료</p>
                      <Input
                        style={{ width: "100%" }}
                        placeholder="수수료 입력"
                        onChange={(e) =>
                          commonStore.setCommission(e.target.value)
                        }
                        value={commonStore.commission}
                      />
                    </Form>
                    <Form style={{ width: "33%", float: "left" }}>
                      <p>전체운임</p>
                      <Input
                        disabled
                        style={{ width: "100%" }}
                        value={
                          commonStore.totalFee == 0
                            ? Number(commonStore.logisFee) +
                              Number(commonStore.commission)
                            : Number(commonStore.totalFee)
                        }
                      />
                    </Form>
                  </div>
                </div>
              </div>

              <CommonAlert />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default FreightManagementUpdateContainer;
