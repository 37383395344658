import React, { Component } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { COLOR_PRIMARY, COLOR_WHITE } from "../common/Color";
import { Form, Input } from "semantic-ui-react";
import CommonAlert from "../Component/Common/CommonAlert";

@inject((stores) => ({
  accStore: stores.AccStore,
  commonStore: stores.CommonStore,
}))
@observer
class AdminLogin extends React.Component {
  //로그인
  fnLogin = () => {
    const { accStore, commonStore } = this.props;
    if (accStore.adminId == "hlogis" && accStore.adminPw == "hlogis1234") {
      sessionStorage.setItem("adminId", "hlogis");
      this.props.history.push("/management/main");
    } else {
      commonStore.commonAlertOpen("아이디와 비밀번호를 확인해 주세요.");
      return false;
    }
  };
  render() {
    const { accStore } = this.props;
    return (
      <React.Fragment>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 60px)",
            display: "flex",
            alignItems: "center",
            background: "#F5F5F5",
          }}
        >
          <div style={{ width: "25%", margin: "0 auto ", marginTop: "-80px" }}>
            <p>로고들어갈 자리</p>
            <Form
              style={{
                borderRadius: "5px",
                padding: "20px",
                background: COLOR_WHITE,
                boxShadow: "0px 4px 10px 2px rgba(0,0,0,0.15)",
              }}
            >
              <Form.Field style={{ marginBottom: "23px" }}>
                <label style={{ fontSize: "14px" }}>
                  아이디
                  <span style={{ color: "#EA3396", marginLeft: "4px" }}>*</span>
                </label>
                <Input
                  style={{ height: "60px", fontSize: "20px" }}
                  placeholder={"아이디를 입력해주세요."}
                  onChange={(e) => accStore.setAdminId(e.target.value)}
                  value={accStore.adminId || ""}
                  type="text"
                />
              </Form.Field>
              <Form.Field>
                <label style={{ fontSize: "14px" }}>
                  비밀번호
                  <span style={{ color: "#EA3396", marginLeft: "4px" }}>*</span>
                </label>
                <Input
                  style={{
                    height: "60px",
                    fontSize: "20px",
                  }}
                  placeholder={"비밀번호를 입력해 주세요."}
                  onChange={(e) => accStore.setAdminPw(e.target.value)}
                  value={accStore.adminPw || ""}
                  type="password"
                  onKeyPress={(e) => {
                    if (e.charCode == 13) {
                      this.fnLogin();
                    }
                  }}
                />
              </Form.Field>

              <div
                onClick={() => {
                  this.fnLogin();
                }}
                style={{
                  width: "100%",
                  height: "60px",
                  marginTop: "33px",
                  marginBottom: "33px",
                  background: COLOR_PRIMARY,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  color: COLOR_WHITE,
                  cursor: "pointer",
                  borderRadius: "5px",
                }}
              >
                로그인
              </div>
            </Form>
          </div>
        </div>
        <CommonAlert />
      </React.Fragment>
    );
  }
}

export default withRouter(AdminLogin);
