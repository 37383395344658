export const vcWeightList = [
  { text: "라보", value: "라보" },
  { text: "다마스", value: "다마스" },
  { text: "1톤", value: "1톤" },
  { text: "1.4톤", value: "1.4톤" },
  { text: "2.5톤", value: "2.5톤" },
  { text: "3.5톤", value: "3.5톤" },
  { text: "4.5톤", value: "4.5톤" },
  { text: "5톤", value: "5톤" },
  { text: "8톤", value: "8톤" },
  { text: "11톤", value: "11톤" },
  { text: "14톤", value: "14톤" },
  { text: "15톤", value: "15톤" },
  { text: "18톤", value: "18톤" },
  { text: "25톤", value: "25톤" },
  { text: "츄레라", value: "츄레라" },
];

export const vcTypList = [
  { text: "전차종가능", value: "전차종가능" },
  { text: "카고", value: "카고" },
  { text: "냉동탑", value: "냉동탑" },
  { text: "냉장윙", value: "냉장윙" },
  { text: "냉장윙축", value: "냉장윙축" },
  { text: "냉장탑", value: "냉장탑" },
  { text: "리프트", value: "리프트" },
  { text: "리프트탑", value: "리프트탑" },
  { text: "리프트윙", value: "리프트윙" },
  { text: "리프트호루", value: "리프트호루" },
  { text: "무징동", value: "무징동" },
  { text: "윙바디", value: "윙바디" },
  { text: "윙축", value: "윙축" },
  { text: "축카고", value: "축카고" },
  { text: "탑차", value: "탑차" },
  { text: "플러스윙", value: "플러스윙" },
  { text: "플러스카", value: "플러스카" },
  { text: "플축윙", value: "플축윙" },
  { text: "플축카고", value: "플축카고" },
  { text: "호루", value: "호루" },
];

export const freightInfoList = [
  { text: "파렛트", value: "파렛트" },
  { text: "가전제품", value: "가전제품" },
  { text: "공사,건축,인테리어 자재", value: "공사,건축,인테리어 자재" },
  { text: "가구", value: "가구" },
  { text: "이사물품", value: "이사물품" },
  { text: "기계류", value: "기계류" },
  { text: "오통바이,자전거 등", value: "오통바이, 자전거 등" },
  { text: "운동기구", value: "운동기구" },
  { text: "박스", value: "박스" },
  { text: "피아노 등 악기류", value: "피아노 등 악기류" },
  { text: "모래, 흙", value: "모래, 흙" },
  { text: "조경수, 나무, 꽃", value: "조경수, 나무, 꽃" },
  { text: "미술용품", value: "미술용품" },
  { text: "과일,야채,채소,곡식류", value: "과일,야채,채소,곡식류" },
  { text: "음료,주류", value: "음료,주류" },
  { text: "기타", value: "기타" },
];

export const networkCdList = [
  { text: "전국24시", value: "전국24시" },
  { text: "원콜", value: "원콜" },
  { text: "직접운송", value: "직접운송" },
];

export const freightCdList = [
  { text: "배차대기", value: "배차대기" },
  { text: "배차완료", value: "배차완료" },
  { text: "운송완료", value: "운송완료" },
  { text: "운송취소", value: "운송취소" },
];

export const loadingCdList = [
  { text: "수작업", value: "수작업" },
  { text: "수해줌", value: "수해줌" },
  { text: "지게차", value: "지게차" },
  { text: "호이스트", value: "호이스트" },
];

export const PayCdList = [
  { text: "선불", value: "선불" },
  { text: "착불", value: "착불" },
  { text: "인수증", value: "인수증" },
];

export const logisCdList = [
  { text: "일반", value: "일반" },
  { text: "긴급", value: "긴급" },
  { text: "혼적", value: "혼적" },
  { text: "왕복", value: "왕복" },
];
