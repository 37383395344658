import React, { Component } from "react";
import { Button, Modal, Input, Form, Table } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import axios from "axios";
import {
  COLOR_FONT_BLACK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import { API_SELECT_COMP_SEARCHLIST } from "../../common/ApiAddress/CompAPI";
@inject((stores) => ({
  commonStore: stores.CommonStore,
  compStore: stores.CompStore,
}))
@observer
class SearchCompModal extends Component {
  fnSelectCompSearchList = (currentPage, compNm) => {
    const { compStore } = this.props;
    compStore.setCompNm(compNm);
    let params = {};
    if (currentPage == undefined || currentPage == 1) {
      params.startCnt = 0;
      params.endCnt = 10;
      params.compNm = compNm;
    } else {
      params.startCnt = (currentPage - 1) * 10;
      params.endCnt = 10;
      params.compNm = compNm;
    }
    axios({
      url: API_SELECT_COMP_SEARCHLIST,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          compStore.setCompList(response.data.data);
          compStore.setTotalPage(response.data.totalPageNum);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { commonStore, compStore } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={commonStore.searchCompModal}
        onClose={() => {
          compStore.initCompNm();
          compStore.initCompList();
          commonStore.searchCompModalClose();
        }}
      >
        <Modal.Header>기사검색</Modal.Header>
        <Modal.Content>
          <Form.Field>
            <Input
              style={{ width: "98%" }}
              placeholder={"거래처명 검색"}
              onChange={(e) => this.fnSelectCompSearchList(1, e.target.value)}
              value={compStore.compNm}
            />
          </Form.Field>
          <Table celled style={{ textAlign: "center" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>거래처명</Table.HeaderCell>
                <Table.HeaderCell>사업자번호</Table.HeaderCell>
                <Table.HeaderCell>연락처</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {compStore.compList == null || compStore.compList.length == 0 ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan="3" textAlign="center">
                    등록된 거래처가 없습니다.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body>
                {compStore.compList.map((data, i) => (
                  <React.Fragment key={data.compNo}>
                    <Table.Row
                      style={{
                        backgroundColor:
                          compStore.selectedComp != null &&
                          data.compNo == compStore.selectedComp.compNo
                            ? COLOR_PRIMARY
                            : COLOR_WHITE,
                        cursor: "pointer",
                        color:
                          compStore.selectedComp != null &&
                          data.compNo == compStore.selectedComp.compNo
                            ? COLOR_WHITE
                            : COLOR_FONT_BLACK,
                      }}
                      onClick={() => compStore.setSelectedComp(data)}
                    >
                      <Table.Cell>{data.compNm}</Table.Cell>
                      <Table.Cell>{data.compBsNo}</Table.Cell>
                      <Table.Cell>{data.compHpNo}</Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                ))}
              </Table.Body>
            )}
          </Table>
        </Modal.Content>
        <div
          style={{
            float: "right",
            marginTop: "20px",
            marginRight: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            negative
            onClick={() => {
              compStore.initCompNm();
              compStore.initCompList();
              commonStore.searchCompModalClose();
            }}
          >
            아니오
          </Button>
          <Button positive onClick={() => this.props.fnUpdateCompInfo()}>
            예
          </Button>
        </div>
      </Modal>
    );
  }
}

export default SearchCompModal;
