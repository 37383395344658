import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import CommonAlert from "../../Component/Common/CommonAlert";
import MediaQuery from "react-responsive";
import { COLOR_PRIMARY, COLOR_WHITE } from "../../common/Color";
@inject((stores) => ({
  driverStore: stores.DriverStore,
  commonStore: stores.CommonStore,
}))
@observer
class DrvierMain extends React.Component {
  componentDidMount() {
    const { driverStore } = this.props;
    this.props.fnGetGeoLocation();
    this.props.fnSelectCurrentFreight();
  }

  render() {
    const { driverStore, commonStore } = this.props;
    return (
      <Fragment>
        <MediaQuery maxWidth={480}>
          <div
            style={{ width: "100%", height: "100vh", background: "#F8F9FA" }}
          >
            <div
              style={{
                width: "100%",
                fontWeight: "700",
                fontSize: "1.5rem",
                textAlign: "center",
                paddingTop: "90px",
                height: "150px",
              }}
            >
              <p>나의 배차 현황</p>
            </div>
            <div
              style={{
                width: "90%",
                boxShadow: "0px 4px 4px 0px #00000040",
                background: COLOR_WHITE,
                height: "400px",
                borderRadius: "10px",
                margin: "0 auto",
                padding: "20px",
                display: "flex",
                justifyItems: "center",
              }}
            >
              {driverStore.currentFreight == null ? (
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h2>현재 배차내역이 없습니다.</h2>
                </div>
              ) : (
                <table style={{ width: "100%" }}>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      상차지
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {driverStore.currentFreight.loadingSido +
                        " " +
                        driverStore.currentFreight.loadingSido +
                        " " +
                        driverStore.currentFreight.loadingDong +
                        " " +
                        driverStore.currentFreight.loadingDetailAdd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      하차지
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {driverStore.currentFreight.unloadingSido +
                        " " +
                        driverStore.currentFreight.unloadingSido +
                        " " +
                        driverStore.currentFreight.unloadingDong +
                        " " +
                        driverStore.currentFreight.unloadingDetailAdd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      화물정보
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {driverStore.currentFreight.freightInfo}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      상차방법
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {driverStore.currentFreight.loadingCd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      하차방법
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {driverStore.currentFreight.unloadingCd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      운송형태
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {driverStore.currentFreight.logisCd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      결제방법
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {driverStore.currentFreight.payCd}
                    </td>
                  </tr>
                </table>
              )}
            </div>
            <div style={{ marginTop: "10%" }}>
              {driverStore.currentFreight == null ? null : (
                <div
                  style={{
                    width: "90%",
                    margin: "0 auto",
                    height: "45px",
                    background: COLOR_PRIMARY,
                    fontSize: "1.55rem",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: COLOR_WHITE,
                    borderRadius: "30px",
                  }}
                  onClick={() => this.props.fnUpdateFreightComplete()}
                >
                  <p>운송완료</p>
                </div>
              )}

              <div
                style={{
                  width: "90%",
                  margin: "0 auto",
                  height: "45px",
                  background: "#2A8CC3",
                  fontSize: "1.55rem",
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: COLOR_WHITE,
                  borderRadius: "30px",
                  marginTop: "5%",
                }}
                onClick={() => this.props.fnUpdateGeoLocation()}
              >
                <p>위치전송</p>
              </div>
              {driverStore.currentFreight == null ? (
                <div
                  style={{
                    width: "90%",
                    margin: "0 auto",
                    height: "45px",
                    background: "#1B264E",
                    fontSize: "1.55rem",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: COLOR_WHITE,
                    borderRadius: "30px",
                    marginTop: "5%",
                  }}
                  onClick={() => this.props.fnUpdateDriverIsEmpty()}
                >
                  <p>공차알림</p>
                </div>
              ) : null}
            </div>

            <CommonAlert />
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(DrvierMain);
