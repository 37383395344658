import { observable, action, reaction, makeObservable, toJS } from "mobx";

class CommonStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }
  @observable path = "/";

  @observable sidoList = [];
  @observable siguList = [];
  @observable dongList = [];

  @observable selectedSido = null;
  @observable selectedSigu = null;
  @observable selectedDong = null;

  @observable unloadingSidoList = [];
  @observable unloadingSiguList = [];
  @observable unloadingDongList = [];

  @observable unloadingSelectedSido = null;
  @observable unloadingSelectedSigu = null;
  @observable unloadingSelectedDong = null;

  @observable vcWeight = null;
  @observable vcTyp = null;

  @observable freightInfo = null;
  @observable hpNo = null;

  @observable loadingDetailAdd = null;
  @observable unloadingDetailAdd = null;

  @observable commonAlert = { isOpen: false, content: null };
  @observable commonAlertWithCallBack = { isOpen: false, content: null };

  @observable fileUploadModal = false;

  @observable confirmModalForDelete = { isOpen: false, content: null };
  @observable searchDriverModal = false;
  @observable searchCompModal = false;
  @observable searchCompleteFreightModal = false;

  @observable logisFee = 0;
  @observable commission = 0;
  @observable totalFee = 0;
  @observable networkCd = null;
  @observable freightCd = null;

  @observable loadingCd = null;
  @observable unloadingCd = null;
  @observable payCd = null;
  @observable logisCd = null;

  @observable dateExcel = null;

  @observable corporationCompleteModal = { isOpen: false, content: null };

  @action
  setPath = (value) => {
    this.path = value;
  };

  @action
  initPath = () => {
    this.path = "/";
  };

  @action
  setSidoList = (value) => {
    this.sidoList = value;
  };

  @action
  initSidoList = () => {
    this.sidoList = [];
  };

  @action
  setSiguList = (value) => {
    this.siguList = value;
  };

  @action
  initSiguList = () => {
    this.siguList = [];
  };

  @action
  setDongList = (value) => {
    this.dongList = value;
  };

  @action
  initDongList = () => {
    this.dongList = [];
  };

  @action
  setSelectedSido = (value) => {
    this.selectedSido = value;
  };

  @action
  initSelectedSido = () => {
    this.selectedSido = null;
  };

  @action
  setSelectedSigu = (value) => {
    this.selectedSigu = value;
  };

  @action
  initSelectedSigu = () => {
    this.selectedSigu = null;
  };

  @action
  setSelectedDong = (value) => {
    this.selectedDong = value;
  };

  @action
  initSelectedDong = () => {
    this.selectedDong = null;
  };

  @action
  initLocation = () => {
    this.initSidoList();
    this.initSidoList();
    this.initDongList();
    this.initSelectedSido();
    this.initSelectedSigu();
    this.initSelectedDong();
    this.initUnloadingSidoList();
    this.initUnloadingSiguList();
    this.initUnloadingDongList();
    this.initUnloadingSelectedSido();
    this.initUnloadingSelectedSigu();
    this.intiUnloadingSelectedDong();
    this.initVcWeight();
    this.initVcTyp();
    this.initFreightInfo();
    this.initHpNo();
    this.initLoadingDetailAdd();
    this.initUnloadingDetailAdd();
    this.initLoadingCd();
    this.initUnloadingCd();
    this.initPayCd();
  };

  @action
  setUnloadingSidoList = (value) => {
    this.unloadingSidoList = value;
  };

  @action
  initUnloadingSidoList = () => {
    this.unloadingSidoList = [];
  };

  @action
  setUnloadingSiguList = (value) => {
    this.unloadingSiguList = value;
  };

  @action
  initUnloadingSiguList = () => {
    this.unloadingSiguList = [];
  };

  @action
  setUnloadingDongList = (value) => {
    this.unloadingDongList = value;
  };

  @action
  initUnloadingDongList = () => {
    this.unloadingDongList = [];
  };

  @action
  setUnloadingSelectedSido = (value) => {
    this.unloadingSelectedSido = value;
  };

  @action
  initUnloadingSelectedSido = () => {
    this.unloadingSelectedSido = null;
  };

  @action
  setUnloadingSelectedSigu = (value) => {
    this.unloadingSelectedSigu = value;
  };

  @action
  initUnloadingSelectedSigu = () => {
    this.unloadingSelectedSigu = null;
  };

  @action
  setUnloadingSelectedDong = (value) => {
    this.unloadingSelectedDong = value;
  };

  @action
  intiUnloadingSelectedDong = () => {
    this.unloadingSelectedDong = null;
  };

  @action
  setVcWeight = (value) => {
    this.vcWeight = value;
  };

  @action
  initVcWeight = () => {
    this.vcWeight = null;
  };

  @action
  setVcTyp = (value) => {
    this.vcTyp = value;
  };

  @action
  initVcTyp = () => {
    this.vcTyp = null;
  };

  @action
  setFreightInfo = (value) => {
    this.freightInfo = value;
  };

  @action
  initFreightInfo = () => {
    this.freightInfo = null;
  };

  @action
  setHpNo = (value) => {
    this.hpNo = value;
  };

  @action
  initHpNo = () => {
    this.hpNo = null;
  };

  @action
  setLoadingDetailAdd = (value) => {
    this.loadingDetailAdd = value;
  };

  @action
  initLoadingDetailAdd = () => {
    this.loadingDetailAdd = null;
  };

  @action
  setUnloadingDetailAdd = (value) => {
    this.unloadingDetailAdd = value;
  };

  @action
  initUnloadingDetailAdd = () => {
    this.unloadingDetailAdd = null;
  };

  @action
  commonAlertOpen = (value) => {
    this.commonAlert = { isOpen: true, content: value };
  };

  @action
  commonAlertClose = () => {
    this.commonAlert = { isOpen: false, content: null };
  };

  @action
  commonAlertWithCallbackOpen = (value) => {
    this.commonAlertWithCallBack = { isOpen: true, content: value };
  };

  @action
  commonAlertWithCallbackClose = () => {
    this.commonAlertWithCallBack = { isOpen: false, content: null };
  };

  @action
  fileUploadModalOepn = () => {
    this.fileUploadModal = true;
  };

  @action
  fileUploadModalClose = () => {
    this.fileUploadModal = false;
  };

  @action
  confirmModalForDeleteOpen = (value) => {
    this.confirmModalForDelete = { isOpen: true, content: value };
  };

  @action
  confirmModalForDeleteClose = () => {
    this.confirmModalForDelete = { isOpen: false, content: null };
  };

  @action
  setLogisFee = (value) => {
    this.logisFee = value;
  };

  @action
  initLogisFee = () => {
    this.logisFee = 0;
  };

  @action
  setCommission = (value) => {
    this.commission = value;
  };

  @action
  initCommission = () => {
    this.commission = 0;
  };

  @action
  setTotalFee = (value) => {
    this.totalFee = value;
  };

  @action
  initTotalFee = () => {
    this.totalFee = 0;
  };

  @action
  setNetworkCd = (value) => {
    this.networkCd = value;
  };

  @action
  initNetworkCd = () => {
    this.networkCd = null;
  };

  @action
  setFreightCd = (value) => {
    this.freightCd = value;
  };

  @action
  initFreightCd = () => {
    this.freightCd = null;
  };

  @action
  searchDriverModalOepn = () => {
    this.searchDriverModal = true;
  };

  @action
  searchDriverModalClose = () => {
    this.searchDriverModal = false;
  };

  @action
  searchCompModalOpen = () => {
    this.searchCompModal = true;
  };

  @action
  searchCompModalClose = () => {
    this.searchCompModal = false;
  };

  @action
  searchCompleteFreightModalOpen = () => {
    this.searchCompleteFreightModal = true;
  };

  @action
  searchCompleteFreightModalClose = () => {
    this.searchCompleteFreightModal = false;
  };

  @action
  setDateExcel = (value) => {
    this.dateExcel = value;
  };

  @action
  initDateExcel = () => {
    this.dateExcel = null;
  };

  @action
  setLoadingCd = (value) => {
    this.loadingCd = value;
  };

  @action
  initLoadingCd = () => {
    this.loadingCd = null;
  };

  @action
  setUnloadingCd = (value) => {
    this.unloadingCd = value;
  };

  @action
  initUnloadingCd = () => {
    this.unloadingCd = null;
  };

  @action
  setPayCd = (value) => {
    this.payCd = value;
  };

  @action
  initPayCd = () => {
    this.payCd = null;
  };
  @action
  setLogisCd = (value) => {
    this.logisCd = value;
  };

  @action
  initLogisCd = () => {
    this.logisCd = null;
  };

  @action
  corporationCompleteModalOpen = (value) => {
    this.corporationCompleteModal = { isOpen: true, content: value };
  };

  @action
  corporationCompleteModalClose = () => {
    this.corporationCompleteModal = { isOpen: false, content: null };
  };
}

export default CommonStore;
