import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  COLOR_BLACK,
  COLOR_FONT_GRAY,
  COLOR_PRIMARY,
  COLOR_WHITE,
  COLOR_BACKGROUND_GRAY,
  COLOR_GRAY_GR06,
} from "../../common/Color";
import { inject, observer } from "mobx-react";
import { Checkbox, Form, Input, TextArea } from "semantic-ui-react";
import axios from "axios";
import CommonAlert from "../../Component/Common/CommonAlert";
import CorporationCompleteModal from "../../Component/Common/CorporationCompleteModal";
import { API_INSERT_CORPORATION } from "../../common/ApiAddress/CorporationAPI";
import MediaQuery from "react-responsive";
@inject((stores) => ({
  quoteStore: stores.QuoteStore,
  commonStore: stores.CommonStore,
}))
@observer
class Corporation extends React.Component {
  handleMonthlyCount = (e, { value }) => {
    const { quoteStore } = this.props;
    quoteStore.setMonthCountValue(value);
  };

  fnSendB2BContact = () => {
    const { quoteStore, commonStore } = this.props;

    let params = {};
    let that = this;

    if (quoteStore.companyNm == null || quoteStore.companyNm == "") {
      commonStore.commonAlertOpen("회사명을 작성해 주세요.");
      return false;
    }

    if (quoteStore.companyEmail == null || quoteStore.companyEmail == "") {
      commonStore.commonAlertOpen("이메일을 작성해 주세요.");
      return false;
    }

    if (quoteStore.requestNm == null || quoteStore.requestNm == "") {
      commonStore.commonAlertOpen("담당자명을 작성해 주세요.");
      return false;
    }

    if (quoteStore.requestHpNo == null || quoteStore.requestHpNo == "") {
      commonStore.commonAlertOpen("연락처를 작성해 주세요.");
      return false;
    }

    if (quoteStore.feightInfo == null || quoteStore.feightInfo == "") {
      commonStore.commonAlertOpen("운송품목을 작성해 주세요.");
      return false;
    }

    if (quoteStore.requestContent == null || quoteStore.requestContent == "") {
      commonStore.commonAlertOpen("문의사항을 작성해 주세요.");
      return false;
    }

    params.companyNm = quoteStore.companyNm;
    let content = "";
    content = content + "회사명 : " + quoteStore.companyNm + "\n";
    content = content + "이메일 : " + quoteStore.companyEmail + "\n";
    content = content + "담당자명 : " + quoteStore.requestNm + "\n";
    content = content + "담당자 연락처 : " + quoteStore.requestHpNo + "\n";

    if (quoteStore.localeList.length > 0) {
      content =
        content +
        "이용지역 : " +
        quoteStore.localeList.join().toString() +
        "\n";
    }

    if (quoteStore.vcWeightList.length > 0) {
      content =
        content +
        "이용차량 톤수 : " +
        quoteStore.vcWeightList.join().toString() +
        "\n";
    }

    if (quoteStore.vcWeightList.length > 0) {
      content =
        content +
        "이용차량 종류 : " +
        quoteStore.vcTypList.join().toString() +
        "\n";
    }

    if (quoteStore.vcWeightList.length > 0) {
      content = content + "월 이용횟수 : " + quoteStore.monthCountValue + "\n";
    }

    content = content + "운송품목 : " + quoteStore.feightInfo + "\n";
    content = content + "문의사항 : " + quoteStore.requestContent + "\n";

    params.content = content;

    axios({
      url: API_INSERT_CORPORATION,
      method: "post",
      data: params,
    })
      .then((response) => {
        if (response.data.resultCode == 100) {
          that.fnGetMsgAuth();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnGetMsgAuth = () => {
    let that = this;
    axios({
      url: "https://htruck.co.kr/exapi/msgAuth",
      method: "post",
    })
      .then((response) => {
        that.fnSendMsgCorporationRequest(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSendMsgCorporationRequest = (authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content = "기업 거래 문의가 접수되었습니다. 내역을 확인해주세요.";

    //phone.push("01031254802");
    phone.push("01088521982");
    //phone.push(msgData.hpNo);
    id.push("ttSystem");
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;
    axios({
      url: "https://htruck.co.kr/exapi/msgSendLmsforTrucker",
      method: "get",
      params: params,
    })
      .then(function (response) {
        if (response.data.code == "1000") {
          commonStore.corporationCompleteModalOpen(
            "내역 확인 후 빠르게 연락드리도록 하겠습니다!"
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { quoteStore } = this.props;
    const localeListObj = [
      {
        text: "서울특별시",
        value: "서울특별시",
      },
      {
        text: "경기도",
        value: "경기도",
      },
      {
        text: "인천광역시",
        value: "인천광역시",
      },
      {
        text: "그 외 지역",
        value: "그 외 지역",
      },
    ];

    const vcWeightListObj = [
      {
        text: "0.3톤",
        value: "0.3톤",
      },
      {
        text: "0.5톤",
        value: "0.5톤",
      },
      {
        text: "1톤",
        value: "1톤",
      },
      {
        text: "1.4톤",
        value: "1.4톤",
      },
      {
        text: "2.5톤",
        value: "2.5톤",
      },
      {
        text: "3.5톤",
        value: "3.5톤",
      },
      {
        text: "3.5톤 광폭",
        value: "3.5톤 광폭",
      },
      {
        text: "5톤",
        value: "5톤",
      },
      {
        text: "5톤 플러스",
        value: "5톤 플러스",
      },
      {
        text: "5톤 축",
        value: "5톤 축",
      },
      {
        text: "5톤 풀축",
        value: "5톤 풀축",
      },
      {
        text: "8톤",
        value: "8톤",
      },
      {
        text: "11톤",
        value: "11톤",
      },
      {
        text: "14톤",
        value: "14톤",
      },
      {
        text: "18톤",
        value: "18톤",
      },
      {
        text: "25톤",
        value: "25톤",
      },
    ];

    const vcTypListObj = [
      {
        text: "다마스",
        value: "다마스",
      },
      {
        text: "라보",
        value: "라보",
      },
      {
        text: "라보호루",
        value: "라보호루",
      },
      {
        text: "카고",
        value: "카고",
      },
      {
        text: "윙바디",
        value: "윙바디",
      },
      {
        text: "리프트",
        value: "리프트",
      },
      {
        text: "윙바디 리프트",
        value: "윙바디 리프트",
      },
      {
        text: "호루 리프트",
        value: "호루 리프트",
      },
      {
        text: "냉장탑",
        value: "냉장탑",
      },
      {
        text: "냉동탑",
        value: "냉동탑",
      },
    ];

    const monthlyCount = [
      { text: "1~10회", value: "1~10회" },
      { text: "11~50회", value: "11~50회" },
      { text: "51~100회", value: "51~100회" },
      { text: "100회 이상", value: "100회 이상" },
    ];
    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                marginBottom: "42px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "90%",
                  background: COLOR_WHITE,
                  borderRadius: "12px",
                  paddingLeft: "28px",
                  marginTop: "56px",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "24px",
                  }}
                >
                  기업 계약거래 신청
                </p>

                <div>
                  <div
                    style={{
                      width: "100%",
                      hegith: "63px",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      회사명
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.companyNm || ""}
                        onChange={(e) =>
                          quoteStore.setCompanyNm(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      hegith: "63px",
                      overflow: "hidden",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      이메일
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.companyEmail || ""}
                        onChange={(e) =>
                          quoteStore.setCompanyEmail(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      width: "100%",
                      hegith: "63px",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      담당자명
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.requestNm || ""}
                        onChange={(e) =>
                          quoteStore.setRequestNm(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      hegith: "63px",
                      overflow: "hidden",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      연락처
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.requestHpNo || ""}
                        onChange={(e) =>
                          quoteStore.setRequestHpNo(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    이용지역
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      (출발지 기준, 중복선택 가능)
                    </span>
                  </p>
                  {localeListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{
                        width: "50%",
                        paddingTop: i > 1 ? "13px" : "0px",
                      }}
                      onChange={() => quoteStore.setLocaleList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    이용차량 톤수
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcWeightListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: "33%", marginTop: "13px" }}
                      onChange={() => quoteStore.setVcWeightList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    이용차량 종류
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcTypListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: "33%", marginTop: "13px" }}
                      onChange={() => quoteStore.setVcTypList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    월 이용 횟수
                  </p>
                  {monthlyCount.map((data, i) => (
                    <Checkbox
                      radio={true}
                      label={data.text}
                      value={data.value}
                      style={{ width: "50%", marginTop: "13px" }}
                      checked={quoteStore.monthCountValue == data.value}
                      onChange={this.handleMonthlyCount}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    운송 품목
                    <span style={{ color: COLOR_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: "none",
                        width: "100%",
                        background: COLOR_BACKGROUND_GRAY,
                        border: "0px",
                      }}
                      onChange={(e) => {
                        quoteStore.setFeightInfo(e.target.value);
                      }}
                    />
                  </Form>
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    문의 사항
                    <span style={{ color: COLOR_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: "none",
                        width: "100%",
                        background: COLOR_BACKGROUND_GRAY,
                        border: "0px",
                      }}
                      onChange={(e) => {
                        quoteStore.setRequestContent(e.target.value);
                      }}
                    />
                  </Form>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      height: "67px",
                      background: COLOR_PRIMARY,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      marginTop: "23px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.fnSendB2BContact();
                    }}
                  >
                    <p
                      style={{
                        color: COLOR_WHITE,
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      기업거래 문의하기
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <CommonAlert />
            <CorporationCompleteModal />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769} maxWidth={1249}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                marginBottom: "42px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  background: COLOR_WHITE,
                  borderRadius: "12px",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "24px",
                    paddingTop: "27px",
                  }}
                >
                  기업 계약거래 신청
                </p>

                <div>
                  <div
                    style={{
                      float: "left",
                      width: "48%",
                      hegith: "63px",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      회사명
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.companyNm || ""}
                        onChange={(e) =>
                          quoteStore.setCompanyNm(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "48%",
                      hegith: "63px",
                      overflow: "hidden",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      이메일
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.companyEmail || ""}
                        onChange={(e) =>
                          quoteStore.setCompanyEmail(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      float: "left",
                      width: "48%",
                      hegith: "63px",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      담당자명
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.requestNm || ""}
                        onChange={(e) =>
                          quoteStore.setRequestNm(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "48%",
                      hegith: "63px",
                      overflow: "hidden",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      연락처
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.requestHpNo || ""}
                        onChange={(e) =>
                          quoteStore.setRequestHpNo(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    이용지역
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      (출발지 기준, 중복선택 가능)
                    </span>
                  </p>
                  {localeListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: "25%" }}
                      onChange={() => quoteStore.setLocaleList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    이용차량 톤수
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcWeightListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: "25%", marginTop: "13px" }}
                      onChange={() => quoteStore.setVcWeightList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    이용차량 종류
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcTypListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: "25%", marginTop: "13px" }}
                      onChange={() => quoteStore.setVcTypList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    월 이용 횟수
                  </p>
                  {monthlyCount.map((data, i) => (
                    <Checkbox
                      radio={true}
                      label={data.text}
                      value={data.value}
                      style={{ width: "25%", marginTop: "13px" }}
                      checked={quoteStore.monthCountValue == data.value}
                      onChange={this.handleMonthlyCount}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    운송 품목
                    <span style={{ color: COLOR_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: "none",
                        width: "100%",
                        background: COLOR_BACKGROUND_GRAY,
                        border: "0px",
                      }}
                      onChange={(e) => {
                        quoteStore.setFeightInfo(e.target.value);
                      }}
                    />
                  </Form>
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    문의 사항
                    <span style={{ color: COLOR_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: "none",
                        width: "100%",
                        background: COLOR_BACKGROUND_GRAY,
                        border: "0px",
                      }}
                      onChange={(e) => {
                        quoteStore.setRequestContent(e.target.value);
                      }}
                    />
                  </Form>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      height: "67px",
                      background: COLOR_PRIMARY,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      marginTop: "23px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.fnSendB2BContact();
                    }}
                  >
                    <p
                      style={{
                        color: COLOR_WHITE,
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      기업거래 문의하기
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <CommonAlert />
            <CorporationCompleteModal />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1250}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                marginTop: "38px",
                marginBottom: "42px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "1000px",
                  background: COLOR_WHITE,
                  borderRadius: "12px",
                  paddingLeft: "28px",
                  marginTop: "56px",
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "24px",
                    paddingTop: "27px",
                  }}
                >
                  기업 계약거래 신청
                </p>

                <div>
                  <div
                    style={{
                      float: "left",
                      width: "48%",
                      hegith: "63px",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      회사명
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.companyNm || ""}
                        onChange={(e) =>
                          quoteStore.setCompanyNm(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "48%",
                      hegith: "63px",
                      overflow: "hidden",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      이메일
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.companyEmail || ""}
                        onChange={(e) =>
                          quoteStore.setCompanyEmail(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      float: "left",
                      width: "48%",
                      hegith: "63px",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      담당자명
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.requestNm || ""}
                        onChange={(e) =>
                          quoteStore.setRequestNm(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "48%",
                      hegith: "63px",
                      overflow: "hidden",
                      fontsize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <label>
                      연락처
                      <span style={{ color: COLOR_PRIMARY }}>*</span>
                    </label>
                    <div style={{ width: "90%", height: "63px" }}>
                      <input
                        type={"text"}
                        style={{
                          border: "0px",
                          borderBottom: "1px solid #8F8F8F",
                          width: "100%",
                        }}
                        value={quoteStore.requestHpNo || ""}
                        onChange={(e) =>
                          quoteStore.setRequestHpNo(e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    이용지역
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      (출발지 기준, 중복선택 가능)
                    </span>
                  </p>
                  {localeListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: "25%" }}
                      onChange={() => quoteStore.setLocaleList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    이용차량 톤수
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcWeightListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: "25%", marginTop: "13px" }}
                      onChange={() => quoteStore.setVcWeightList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    이용차량 종류
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      (중복선택 가능)
                    </span>
                  </p>
                  {vcTypListObj.map((data, i) => (
                    <Checkbox
                      label={data.text}
                      style={{ width: "25%", marginTop: "13px" }}
                      onChange={() => quoteStore.setVcTypList(data.value)}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    월 이용 횟수
                  </p>
                  {monthlyCount.map((data, i) => (
                    <Checkbox
                      radio={true}
                      label={data.text}
                      value={data.value}
                      style={{ width: "25%", marginTop: "13px" }}
                      checked={quoteStore.monthCountValue == data.value}
                      onChange={this.handleMonthlyCount}
                    />
                  ))}
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    운송 품목
                    <span style={{ color: COLOR_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: "none",
                        width: "944px",
                        background: COLOR_BACKGROUND_GRAY,
                        border: "0px",
                      }}
                      onChange={(e) => {
                        quoteStore.setFeightInfo(e.target.value);
                      }}
                    />
                  </Form>
                </div>

                <div style={{ marginTop: "41px" }}>
                  <p style={{ fontSize: "16px", fontWeight: "700" }}>
                    문의 사항
                    <span style={{ color: COLOR_PRIMARY }}>*</span>
                  </p>
                  <Form>
                    <TextArea
                      rows={8}
                      style={{
                        resize: "none",
                        width: "944px",
                        background: COLOR_BACKGROUND_GRAY,
                        border: "0px",
                      }}
                      onChange={(e) => {
                        quoteStore.setRequestContent(e.target.value);
                      }}
                    />
                  </Form>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      height: "67px",
                      background: COLOR_PRIMARY,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      marginTop: "23px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.fnSendB2BContact();
                    }}
                  >
                    <p
                      style={{
                        color: COLOR_WHITE,
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      기업거래 문의하기
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <CommonAlert />
            <CorporationCompleteModal />
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(Corporation);
