import { apiAddress } from "../ApiPlatform";

export const API_INSERT_DRIVER = apiAddress + "driver/insertDriver";

export const API_SELECT_DRIVER_LIST = apiAddress + "driver/selectDriverList";

export const API_UPDATE_DRIVER = apiAddress + "driver/updateDriver";
export const API_DELETE_DRIVER = apiAddress + "driver/deleteDriver";
export const API_RECOVER_DRIVER = apiAddress + "driver/recoverDriver";

export const API_INSERT_DRIVER_BULK = apiAddress + "driver/insertDriverBulk";

export const API_LOGIN_DRIVER = apiAddress + "driver/login";

export const API_UPDATE_GEOLOCATION = apiAddress + "driver/updateGeolocation";
export const API_SELECT_GEOLOCATION_LIST =
  apiAddress + "driver/selectGeolocationList";

export const API_SELECT_DRIVER_SEARCHLIST =
  apiAddress + "driver/selectDriverSearchList";

export const API_SELECT_CURRENT_FREIGHT =
  apiAddress + "driver/selectCurrentFreight";

export const API_UPDATE_ISEMPTY = apiAddress + "driver/updateIsEmpty";

export const API_UPDATE_USEYN_BLUK = apiAddress + "driver/updateUseYnBluk";
