import { observable, action, reaction, makeObservable, toJS } from "mobx";

class FreightStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable freightList = [];
  @observable selectedFreight = null;

  @observable currentPage = 1;
  @observable totalPage = 1;

  @observable freightCompleteList = [];

  @observable selectedFreightList = [];

  @action
  setFreightList = (value) => {
    this.freightList = value;
  };

  @action
  initFreightList = () => {
    this.freightList = [];
  };

  @action
  setSelectedFreight = (value) => {
    this.selectedFreight = value;
  };

  @action
  initSelectedFreight = () => {
    this.selectedFreight = null;
  };

  @action
  setCurrentPage = (value) => {
    this.currentPage = value;
  };

  @action
  initCurrentPage = () => {
    this.currentPage = 1;
  };

  @action
  setTotalPage = (value) => {
    this.totalPage = value;
  };

  @action
  initTotalPage = () => {
    this.totalPage = 1;
  };

  @action
  setFreightCompleteList = (value) => {
    this.freightCompleteList = value;
  };

  @action
  initFreightCompleteList = () => {
    this.freightCompleteList = [];
  };

  @action
  addSelectedFreightList = (value) => {
    this.selectedFreightList.push(value);
  };

  @action
  removeSelectedFreightList = (value) => {
    //인덱스 찾아서 삭제
    let index = this.selectedFreightList.findIndex(
      (obj) => obj.freightNo == value.freightNo
    );
    this.selectedFreightList.splice(index, 1);
  };
}

export default FreightStore;
