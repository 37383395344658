import { observable, action, reaction, makeObservable, toJS } from "mobx";

class AccStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable adminId = null;
  @observable adminPw = null;

  @action
  setAdminId = (value) => {
    this.adminId = value;
  };

  @action
  initAdminId = () => {
    this.adminId = null;
  };

  @action
  setAdminPw = (value) => {
    this.adminPw = value;
  };

  @action
  initAdminPw = () => {
    this.adminPw = null;
  };
}

export default AccStore;
