import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import Header from "../Component/Common/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import { ReactComponent as MainTopCar } from "../assets/mainTopCar.svg";
import { ReactComponent as MainMiddle1 } from "../assets/mainMiddle_1.svg";
import { ReactComponent as MainMiddle2 } from "../assets/mainMiddle_2.svg";
import { ReactComponent as MainMiddle3 } from "../assets/mainMiddle_3.svg";
import { ReactComponent as MainMiddle4 } from "../assets/mainMiddle_4.svg";
import { ReactComponent as MainImage1 } from "../assets/mainImage1.svg";
import { ReactComponent as MainImage2 } from "../assets/mainImage2.svg";
import { ReactComponent as MainImage3 } from "../assets/mainImage3.svg";
import { ReactComponent as MainBottomImg1 } from "../assets/mainBottomImg1.svg";
import { ReactComponent as MainBottomImg2 } from "../assets/mainBottomImg2.svg";
import { ReactComponent as MainBottomImg3 } from "../assets/mainBottomImg3.svg";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_FONT_BLACK,
  COLOR_FONT_GRAY,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../common/Color";
import MediaQuery from "react-responsive";

class Main extends React.Component {
  fnRouterChage = (path) => {
    this.props.history.push(path);
  };

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <Fragment>
        <MediaQuery maxWidth={480}>
          <div
            style={{ width: "100%", height: "auto", overflowX: "hidden" }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "632px",
                background: COLOR_PRIMARY,
                backgroundImage: "url(./image/greenBack.svg)",
                backgroundSize: "100%",
                backgroundPositionY: "100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                style={{
                  width: "80%",
                  height: "100%",
                  paddingTop: "54px",
                  margin: "0 auto ",
                  marginBottom: "10px",
                }}
              >
                <p
                  data-aos="fade-down"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "400",
                    fontSize: "1.4rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  차이를 만드는 AI상담기술
                </p>
                <div
                  data-aos="fade-down"
                  data-aos-delay="350"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "300",
                    fontSize: "2.2rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                    marginBottom: "12px",
                    letterSpacing: "-1px",
                    marginBottom: "15px",
                  }}
                >
                  <p>누구보다 빠르고 친절한 상담</p>
                </div>
                <div
                  className="maint"
                  data-aos="fade-down"
                  data-aos-delay="650"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "700",
                    fontSize: "2.2rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  <p style={{ position: "relative", top: "-20px" }}>
                    초간단접수로 바로 배차
                  </p>
                </div>

                <p
                  data-aos="fade-down"
                  data-aos-delay="950"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "600",
                    fontSize: "3.338rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  1833-8852
                </p>
                <div
                  data-aos="fade-down"
                  data-aos-delay="1250"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "700",
                    fontSize: "1.725rem",
                    marginBottom: "20px",
                    width: "14.313rem",
                    height: "4.813rem",
                    background: COLOR_WHITE,
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.props.fnFreightModalOpen();
                  }}
                >
                  실시간 견적요청
                </div>
                <div
                  data-aos="fade-down"
                  data-aos-delay="650"
                  data-aos-duration="800"
                  data-aos-once="true"
                  style={{
                    width: "100%",
                    //display: "flex",
                    //justifyContent: "flex-end",
                    //alignItems: "center",
                    height: "200px",
                    //float: "left",
                    paddingRight: "5%",
                    textAlign: "right",
                  }}
                >
                  <MainTopCar width={"50%"} height={"200px"} />
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "auto",
                background: COLOR_BACKGROUND_GRAY,
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "60px",
                  paddingBottom: "60px",
                }}
              >
                <p
                  style={{
                    fontSize: "35px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  서비스 안내
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "300",
                    color: COLOR_FONT_GRAY,
                  }}
                >
                  최상의 운송경험을 위한 특별한 서비스
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  paddingBottom: "70px",
                }}
              >
                <ul
                  style={{
                    overflow: "hidden",
                    width: "80%",
                    display: "table",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingLeft: "0px",
                  }}
                >
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "100%",
                      marginRight: "1.2%",
                      display: "inline-block",
                    }}
                  >
                    <div>
                      <MainMiddle1 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>단품운송</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          개인화물 서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          저렴한 비용으로 소량의 화물을
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          목적지까지 안전하게 운송합니다.
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          다마스/라보/1톤
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "100%",
                      marginRight: "1.2%",
                      display: "inline-block",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <MainMiddle2 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-7px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>전국화물1T~25T</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          전국화물 서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          일반카고/윙탑/냉장탑/냉동탑
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          리프트카/무진동/츄레라 등 다양한 차량
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          카고/탑차/윙카/리프트/무진동
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "100%",
                      marginRight: "1.2%",
                      display: "inline-block",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <MainMiddle3 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>혼적화물1T~25T</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          혼전화물(합짐)서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          같은방향으로 이동하는 차량을 배차하여
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          목적지까지 보다 저렴하게 운송
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          카고/탑차/윙카/리프트/무진동
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "100%",
                      display: "inline-block",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <MainMiddle4 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>간단 이사 서비스</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          이사 서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          이사물량과 종류에따라
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          필요한 차량으로 운송합니다.
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          일반/포장/사무실/원룸이사
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                background: COLOR_PRIMARY,
                height: "270px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "150px",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "2.538rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                    paddingTop: "40px",
                  }}
                >
                  실시간견적 화물접수
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "1.125rem",
                    color: COLOR_WHITE,
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  출발지와 목적지, 필요한 차량,
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "1.125rem",
                    color: COLOR_WHITE,
                  }}
                >
                  운송할 물건들을 간략히 적어주세요.
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    width: "15.5rem",
                    height: "4.875rem",
                    borderRadius: "10px",
                    border: "2px solid #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "20px",
                  }}
                  onClick={() => this.props.fnFreightModalOpen()}
                >
                  <p
                    style={{
                      color: COLOR_WHITE,
                      fontSize: "1.8rem",
                      fontWeight: "700",
                    }}
                  >
                    실시간 견적요청
                  </p>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "80px",
                }}
              >
                <div
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{ textAlign: "center" }}
                >
                  <MainImage1 />
                  <p
                    style={{
                      fontSize: "2.213rem",
                      fontWeight: "500",
                      color: COLOR_FONT_BLACK,
                      marginBottom: "10px",
                    }}
                  >
                    최적의 서비스와 합리적인 요금
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    출발지와 도착지의 거리에따라
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    가장 합리적인 요금으로 운송해 드립니다.
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "80px",
                }}
              >
                <div
                  data-aos="fade-left"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{ textAlign: "center" }}
                >
                  <MainImage2 width={"70%"} />
                  <p
                    style={{
                      fontSize: "2.213rem",
                      fontWeight: "500",
                      color: COLOR_FONT_BLACK,
                      marginBottom: "10px",
                    }}
                  >
                    365일 24시간 온라인 접수
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    언제 어디서든 PC, Mobile, 전화로
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    화물차량 배차 접수를 해보세요.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "80px",
                }}
              >
                <div
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{ textAlign: "center" }}
                >
                  <MainImage3 />
                  <p
                    style={{
                      fontSize: "2.213rem",
                      fontWeight: "500",
                      color: COLOR_FONT_BLACK,
                      marginBottom: "10px",
                    }}
                  >
                    빠르고 정확한 배송
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    고객님께서 접수하신 화물을 정확한 곳까지
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    안전하게 운송해 드리겠습니다.
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                background: COLOR_BACKGROUND_GRAY,
                height: "100%",
              }}
            >
              <ul
                style={{
                  display: "table",
                  paddingLeft: "0px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "80%",
                  paddingTop: "90px",
                  marginBottom: "0px",
                }}
              >
                <li
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{
                    listStyle: "none",
                    height: "25rem",
                    width: "100%",
                    background: COLOR_WHITE,
                    borderRadius: "20px",
                    marginRight: "5%",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    float: "left",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ width: "100%", height: "50%" }}>
                    <p
                      style={{
                        fontSize: "1.9rem",
                        fontWeight: "500",
                        color: COLOR_FONT_BLACK,
                      }}
                    >
                      화물적재보험 가입
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치화물의 모든 운송서비스는 화물적재보험에 가입됩니다.
                      고객님의 소중한 물건을 언제나 안전하게 보호하고
                      운송합니다.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "50%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MainBottomImg1
                      style={{
                        width: "30%",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                </li>
                <li
                  data-aos="zoom-in"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{
                    listStyle: "none",
                    height: "25rem",
                    width: "100%",
                    background: COLOR_WHITE,
                    borderRadius: "20px",
                    marginRight: "5%",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    float: "left",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ width: "100%", height: "50%" }}>
                    <p
                      style={{
                        fontSize: "1.9rem",
                        fontWeight: "500",
                        color: COLOR_FONT_BLACK,
                      }}
                    >
                      100% 배차 보장
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치전국화물은 100% 배차를 보장합니다. 운송신청이
                      완료되면, 이후의 모든 과정은 에이치전국화물에 안심하고
                      맡겨주세요.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "50%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MainBottomImg2
                      style={{
                        width: "30%",
                      }}
                    />
                  </div>
                </li>
                <li
                  data-aos="fade-left"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{
                    listStyle: "none",
                    height: "25rem",
                    width: "100%",
                    background: COLOR_WHITE,
                    borderRadius: "20px",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    overflow: "hidden",
                    marginBottom: "90px",
                  }}
                >
                  <div style={{ width: "100%", height: "50%" }}>
                    <p
                      style={{
                        fontSize: "1.9rem",
                        fontWeight: "500",
                        color: COLOR_FONT_BLACK,
                      }}
                    >
                      안정적인 기업거래
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치 전국화물은 화주의 편의성을 고려하여 월단위 결재의
                      기업거래를 실시하고 있습니다. 매번 운송비 결제가
                      부담스럽다면 문의주세요.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "50%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MainBottomImg3
                      style={{
                        width: "30%",
                      }}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={481} maxWidth={1249}>
          <div
            style={{ width: "100%", height: "auto", overflowX: "hidden" }}
            className="nonescroll"
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                background: COLOR_PRIMARY,
                backgroundImage: "url(./image/greenBack.svg)",
                backgroundSize: "100%",
                backgroundPositionY: "100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* <div>
            <img src={"./image/background.png"} style={{ zIndex: -1 }} />
            </div> */}

              <div
                style={{
                  width: "80%",
                  height: "100%",
                  paddingTop: "54px",
                  margin: "0 auto ",
                  marginBottom: "10px",
                }}
              >
                <p
                  data-aos="fade-down"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "400",
                    fontSize: "2.4rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  차이를 만드는 AI상담기술
                </p>
                <div
                  data-aos="fade-down"
                  data-aos-delay="350"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "300",
                    fontSize: "3.2rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                    marginBottom: "12px",
                    letterSpacing: "-1px",
                    marginBottom: "15px",
                  }}
                >
                  <p>누구보다 빠르고 친절한 상담</p>
                </div>
                <div
                  className="maint"
                  data-aos="fade-down"
                  data-aos-delay="650"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "700",
                    fontSize: "3.2rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  <p style={{ position: "relative", top: "-20px" }}>
                    초간단접수로 바로 배차
                  </p>
                </div>

                <p
                  data-aos="fade-down"
                  data-aos-delay="950"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "600",
                    fontSize: "4.338rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  1833-8852
                </p>
                <div
                  data-aos="fade-down"
                  data-aos-delay="1250"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  onClick={() => {
                    this.props.fnFreightModalOpen();
                  }}
                  style={{
                    width: "100%",
                    height: "80px",
                    marginBottom: "20px",
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "700",
                      fontSize: "1.725rem",

                      width: "14.313rem",
                      height: "4.813rem",
                      background: COLOR_WHITE,
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      cursor: "pointer",
                      float: "left",
                    }}
                  >
                    <p>실시간 견적요청</p>
                  </div>
                </div>
                <div
                  data-aos="fade-down"
                  data-aos-delay="650"
                  data-aos-duration="800"
                  data-aos-once="true"
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "right",
                    position: "relative",
                    top: "-50px",
                  }}
                >
                  <div style={{ height: "250px" }}>
                    <MainTopCar height={"100%"} />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "auto",
                background: COLOR_BACKGROUND_GRAY,
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "60px",
                  paddingBottom: "60px",
                }}
              >
                <p
                  style={{
                    fontSize: "35px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  서비스 안내
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "300",
                    color: COLOR_FONT_GRAY,
                  }}
                >
                  최상의 운송경험을 위한 특별한 서비스
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  paddingBottom: "70px",
                }}
              >
                <ul
                  style={{
                    overflow: "hidden",
                    width: "90%",
                    display: "table",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingLeft: "0px",
                  }}
                >
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "49%",
                      marginRight: "2%",
                      display: "inline-block",
                    }}
                  >
                    <div>
                      <MainMiddle1 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>단품운송</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          개인화물 서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          저렴한 비용으로 소량의 화물을
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          목적지까지 안전하게 운송합니다.
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          다마스/라보/1톤
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "49%",
                      display: "inline-block",
                    }}
                  >
                    <div>
                      <MainMiddle2 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-7px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>전국화물1T~25T</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          전국화물 서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          일반카고/윙탑/냉장탑/냉동탑
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          리프트카/무진동/츄레라 등 다양한 차량
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          카고/탑차/윙카/리프트/무진동
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "49%",
                      marginRight: "2%",
                      display: "inline-block",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <MainMiddle3 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>혼적화물1T~25T</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          혼전화물(합짐)서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          같은방향으로 이동하는 차량을 배차하여
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          목적지까지 보다 저렴하게 운송
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          카고/탑차/윙카/리프트/무진동
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "49%",
                      display: "inline-block",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <MainMiddle4 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>간단 이사 서비스</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          이사 서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          이사물량과 종류에따라
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          필요한 차량으로 운송합니다.
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          일반/포장/사무실/원룸이사
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                background: COLOR_PRIMARY,
                height: "270px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "150px",
                  textAlign: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "2.538rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                    paddingTop: "40px",
                  }}
                >
                  실시간견적 화물접수
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "1.125rem",
                    color: COLOR_WHITE,
                    marginTop: "20px",
                  }}
                >
                  출발지와 목적지, 필요한 차량, 운송할 물건들을 간략히
                  적어주세요.
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    width: "15.5rem",
                    height: "4.875rem",
                    borderRadius: "10px",
                    border: "2px solid #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "20px",
                  }}
                  onClick={() => this.props.fnFreightModalOpen()}
                >
                  <p
                    style={{
                      color: COLOR_WHITE,
                      fontSize: "1.8rem",
                      fontWeight: "700",
                    }}
                  >
                    실시간 견적요청
                  </p>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "80px",
                }}
              >
                <div
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{ textAlign: "center" }}
                >
                  <MainImage1 />
                  <p
                    style={{
                      fontSize: "2.213rem",
                      fontWeight: "500",
                      color: COLOR_FONT_BLACK,
                      marginBottom: "10px",
                    }}
                  >
                    최적의 서비스와 합리적인 요금
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    출발지와 도착지의 거리에따라
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    가장 합리적인 요금으로 운송해 드립니다.
                  </p>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "80px",
                }}
              >
                <div
                  data-aos="fade-left"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{ textAlign: "center" }}
                >
                  <MainImage2 width={"70%"} />
                  <p
                    style={{
                      fontSize: "2.213rem",
                      fontWeight: "500",
                      color: COLOR_FONT_BLACK,
                      marginBottom: "10px",
                    }}
                  >
                    365일 24시간 온라인 접수
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    언제 어디서든 PC, Mobile, 전화로
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    화물차량 배차 접수를 해보세요.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "80px",
                }}
              >
                <div
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{ textAlign: "center" }}
                >
                  <MainImage3 />
                  <p
                    style={{
                      fontSize: "2.213rem",
                      fontWeight: "500",
                      color: COLOR_FONT_BLACK,
                      marginBottom: "10px",
                    }}
                  >
                    빠르고 정확한 배송
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    고객님께서 접수하신 화물을 정확한 곳까지
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    안전하게 운송해 드리겠습니다.
                  </p>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                background: COLOR_BACKGROUND_GRAY,
                height: "100%",
              }}
            >
              <ul
                style={{
                  display: "table",
                  paddingLeft: "0px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "80%",
                  paddingTop: "90px",
                  marginBottom: "0px",
                }}
              >
                <li
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{
                    listStyle: "none",
                    height: "25rem",
                    width: "100%",
                    background: COLOR_WHITE,
                    borderRadius: "20px",
                    marginRight: "5%",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    float: "left",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        fontSize: "1.9rem",
                        fontWeight: "500",
                        color: COLOR_FONT_BLACK,
                      }}
                    >
                      화물적재보험 가입
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치화물의 모든 운송서비스는
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      화물적재보험에 가입됩니다.
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      고객님의 소중한 물건을 언제나 안전하게
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      보호하고 운송합니다.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "40%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MainBottomImg1
                      style={{
                        width: "20%",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                </li>
                <li
                  data-aos="zoom-in"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{
                    listStyle: "none",
                    height: "25rem",
                    width: "100%",
                    background: COLOR_WHITE,
                    borderRadius: "20px",
                    marginRight: "5%",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    float: "left",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ width: "100%", height: "60%" }}>
                    <p
                      style={{
                        fontSize: "1.9rem",
                        fontWeight: "500",
                        color: COLOR_FONT_BLACK,
                      }}
                    >
                      100% 배차 보장
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치전국화물은 100% 배차를 보장합니다.
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      운송신청이 완료되면, 이후의 모든 과정은
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치전국화물에 안심하고 맡겨주세요.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "40%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MainBottomImg2
                      style={{
                        width: "20%",
                      }}
                    />
                  </div>
                </li>
                <li
                  data-aos="fade-left"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{
                    listStyle: "none",
                    height: "25rem",
                    width: "100%",
                    background: COLOR_WHITE,
                    borderRadius: "20px",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    overflow: "hidden",
                    marginBottom: "90px",
                  }}
                >
                  <div style={{ width: "100%", height: "60%" }}>
                    <p
                      style={{
                        fontSize: "1.9rem",
                        fontWeight: "500",
                        color: COLOR_FONT_BLACK,
                      }}
                    >
                      안정적인 기업거래
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치 전국화물은 화주의 편의성을 고려하여
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      월단위 결재의 기업거래를 실시하고 있습니다.
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      매번 운송비 결제가 부담스럽다면 문의주세요.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "40%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MainBottomImg3
                      style={{
                        width: "20%",
                      }}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery minWidth={1250}>
          <div style={{ width: "100%", height: "auto" }}>
            <div
              style={{
                width: "100%",
                height: "632px",
                background: COLOR_PRIMARY,
                backgroundImage: "url(./image/greenBack.svg)",
                backgroundSize: "100%",
                backgroundPositionY: "100%",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* <div>
            <img src={"./image/background.png"} style={{ zIndex: -1 }} />
            </div> */}
              <div
                style={{
                  width: "25%",
                  float: "left",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              />
              <div
                style={{
                  width: "30%",
                  float: "left",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  flexDirection: "column",
                  marginTop: "-30px",
                }}
              >
                <p
                  data-aos="fade-down"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "400",
                    fontSize: "1.85rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  차이를 만드는 AI상담기술
                </p>
                <div
                  data-aos="fade-down"
                  data-aos-delay="350"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "300",
                    fontSize: "3.338rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                    marginBottom: "12px",
                    letterSpacing: "-1px",
                  }}
                >
                  <p>누구보다 빠르고 친절한 상담</p>
                </div>
                <div
                  className="maint"
                  data-aos="fade-down"
                  data-aos-delay="650"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "700",
                    fontSize: "3.338rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  <p style={{ position: "relative", top: "-20px" }}>
                    초간단접수로 바로 배차
                  </p>
                </div>

                <p
                  data-aos="fade-down"
                  data-aos-delay="950"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "600",
                    fontSize: "3.338rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  1833-8852
                </p>
                <div
                  data-aos="fade-down"
                  data-aos-delay="1250"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  style={{
                    fontWeight: "700",
                    fontSize: "1.725rem",
                    marginBottom: "0px",
                    width: "14.313rem",
                    height: "4.813rem",
                    background: COLOR_WHITE,
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.props.fnFreightModalOpen();
                  }}
                >
                  실시간 견적요청
                </div>
              </div>

              <div
                data-aos="fade-left"
                data-aos-delay="650"
                data-aos-duration="800"
                data-aos-once="true"
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height: "100%",
                  float: "left",
                  paddingRight: "5%",
                }}
              >
                <MainTopCar />
              </div>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "auto",
                background: COLOR_BACKGROUND_GRAY,
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "60px",
                  paddingBottom: "60px",
                }}
              >
                <p
                  style={{
                    fontSize: "35px",
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  서비스 안내
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "300",
                    color: COLOR_FONT_GRAY,
                  }}
                >
                  최상의 운송경험을 위한 특별한 서비스
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  paddingBottom: "70px",
                }}
              >
                <ul
                  style={{
                    overflow: "hidden",
                    width: "80%",
                    display: "table",
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingLeft: "0px",
                  }}
                >
                  <li
                    data-aos="fade-right"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "23.8%",
                      marginRight: "1.2%",
                      display: "inline-block",
                    }}
                  >
                    <div>
                      <MainMiddle1 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>단품운송</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          개인화물 서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          저렴한 비용으로 소량의 화물을
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          목적지까지 안전하게 운송합니다.
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          다마스/라보/1톤
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "23.8%",
                      marginRight: "1.2%",
                      display: "inline-block",
                    }}
                  >
                    <div>
                      <MainMiddle2 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-7px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>전국화물1T~25T</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          전국화물 서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          일반카고/윙탑/냉장탑/냉동탑
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          리프트카/무진동/츄레라 등 다양한 차량
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          카고/탑차/윙카/리프트/무진동
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    data-aos="zoom-in"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "23.8%",
                      marginRight: "1.2%",
                      display: "inline-block",
                    }}
                  >
                    <div>
                      <MainMiddle3 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>혼적화물1T~25T</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          혼전화물(합짐)서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          같은방향으로 이동하는 차량을 배차하여
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          목적지까지 보다 저렴하게 운송
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          카고/탑차/윙카/리프트/무진동
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    data-aos="fade-left"
                    data-aos-delay="350"
                    data-aos-duration="600"
                    data-aos-once="false"
                    style={{
                      listStyle: "none",
                      float: "left",
                      width: "23.8%",
                      display: "inline-block",
                    }}
                  >
                    <div>
                      <MainMiddle4 width={"100%"} height={"100%"} />
                      <div
                        style={{
                          background: "#fff",
                          marginTop: "-6px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            background: "#03C75A",
                            borderRadius: "5px",
                            width: "50%",
                            height: "34px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                            fontSize: "1rem",
                            fontWeight: "700",
                            marginBottom: "15px",
                            marginTop: "15px",
                          }}
                        >
                          <p>간단 이사 서비스</p>
                        </div>
                        <p style={{ fontWeight: "500", fontSize: "1.5rem" }}>
                          이사 서비스
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            marginBottom: "0px",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          이사물량과 종류에따라
                        </p>
                        <p
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                            color: COLOR_FONT_GRAY,
                          }}
                        >
                          필요한 차량으로 운송합니다.
                        </p>
                        <p
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "500",
                            color: COLOR_FONT_BLACK,
                            marginBottom: "20px",
                          }}
                        >
                          일반/포장/사무실/원룸이사
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                background: COLOR_PRIMARY,
                height: "250px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "40%",
                  marginLeft: "15%",
                  float: "left",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "250px",
                }}
              >
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "3.338rem",
                    marginBottom: "0px",
                    color: COLOR_WHITE,
                  }}
                >
                  실시간견적 화물접수
                </p>
                <p
                  style={{
                    fontWeight: "500",
                    fontSize: "1.125rem",
                    color: COLOR_WHITE,
                  }}
                >
                  출발지와 목적지, 필요한 차량, 운송할 물건들을 간략히
                  적어주세요.
                </p>
              </div>
              <div style={{ width: "20%", float: "left", marginLeft: "15%" }}>
                <div
                  style={{
                    width: "16.5rem",
                    height: "5.375rem",
                    borderRadius: "10px",
                    border: "2px solid #fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnFreightModalOpen()}
                >
                  <p
                    style={{
                      color: COLOR_WHITE,
                      fontSize: "2.075rem",
                      fontWeight: "700",
                    }}
                  >
                    실시간 견적요청
                  </p>
                </div>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "80px",
                }}
              >
                <div
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{ float: "left", width: "40%" }}
                >
                  <p
                    style={{
                      fontSize: "2.813rem",
                      fontWeight: "500",
                      color: COLOR_FONT_BLACK,
                      marginBottom: "10px",
                    }}
                  >
                    최적의 서비스와 합리적인 요금
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    출발지와 도착지의 거리에따라
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    가장 합리적인 요금으로 운송해 드립니다.
                  </p>
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                >
                  <MainImage1 />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "80px",
                }}
              >
                <div
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{ float: "left", width: "50%" }}
                >
                  <MainImage2 />
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                >
                  <p
                    style={{
                      fontSize: "2.813rem",
                      fontWeight: "500",
                      color: COLOR_FONT_BLACK,
                      marginBottom: "10px",
                    }}
                  >
                    365일 24시간 온라인 접수
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    언제 어디서든 PC, Mobile, 전화로
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    화물차량 배차 접수를 해보세요.
                  </p>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "80px",
                }}
              >
                <div
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{ float: "left", width: "40%" }}
                >
                  <p
                    style={{
                      fontSize: "2.813rem",
                      fontWeight: "500",
                      color: COLOR_FONT_BLACK,
                      marginBottom: "10px",
                    }}
                  >
                    빠르고 정확한 배송
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                      marginBottom: "0px",
                    }}
                  >
                    고객님께서 접수하신 화물을 정확한 곳까지
                  </p>
                  <p
                    style={{
                      fontSize: "1.563rem",
                      fontWeight: "300",
                      color: COLOR_FONT_GRAY,
                    }}
                  >
                    안전하게 운송해 드리겠습니다.
                  </p>
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                >
                  <MainImage3 />
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                background: COLOR_BACKGROUND_GRAY,
                height: "35.188rem",
              }}
            >
              <ul
                style={{
                  display: "table",
                  paddingLeft: "0px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "80%",
                  paddingTop: "70px",
                }}
              >
                <li
                  data-aos="fade-right"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{
                    listStyle: "none",
                    height: "25rem",
                    width: "30%",
                    background: COLOR_WHITE,
                    borderRadius: "20px",
                    marginRight: "5%",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    float: "left",
                  }}
                >
                  <div style={{ width: "100%", height: "60%" }}>
                    <p
                      style={{
                        fontSize: "1.9rem",
                        fontWeight: "500",
                        color: COLOR_FONT_BLACK,
                      }}
                    >
                      화물적재보험 가입
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치화물의 모든 운송서비스는
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      화물적재보험에 가입됩니다.
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      고객님의 소중한 물건을 언제나 안전하게
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                      }}
                    >
                      보호하고 운송합니다.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "40%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MainBottomImg1
                      style={{
                        width: "20%",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                </li>
                <li
                  data-aos="zoom-in"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{
                    listStyle: "none",
                    height: "25rem",
                    width: "30%",
                    background: COLOR_WHITE,
                    borderRadius: "20px",
                    marginRight: "5%",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    float: "left",
                  }}
                >
                  <div style={{ width: "100%", height: "60%" }}>
                    <p
                      style={{
                        fontSize: "1.9rem",
                        fontWeight: "500",
                        color: COLOR_FONT_BLACK,
                      }}
                    >
                      100% 배차 보장
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치전국화물은 100% 배차를 보장합니다.
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      운송신청이 완료되면, 이후의 모든 과정은
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치전국화물에 안심하고 맡겨주세요.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "40%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MainBottomImg2
                      style={{
                        width: "20%",
                      }}
                    />
                  </div>
                </li>
                <li
                  data-aos="fade-left"
                  data-aos-delay="350"
                  data-aos-duration="600"
                  data-aos-once="false"
                  style={{
                    listStyle: "none",
                    height: "25rem",
                    width: "30%",
                    background: COLOR_WHITE,
                    borderRadius: "20px",
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    overflow: "hidden",
                  }}
                >
                  <div style={{ width: "100%", height: "60%" }}>
                    <p
                      style={{
                        fontSize: "1.9rem",
                        fontWeight: "500",
                        color: COLOR_FONT_BLACK,
                      }}
                    >
                      안정적인 기업거래
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      에이치 전국화물은 화주의 편의성을 고려하여
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      월단위 결재의 기업거래를 실시하고 있습니다.
                    </p>
                    <p
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "300",
                        color: COLOR_FONT_GRAY,
                        marginBottom: "0px",
                      }}
                    >
                      매번 운송비 결제가 부담스럽다면 문의주세요.
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      height: "40%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <MainBottomImg3
                      style={{
                        width: "20%",
                      }}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(Main);
