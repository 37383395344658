import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class CorfirmModalForDelete extends Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={commonStore.confirmModalForDelete.isOpen}
        onClose={() => commonStore.confirmModalForDeleteClose()}
        style={{ height: "200px" }}
      >
        <Modal.Header>알림</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: "16pt" }}>
            {commonStore.confirmModalForDelete.content}
          </p>
        </Modal.Content>
        <div style={{ float: "right", marginTop: "20px", marginRight: "20px" }}>
          <Button negative onClick={() => this.props.fnConfirmNo()}>
            아니오
          </Button>
          <Button positive onClick={() => this.props.fnConfirmYes()}>
            예
          </Button>
        </div>
      </Modal>
    );
  }
}

export default CorfirmModalForDelete;
