import { observable, action, reaction, makeObservable, toJS } from "mobx";

class DashBoardStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable freightList1 = [];
  @observable freightList2 = [];
  @observable freightList3 = [];
  @observable freightList4 = [];
  @observable quoteList = [];
  @observable driverList = [];
  @observable compList = [];
  @observable monthlyIncomeList = [];

  @action
  setFreightList1 = (value) => {
    this.freightList1 = value;
  };

  @action
  initFreightList1 = () => {
    this.freightList1 = [];
  };

  @action
  setFreightList2 = (value) => {
    this.freightList2 = value;
  };

  @action
  initFreightList2 = () => {
    this.freightList2 = [];
  };

  @action
  setFreightList3 = (value) => {
    this.freightList3 = value;
  };

  @action
  initFreightList3 = () => {
    this.freightList3 = [];
  };

  @action
  setFreightList4 = (value) => {
    this.freightList4 = value;
  };

  @action
  initFreightList4 = () => {
    this.freightList4 = [];
  };

  @action
  setQuoteList = (value) => {
    this.quoteList = value;
  };

  @action
  initQuoteList = () => {
    this.quoteList = [];
  };

  @action
  setDriverList = (value) => {
    this.driverList = value;
  };

  @action
  initDriverList = () => {
    this.freightList = [];
  };
  @action
  setCompList = (value) => {
    this.compList = value;
  };

  @action
  initCompList = () => {
    this.compList = [];
  };
  @action
  setMonthlyIncomeList = (value) => {
    this.monthlyIncomeList = value;
  };

  @action
  initMonthlyIncomeList = () => {
    this.monthlyIncomeList = [];
  };
}

export default DashBoardStore;
