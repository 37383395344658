import React, { Component, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import SideBar from "./SideBar";
import { isLogin, isDriverLogin } from "../../common/Util";
class PrivateRoute extends Component {
  state = {
    selected: null,
    expanded: false,
  };

  onSelect = (selected) => {
    this.setState({ selected: selected });
  };
  onToggle = () => {
    this.setState({ expanded: true });
  };

  onToggleClose = () => {
    this.setState({ expanded: false });
  };

  render() {
    return (
      <Route>
        {isLogin() == true ? (
          this.props.sideBar == true ? (
            <div
              style={{
                height: "100vh",
                marginLeft: this.state.expanded ? "250px" : "80px",
              }}
            >
              <SideBar
                onSelect={this.onSelect}
                onToggle={this.onToggle}
                onToggleClose={this.onToggleClose}
                expanded={this.state.expanded}
                selected={this.state.selected}
              />

              <this.props.component onSelect={this.onSelect} />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100vh",
              }}
            >
              <this.props.component onSelect={this.onSelect} />
            </div>
          )
        ) : isDriverLogin() == true ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
            }}
          >
            <this.props.component />
          </div>
        ) : (
          <Redirect to="/" />
        )}
      </Route>
    );
  }
}

export default PrivateRoute;
