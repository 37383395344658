import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import axios from "axios";
import {
  Table,
  Form,
  Button,
  Input,
  Select,
  Pagination,
  Checkbox,
} from "semantic-ui-react";
import moment from "moment";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_FONT_BLACK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
import {
  API_DELETE_DRIVER,
  API_INSERT_DRIVER,
  API_INSERT_DRIVER_BULK,
  API_RECOVER_DRIVER,
  API_SELECT_DRIVER_LIST,
  API_UPDATE_DRIVER,
  API_UPDATE_USEYN_BLUK,
} from "../../common/ApiAddress/DriverAPI";
import { freightInfoList, vcTypList, vcWeightList } from "../../common/VcData";
import FileUploadModal from "../../Component/Modal/FileUploadModal";
import readXlsxFile from "read-excel-file";
import CommonAlert from "../../Component/Common/CommonAlert";
@inject((stores) => ({
  commonStore: stores.CommonStore,
  driverStroe: stores.DriverStore,
}))
@observer
class DriverManagement extends React.Component {
  state = {
    upsertView: false,
    selectedMode: false,
  };

  componentDidMount() {
    this.fnSelectDriverList();
  }

  componentWillUnmount() {
    const { driverStroe } = this.props;
    driverStroe.initForm();
  }

  fnSelectDriverList = (currentPage) => {
    const { driverStroe } = this.props;
    let params = {};
    if (currentPage == undefined || currentPage == 1) {
      params.startCnt = 0;
      params.endCnt = 20;
    } else {
      params.startCnt = (currentPage - 1) * 20;
      params.endCnt = 20;
    }
    axios({
      url: API_SELECT_DRIVER_LIST,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          driverStroe.setDriverList(response.data.data);
          driverStroe.setTotalPage(response.data.totalPageNum);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectedDriver = (value) => {
    const { driverStroe } = this.props;
    driverStroe.setSelectedDriver(value);
    driverStroe.setDriverId(value.driverId);
    driverStroe.setDriverNm(value.driverNm);
    driverStroe.setHpNo(value.hpNo);
    driverStroe.setUseYn(value.useYn);
    driverStroe.setVcTyp(value.vcTyp);
    driverStroe.setVcWeight(value.vcWeight);
    driverStroe.setBsNo(value.bsNo);
    this.setState({
      upsertView: true,
    });
  };

  fnUpsertViewOpen = () => {
    this.setState({
      upsertView: true,
    });
  };

  fnInsertDriver = () => {
    const { driverStroe, commonStore } = this.props;
    let params = {};
    params.driverId = driverStroe.driverId;
    params.driverNm = driverStroe.driverNm;
    params.pw = driverStroe.hpNo;
    params.hpNo = driverStroe.hpNo;
    params.useYn = "Y";
    params.vcTyp = driverStroe.vcTyp;
    params.vcWeight = driverStroe.vcWeight;
    params.bsNo = driverStroe.bsNo;

    axios({
      url: API_INSERT_DRIVER,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
        if (response.data.resultCode == 201) {
          commonStore.commonAlertOpen(response.data.resultMsg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUpdateDriver = () => {
    const { driverStroe } = this.props;
    let params = {};
    params.driverNo = driverStroe.selectedDriver.driverNo;
    params.driverId = driverStroe.driverId;
    params.driverNm = driverStroe.driverNm;
    params.pw = driverStroe.hpNo;
    params.hpNo = driverStroe.hpNo;
    params.useYn = "Y";
    params.vcTyp = driverStroe.vcTyp;
    params.vcWeight = driverStroe.vcWeight;
    params.bsNo = driverStroe.bsNo;

    axios({
      url: API_UPDATE_DRIVER,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnDeleteDriver = () => {
    const { driverStroe } = this.props;
    let params = {};
    params.driverNo = driverStroe.selectedDriver.driverNo;

    axios({
      url: API_DELETE_DRIVER,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnRecoverDriver = () => {
    const { driverStroe } = this.props;
    let params = {};
    params.driverNo = driverStroe.selectedDriver.driverNo;

    axios({
      url: API_RECOVER_DRIVER,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnInsertDriverByExcel = () => {
    const { commonStore, driverStroe } = this.props;
    //엑셀파일 => 리스트화 해야함
    const input = document.getElementById("excelUpload");
    let driverList = [];
    readXlsxFile(input.files[0]).then((rows) => {
      for (let i = 1; i < rows.length; i++) {
        let data = {};
        data.driverId = rows[i][0];
        data.driverNm = rows[i][1];
        data.pw = rows[i][2];
        data.hpNo = rows[i][2];
        data.useYn = rows[i][3];
        data.vcTyp = rows[i][4];
        data.vcWeight = rows[i][5];
        data.bsNo = rows[i][6];
        driverList.push(data);
      }
      axios({
        url: API_INSERT_DRIVER_BULK,
        method: "post",
        data: driverList,
      })
        .then(function (response) {
          if (response.data.resultCode == 100) {
            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  fnToggleSelectedMode = () => {
    const { driverStroe } = this.props;

    if (this.state.selectedMode == false) {
      this.setState({
        selectedMode: true,
      });
      driverStroe.initSelectedDriver();
    } else {
      this.setState({
        selectedMode: false,
      });
      driverStroe.clearSelectedDriver();
    }
  };

  fnOnchageSelectedDriverList = (checked, data) => {
    const { driverStroe } = this.props;
    if (checked == true) {
      driverStroe.addSelectedDriver(data);
    } else {
      driverStroe.removeSelectedDriver(data);
    }
  };

  fnUpdateUseYnBulk = (useYn) => {
    const { driverStroe } = this.props;
    let params = {};
    params.selectedDriverList = driverStroe.selectedDriverList;
    params.useYn = useYn;

    axios({
      url: API_UPDATE_USEYN_BLUK,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { commonStore, driverStroe } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 100px)",
          //overflowY: "scroll",
        }}
      >
        <div
          style={{
            width: "40%",
            padding: "30px",
            float: "left",
            boxShadow: "4px 4px 10px rgba(5,5,5,0.1)",
            borderRadius: "10px",
            marginBottom: "2.5%",
            marginRight: "2%",
            marginLeft: "1%",
            marginTop: "1.5%",
            height: "calc(100vh - 50px)",
          }}
        >
          <div style={{ textAlign: "right" }}>
            <Button
              color="brown"
              onClick={() => {
                this.fnToggleSelectedMode();
              }}
            >
              {this.state.selectedMode == false ? "선택모드" : "선택모드 해제"}
            </Button>
            {this.state.selectedMode == false ? (
              <Fragment>
                <a
                  href="../../form/driver_form.xlsx"
                  download="차주업로드양식.xlsx"
                >
                  <Button color="teal">양식 다운로드</Button>
                </a>
                <Button
                  positive
                  onClick={() => commonStore.fileUploadModalOepn()}
                >
                  신규 기사 추가(엑셀업로드)
                </Button>
                <Button
                  color="blue"
                  onClick={() => {
                    driverStroe.initForm();
                    this.fnUpsertViewOpen();
                  }}
                >
                  신규 기사 추가(단건)
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button positive onClick={() => this.fnUpdateUseYnBulk("Y")}>
                  선택기사 사용재개
                </Button>
                <Button negative onClick={() => this.fnUpdateUseYnBulk("N")}>
                  선택기사 사용중지
                </Button>
              </Fragment>
            )}
          </div>
          <div
            style={{
              overflowY: "scroll",
              height: "calc(100vh - 230px)",
              marginTop: "20px",
            }}
          >
            <Table celled style={{ textAlign: "center" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {this.state.selectedMode == false ? "번호" : "선택"}
                  </Table.HeaderCell>
                  <Table.HeaderCell>기사명</Table.HeaderCell>
                  <Table.HeaderCell>차량번호</Table.HeaderCell>
                  <Table.HeaderCell>연락처</Table.HeaderCell>
                  <Table.HeaderCell>사용유무</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {driverStroe.driverList == null ||
              driverStroe.driverList.length == 0 ? (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="3" textAlign="center">
                      등록된 기사가 없습니다.
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : (
                <Table.Body>
                  {driverStroe.driverList.map((obj, i) => (
                    <React.Fragment key={obj.driverNo}>
                      <Table.Row
                        style={{
                          backgroundColor:
                            driverStroe.selectedDriver != null &&
                            obj.driverNo == driverStroe.selectedDriver.driverNo
                              ? COLOR_PRIMARY
                              : COLOR_WHITE,
                          cursor: "pointer",
                          color:
                            driverStroe.selectedDriver != null &&
                            obj.driverNo == driverStroe.selectedDriver.driverNo
                              ? COLOR_WHITE
                              : COLOR_FONT_BLACK,
                        }}
                        onClick={() =>
                          this.state.selectedMode == false
                            ? this.fnSelectedDriver(obj)
                            : null
                        }
                      >
                        <Table.Cell>
                          {this.state.selectedMode == true ? (
                            <Checkbox
                              onClick={(e, data) =>
                                this.fnOnchageSelectedDriverList(
                                  data.checked,
                                  obj
                                )
                              }
                            />
                          ) : (
                            obj.driverNo
                          )}
                        </Table.Cell>
                        <Table.Cell>{obj.driverNm}</Table.Cell>
                        <Table.Cell>{obj.driverId}</Table.Cell>
                        <Table.Cell>{obj.hpNo}</Table.Cell>
                        <Table.Cell>{obj.useYn}</Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  ))}
                </Table.Body>
              )}
            </Table>
          </div>
          <Pagination
            style={{ marginTop: "20px" }}
            onPageChange={(e, data) => {
              this.setState({
                selectedMode: false,
              });
              driverStroe.clearSelectedDriver();
              this.fnSelectDriverList(data.activePage);
            }}
            defaultActivePage={driverStroe.currentPage}
            totalPages={driverStroe.totalPage}
          />
        </div>

        {this.state.upsertView == false ? null : (
          <div
            style={{
              width: "55%",
              padding: "30px",
              overflow: "hidden",
              boxShadow: "4px 4px 10px rgba(5,5,5,0.1)",
              borderRadius: "10px",
              marginBottom: "2.5%",
              marginRight: "2%",
              position: "relative",
              top: "2.8%",
              height: "94vh",
            }}
          >
            {driverStroe.selectedDriver == null ? (
              <Fragment>
                <Table style={{ border: "0px", marginTop: "15px" }}>
                  <Table.Header style={{ border: "0px" }}>
                    <Table.Row>
                      <Table.HeaderCell
                        colSpan="2"
                        style={{ background: "#fff" }}
                      >
                        신규 기사 추가
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>차량번호(아이디)</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="차량번호 기입"
                          value={driverStroe.driverId || ""}
                          onChange={(e) => {
                            driverStroe.setDriverId(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>기사 이름</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="기사 이름 기입"
                          value={driverStroe.driverNm || ""}
                          onChange={(e) => {
                            driverStroe.setDriverNm(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>기사 연락처(비밀번호)</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="기사 연락처 기입"
                          value={driverStroe.hpNo || ""}
                          onChange={(e) => {
                            driverStroe.setHpNo(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>차량톤수</Table.Cell>
                      <Table.Cell>
                        <Form.Field style={{ width: "50%", float: "left" }}>
                          <Select
                            style={{ width: "96%" }}
                            placeholder={"톤수선택"}
                            value={driverStroe.vcWeight}
                            options={vcWeightList}
                            onChange={(e, { value }) =>
                              driverStroe.setVcWeight(value)
                            }
                          />
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>차량종류</Table.Cell>
                      <Table.Cell>
                        <Form.Field style={{ width: "50%", float: "left" }}>
                          <Select
                            style={{ width: "96%" }}
                            placeholder={"차량종류"}
                            value={driverStroe.vcTyp}
                            options={vcTypList}
                            onChange={(e, { value }) =>
                              driverStroe.setVcTyp(value)
                            }
                          />
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>사업자 번호</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="기사 사업자번호 기입"
                          value={driverStroe.bsNo || ""}
                          onChange={(e) => {
                            driverStroe.setBsNo(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <div style={{ textAlign: "right" }}>
                  <Button positive onClick={() => this.fnInsertDriver()}>
                    기사등록
                  </Button>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <Table style={{ border: "0px", marginTop: "15px" }}>
                  <Table.Header style={{ border: "0px" }}>
                    <Table.Row>
                      <Table.HeaderCell
                        colSpan="2"
                        style={{ background: "#fff" }}
                      >
                        기사 정보 수정
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>차량번호(아이디)</Table.Cell>
                      <Table.Cell>
                        <p style={{ width: "100%" }}>
                          {driverStroe.driverId || ""}
                        </p>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>기사 이름</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="기사 이름 기입"
                          value={driverStroe.driverNm || ""}
                          onChange={(e) => {
                            driverStroe.setDriverNm(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>기사 연락처(비밀번호)</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="기사 연락처 기입"
                          value={driverStroe.hpNo || ""}
                          onChange={(e) => {
                            driverStroe.setHpNo(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>차량톤수</Table.Cell>
                      <Table.Cell>
                        <Form.Field style={{ width: "50%", float: "left" }}>
                          <Select
                            style={{ width: "96%" }}
                            placeholder={"톤수선택"}
                            value={driverStroe.vcWeight}
                            options={vcWeightList}
                            onChange={(e, { value }) =>
                              driverStroe.setVcWeight(value)
                            }
                          />
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>차량종류</Table.Cell>
                      <Table.Cell>
                        <Form.Field style={{ width: "50%", float: "left" }}>
                          <Select
                            style={{ width: "96%" }}
                            placeholder={"차량종류"}
                            value={driverStroe.vcTyp}
                            options={vcTypList}
                            onChange={(e, { value }) =>
                              driverStroe.setVcTyp(value)
                            }
                          />
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>사업자 번호</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="기사 사업자번호 기입"
                          value={driverStroe.bsNo || ""}
                          onChange={(e) => {
                            driverStroe.setBsNo(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <div style={{ textAlign: "right" }}>
                  {driverStroe.selectedDriver.useYn == "N" ? (
                    <Button color="teal" onClick={() => this.fnRecoverDriver()}>
                      사용재개
                    </Button>
                  ) : (
                    <Button negative onClick={() => this.fnDeleteDriver()}>
                      사용중지
                    </Button>
                  )}

                  <Button positive onClick={() => this.fnUpdateDriver()}>
                    기사정보 수정
                  </Button>
                </div>
              </Fragment>
            )}
          </div>
        )}
        <FileUploadModal fnInsertBulkByExcel={this.fnInsertDriverByExcel} />
        <CommonAlert />
      </div>
    );
  }
}

export default withRouter(DriverManagement);
