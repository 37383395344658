import { apiAddress } from "../ApiPlatform";

export const API_INSERT_CORPORATION =
  apiAddress + "corporation/insertCorporation";

export const API_SELECT_CORPORATION_LIST =
  apiAddress + "corporation/selectCorporation";

export const API_UPDATE_CORPORATION_MEMO =
  apiAddress + "corporation/updateMemo";

export const API_UPDATE_CORPORATION_CONTACT_STATUS =
  apiAddress + "corporation/updateContactStatus";
