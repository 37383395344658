import { observable, action, reaction, makeObservable, toJS } from "mobx";

class QuoteStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  //기업 신용 거래 문의
  @observable companyNm = null;
  @observable companyEmail = null;
  @observable requestNm = null;
  @observable requestHpNo = null;

  @observable localeList = [];
  @observable vcWeightList = [];
  @observable vcTypList = [];
  @observable monthCountValue = null;

  @observable feightInfo = null;
  @observable requestContent = null;

  @observable corporationList = [];
  @observable selectedCorporation = null;
  @observable memo = null;

  @observable currentPage = 1;
  @observable totalPage = 1;

  @action
  setCompanyNm = (value) => {
    this.companyNm = value;
  };

  @action
  initCompanyNm = () => {
    this.companyNm = null;
  };

  @action
  setCompanyEmail = (value) => {
    this.companyEmail = value;
  };

  @action
  initCompanyEmail = () => {
    this.companyEmail = null;
  };

  @action
  setRequestNm = (value) => {
    this.requestNm = value;
  };

  @action
  initRequestNm = () => {
    this.requestNm = null;
  };

  @action
  setRequestHpNo = (value) => {
    this.requestHpNo = value;
  };

  @action
  initRequestHpNo = () => {
    this.requestHpNo = null;
  };

  @action
  setLocaleList = (value) => {
    let idx = this.localeList.findIndex((item) => item == value);
    if (idx == -1) {
      this.localeList.push(value);
    } else {
      this.localeList.splice(idx, 1);
    }
  };

  @action
  initLocaleList = () => {
    this.localeList = [];
  };

  @action
  setVcWeightList = (value) => {
    let idx = this.vcWeightList.findIndex((item) => item == value);
    if (idx == -1) {
      this.vcWeightList.push(value);
    } else {
      this.vcWeightList.splice(idx, 1);
    }
  };

  @action
  initVcWeightList = () => {
    this.vcWeightList = [];
  };

  @action
  setVcTypList = (value) => {
    let idx = this.vcTypList.findIndex((item) => item == value);
    if (idx == -1) {
      this.vcTypList.push(value);
    } else {
      this.vcTypList.splice(idx, 1);
    }
  };

  @action
  initVcTypList = () => {
    this.vcTypList = [];
  };

  @action
  setMonthCountValue = (value) => {
    this.monthCountValue = value;
  };

  @action
  initMonthCountValue = () => {
    this.monthCountValue = null;
  };

  @action
  setFeightInfo = (value) => {
    this.feightInfo = value;
  };

  @action
  initFeightInfo = () => {
    this.feightInfo = null;
  };

  @action
  setRequestContent = (value) => {
    this.requestContent = value;
  };

  @action
  initRequestContent = () => {
    this.requestContent = null;
  };

  @action
  initB2BContactForm = () => {
    this.initCompanyNm();
    this.initCompanyEmail();
    this.initRequestNm();
    this.initRequestHpNo();
    this.initLocaleList();
    this.initVcWeightList();
    this.initVcTypList();
    this.initMonthCountValue();
    this.initFeightInfo();
    this.initRequestContent();
    this.initSelectedCorporation();
    this.initMemo();
    this.initCurrentPage();
    this.initTotalPage();
  };

  @action
  setCorporationList = (value) => {
    this.corporationList = value;
  };

  @action
  initCorporationList = () => {
    this.corporationList = [];
  };

  @action
  SetSelectedCorporation = (value) => {
    this.selectedCorporation = value;
  };

  @action
  initSelectedCorporation = () => {
    this.selectedCorporation = null;
  };

  @action
  setMemo = (value) => {
    this.memo = value;
  };

  @action
  initMemo = () => {
    this.memo = null;
  };

  @action
  setCurrentPage = (value) => {
    this.currentPage = value;
  };

  @action
  initCurrentPage = () => {
    this.currentPage = 1;
  };

  @action
  setTotalPage = (value) => {
    this.totalPage = value;
  };

  @action
  initTotalPage = () => {
    this.totalPage = 1;
  };
}

export default QuoteStore;
