import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogin, isDriverLogin } from "../../common/Util";
import SideBar from "./SideBar";

const PublicRoute = ({
  component: Component,
  restricted,
  sideBar,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted ? (
          <Redirect to="/management/main" />
        ) : isDriverLogin() && restricted ? (
          <Redirect to="/driver/main" />
        ) : (
          <React.Fragment>
            {sideBar === true ? <SideBar /> : null}
            <Component {...props} />
          </React.Fragment>
        )
      }
    />
  );
};
export default PublicRoute;
