import { apiAddress } from "../ApiPlatform";

export const API_INSERT_COMP = apiAddress + "comp/insertComp";
export const API_SELECT_COMP_LIST = apiAddress + "comp/selectCompList";
export const API_UPDATE_COMP = apiAddress + "comp/updateComp";
export const API_DELETE_COMP = apiAddress + "comp/deleteComp";
export const API_RECOVER_COMP = apiAddress + "comp/recoverComp";
export const API_INSERT_COMP_BULK = apiAddress + "comp/insertCompBulk";

export const API_SELECT_COMP_SEARCHLIST =
  apiAddress + "comp/selectCompSearchList";
