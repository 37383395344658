import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_BLACK,
  COLOR_FONT_GRAY,
  COLOR_PRIMARY,
  COLOR_WHITE,
  COLOR_GRAY_GR06,
} from "../../common/Color";
import { inject, observer } from "mobx-react";
import { ReactComponent as Cargo1T } from "../../assets/1tcargo.svg";
import { ReactComponent as Topvc } from "../../assets/topvc.svg";
import { ReactComponent as Cargo14T } from "../../assets/14tcargo.svg";
import { ReactComponent as Wingvc } from "../../assets/wingvc.svg";
import { ReactComponent as Cargo2T } from "../../assets/2tcargo.svg";
import { ReactComponent as Topvc2 } from "../../assets/topvc2.svg";
import { ReactComponent as Cargo5T } from "../../assets/5tcargo.svg";
import { ReactComponent as Topvc5 } from "../../assets/topvc5.svg";
import { ReactComponent as Cargo11T } from "../../assets/11tcargo.svg";
import { ReactComponent as Cargo25T } from "../../assets/25tcargo.svg";
import { ReactComponent as Topvc11 } from "../../assets/topvc11.svg";
import MediaQuery from "react-responsive";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class AllVcInfo extends React.Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Fragment>
        <MediaQuery maxWidth={768}>
          <div style={{ width: "100%", height: "auto" }}>
            <div
              style={{
                width: "100%",
                height: "171px",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                개인화물 / 전국화물 / 차량제원
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.4rem",
                  color: COLOR_WHITE,
                }}
              >
                에이치 전국화물과 편리한 운송을 경험해보세요.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  width: "100%",
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "100%",
                    color:
                      commonStore.path == "BasicFreight"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "BasicFreight" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("BasicFreight")}
                >
                  개인화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "100%",
                    color:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "AllLocaFreight" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllLocaFreight")}
                >
                  전국화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "33%",
                    height: "100%",

                    color:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_PRIMARY
                        : COLOR_GRAY_GR06,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "AllVcInfo" ? "700" : "400",
                    fontSize: "1.425rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllVcInfo")}
                >
                  차량제원
                </li>
              </ul>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                차량제원
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                  marginBottom: "0px",
                }}
              >
                1톤~25톤 : 카고/탑차/윙바디/리프트/무진동/츄레라
              </p>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ul
                style={{
                  width: "90%",
                  listStyle: "none",
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* 1톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">1톤 카고</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Cargo1T width={"100%"} />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          2,850㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,600㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          4,820㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,995㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          6CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 1톤탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">1톤 탑·윙바디</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Topvc />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          2,850㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>

                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          1,600㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          4,820㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,570㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          1,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          6CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 1.4톤 카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">1.4톤 카고</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Cargo14T />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          3,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          5,425㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,080㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          9CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 1.4톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">1.4톤 탑·윙바디</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Wingvc />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          3,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          5,425㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          1,800㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,700㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 2.5톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">2.5톤 카고</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Cargo2T width={"100%"} height={"246px"} />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          4,300㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          100㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          1,800㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          6,225㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,325㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          3,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          12CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 2.5톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">2.5톤 탑·윙바디</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Topvc2 width={"100%"} height={"246px"} />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          4,300㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          100㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          1,800㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,200㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          3,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 5톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">5톤 카고</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Cargo5T width={"100%"} height={"246px"} />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          6,200㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          120㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,2300㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          5,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          24CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 5톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">5톤 탑·윙바디</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Topvc5 />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          6,200㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          120㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,230㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,400mm
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          5,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          24CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 11톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">11톤 카고</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Cargo11T />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          9,100mm
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          145㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,340㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          13,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          30CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 11톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">11톤 탑·윙바디</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Topvc11 />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          9,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          145㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,340㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,500㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          13,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          30CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 25톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          background: "#DBDBDB",
                          fontWeight: "600",
                          fontSize: "1.125rem",
                          height: "40px",
                        }}
                      >
                        <td colSpan="4">25톤 카고</td>
                      </tr>
                      <tr
                        style={{
                          border: "1px solid #DBDBDB",
                          textAlign: "center",
                        }}
                      >
                        <td colSpan="4">
                          <Cargo25T />
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          10,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                            width: "25%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                            width: "25%",
                          }}
                        >
                          140㎜
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          2,340㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          25,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.1rem",
                          }}
                        >
                          32CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769} maxWidth={1249}>
          <div style={{ width: "100%", height: "auto" }}>
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                개인화물 / 전국화물 / 차량제원
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                }}
              >
                에이치 전국화물과 편리한 운송을 경험해보세요.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "BasicFreight"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "BasicFreight"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "BasicFreight"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "BasicFreight" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("BasicFreight")}
                >
                  개인화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "AllLocaFreight"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "AllLocaFreight" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    borderLeftWidth:
                      commonStore.path == "AllVcInfo" ? "0px" : "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllLocaFreight")}
                >
                  전국화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "AllVcInfo"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "AllVcInfo" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllVcInfo")}
                >
                  차량제원
                </li>
              </ul>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                차량제원
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                  marginBottom: "0px",
                }}
              >
                1톤~25톤 : 카고/탑차/윙바디/리프트/무진동/츄레라
              </p>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ul
                style={{
                  width: "90%",
                  listStyle: "none",
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* 1톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          1톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo1T />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          2,850㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,600㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          4,820㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,995㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          6CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 1톤탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          1톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Topvc />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          2,850㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,600㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          4,820㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,570㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          6CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 1.4톤 카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          1.4톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo14T />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          3,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          5,425㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,080㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          9CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 1.4톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          1.4톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Wingvc />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          3,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          5,425㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,800㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,700㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 2.5톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          2.5톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo2T width={"100%"} height={"246px"} />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          4,300㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          100㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,800㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          6,225㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,325㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          3,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          12CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 2.5톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          2.5톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Topvc2 width={"100%"} height={"246px"} />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          4,300㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          100㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,800㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,200㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          3,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 5톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          5톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo5T width={"100%"} height={"246px"} />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          6,200㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          120㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,2300㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          5,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          24CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 5톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          5톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Topvc5 />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          6,200㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          120㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,230㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,400mm
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          5,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          24CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 11톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          11톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo11T />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          9,100mm
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          145㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,340㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          13,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          30CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 11톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          11톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Topvc11 />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          9,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          145㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,340㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,500㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          13,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          30CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 25톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          25톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo25T />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          10,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          140㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,340㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          25,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          32CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1250}>
          <div style={{ width: "100%", height: "auto" }}>
            <div
              style={{
                width: "100%",
                height: "14.625rem",
                background: COLOR_PRIMARY,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "1.25rem",
                  color: COLOR_WHITE,
                  marginBottom: "0px",
                }}
              >
                개인화물 / 전국화물 / 차량제원
              </p>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "2.5rem",
                  color: COLOR_WHITE,
                }}
              >
                에이치 전국화물과 편리한 운송을 경험해보세요.
              </p>
            </div>
            <div style={{ width: "100%", marginTop: "70px" }}>
              <ul
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "BasicFreight"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "BasicFreight"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "BasicFreight"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "BasicFreight" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("BasicFreight")}
                >
                  개인화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "AllLocaFreight"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "AllLocaFreight"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight:
                      commonStore.path == "AllLocaFreight" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    borderLeftWidth:
                      commonStore.path == "AllVcInfo" ? "0px" : "1px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllLocaFreight")}
                >
                  전국화물
                </li>
                <li
                  style={{
                    listStyle: "none",
                    width: "9.575rem",
                    height: "3.325rem",
                    border:
                      commonStore.path == "AllVcInfo"
                        ? "1px solid #03C75A"
                        : "1px solid #DBDBDB",
                    background:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_PRIMARY
                        : COLOR_WHITE,
                    color:
                      commonStore.path == "AllVcInfo"
                        ? COLOR_WHITE
                        : COLOR_BLACK,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: commonStore.path == "AllVcInfo" ? "600" : "400",
                    fontSize: "1.125rem",
                    float: "left",
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.fnRouterChage("AllVcInfo")}
                >
                  차량제원
                </li>
              </ul>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <p
                style={{
                  fontSize: "2.188rem",
                  fontWeight: "500",
                  color: COLOR_BLACK,
                  marginBottom: "10px",
                }}
              >
                차량제원
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "300",
                  color: COLOR_FONT_GRAY,
                  marginBottom: "0px",
                }}
              >
                1톤~25톤 : 카고/탑차/윙바디/리프트/무진동/츄레라
              </p>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ul
                style={{
                  width: "60%",
                  listStyle: "none",
                  paddingLeft: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {/* 1톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          1톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo1T />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          2,850㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,600㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          4,820㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,995㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          6CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 1톤탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          1톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Topvc />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          2,850㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,600㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          4,820㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,570㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          6CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 1.4톤 카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          1.4톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo14T />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          3,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          5,425㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,080㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          9CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 1.4톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          1.4톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Wingvc />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          3,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          80㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,700㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          5,425㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,800㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,700㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 2.5톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          2.5톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo2T width={"100%"} height={"246px"} />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          4,300㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          100㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,800㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          6,225㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,325㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          3,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          12CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 2.5톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          2.5톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Topvc2 width={"100%"} height={"246px"} />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          4,300㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          100㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          1,800㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,200㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          3,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 5톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          5톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo5T width={"100%"} height={"246px"} />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          6,200㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          120㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,2300㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          5,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          24CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 5톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          5톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Topvc5 />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          6,200㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          120㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,230㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,400mm
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          5,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          24CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 11톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          11톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo11T />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          9,100mm
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          145㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,340㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          13,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          30CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 11톤 탑,윙바디 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          11톤 탑·윙바디
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Topvc11 />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          9,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          145㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,340㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,500㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          13,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          30CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                {/* 25톤카고 */}
                <li style={{ width: "100%", marginBottom: "40px" }}>
                  <table
                    className="collapse_table"
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.125rem",
                            width: "15%",
                            height: "246px",
                          }}
                        >
                          25톤 카고
                        </td>
                        <td
                          rowspan="4"
                          style={{
                            border: "1px solid #DBDBDB",
                            width: "30% !important",
                            textAlign: "center",
                          }}
                        >
                          <Cargo25T />
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          10,100㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                            width: "11%",
                          }}
                        >
                          적재함 지상고
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                            width: "11%",
                          }}
                        >
                          140㎜
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 폭
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          2,340㎜
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 길이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재함 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 총 높이
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          -
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          적재량
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          25,000㎏
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            background: "#DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.063rem",
                          }}
                        >
                          차량 적재 용적
                        </td>
                        <td
                          style={{
                            border: "1px solid #DBDBDB",
                            fontWeight: "500",
                            fontSize: "1.188rem",
                          }}
                        >
                          32CBM
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(AllVcInfo);
