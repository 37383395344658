import { observable, action, reaction, makeObservable, toJS } from "mobx";

class CompStore {
  constructor(root) {
    this.root = root;
    makeObservable(this);
  }

  @observable compNm = null;
  @observable compBsNo = null;
  @observable compHpNo = null;
  @observable representative = null;
  @observable bsTyp = null;
  @observable bsNm = null;
  @observable compAdd = null;
  @observable email = null;

  @observable compList = [];
  @observable selectedComp = null;

  @observable currentPage = 1;
  @observable totalPage = 1;

  @action
  setCompNm = (value) => {
    this.compNm = value;
  };

  @action
  initCompNm = () => {
    this.compNm = null;
  };
  @action
  setCompBsNo = (value) => {
    this.compBsNo = value;
  };

  @action
  initCompBsNo = () => {
    this.compBsNo = null;
  };

  @action
  setCompHpNo = (value) => {
    this.compHpNo = value;
  };

  @action
  initCompHpNo = () => {
    this.compHpNo = null;
  };

  @action
  setRepresentative = (value) => {
    this.representative = value;
  };

  @action
  initRepresentative = () => {
    this.representative = null;
  };

  @action
  setBsTyp = (value) => {
    this.bsTyp = value;
  };

  @action
  initBsTyp = () => {
    this.bsTyp = null;
  };

  @action
  setBsNm = (value) => {
    this.bsNm = value;
  };

  @action
  initBsNm = () => {
    this.bsNm = null;
  };

  @action
  setCompAdd = (value) => {
    this.compAdd = value;
  };

  @action
  initCompAdd = () => {
    this.compAdd = null;
  };

  @action
  setCompList = (value) => {
    this.compList = value;
  };

  @action
  initCompList = () => {
    this.compList = [];
  };

  @action
  setSelectedComp = (value) => {
    this.selectedComp = value;
  };

  @action
  initSelectedComp = () => {
    this.selectedComp = null;
  };

  @action
  setEmail = (value) => {
    this.email = value;
  };

  @action
  initEmail = () => {
    this.email = null;
  };

  @action
  setCurrentPage = (value) => {
    this.currentPage = value;
  };

  @action
  initCurrentPage = () => {
    this.currentPage = 1;
  };

  @action
  setTotalPage = (value) => {
    this.totalPage = value;
  };

  @action
  initTotalPage = () => {
    this.totalPage = 1;
  };

  @action
  initForm = () => {
    this.initCompNm();
    this.initCompHpNo();
    this.initCompBsNo();
    this.initBsTyp();
    this.initBsNm();
    this.initRepresentative();
    this.initCompAdd();
    this.initEmail();
    this.initCurrentPage();
    this.initTotalPage();
    this.initSelectedComp();
  };
}

export default CompStore;
