import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import DatePicker, { registerLocale } from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { API_SELECT_FREIGHT_COMPLETE_LIST_EXCEL } from "../../common/ApiAddress/FrightAPI";
import axios from "axios";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
@inject((stores) => ({
  commonStore: stores.CommonStore,
  freightStore: stores.FreightStore,
}))
@observer
class CompleteFreightExcelModal extends Component {
  state = {
    selectedMonth: null,
  };

  //날짜 선택
  fnSelectMonth = (value) => {
    const { commonStore, freightStore } = this.props;
    this.setState({
      selectedMonth: value,
    });
    let that = this;
    let params = {};
    params.selectedMonth = moment(value).format("YYYY-MM");

    axios({
      url: API_SELECT_FREIGHT_COMPLETE_LIST_EXCEL,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          freightStore.setFreightCompleteList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnexcelDownLoad = async () => {
    const { commonStore, freightStore } = this.props;
    let data = freightStore.freightCompleteList;
    console.log(data);
    const schema = [
      {
        column: "운송상태",
        type: String,
        value: (data) => data.freightCd,
      },
      {
        column: "출발지",
        type: String,
        value: (data) =>
          data.loadingSido +
          " " +
          data.loadingSigu +
          " " +
          data.loadingDong +
          " " +
          data.loadingDetailAdd,
      },
      {
        column: "출발지",
        type: String,
        value: (data) =>
          data.unloadingSido +
          " " +
          data.unloadingSigu +
          " " +
          data.unloadingDong +
          " " +
          data.unloadingDetailAdd,
      },
      {
        column: "차량종류(고객요청)",
        type: String,
        value: (data) => data.vcTyp,
      },
      {
        column: "차량톤수(고객요청)",
        type: String,
        value: (data) => data.vcWeight,
      },
      {
        column: "화물정보",
        type: String,
        value: (data) => data.freightInfo,
      },
      {
        column: "고객연락처",
        type: String,
        value: (data) => data.hpNo,
      },
      {
        column: "등록일",
        type: String,
        value: (data) => moment(data.registDt).format("YYYY-MM-DD"),
      },
      {
        column: "차량번호",
        type: String,
        value: (data) => data.driverId,
      },
      {
        column: "차주이름",
        type: String,
        value: (data) => data.driverNm,
      },
      {
        column: "차주연락처",
        type: String,
        value: (data) => data.driverHpNo,
      },
      {
        column: "차주사업자번호",
        type: String,
        value: (data) => data.driverBsNo,
      },
      {
        column: "차량종류(실제배차)",
        type: String,
        value: (data) => data.driverVcTyp,
      },
      {
        column: "차량톤수(실제배차)",
        type: String,
        value: (data) => data.driverVcWeight,
      },
      {
        column: "차주운임",
        type: Number,
        value: (data) => data.logisFee,
      },
      {
        column: "수수료",
        type: Number,
        value: (data) => data.commission,
      },
      {
        column: "전체운임",
        type: Number,
        value: (data) => data.totalFee,
      },
      {
        column: "정보망",
        type: String,
        value: (data) => data.networkCd,
      },
      {
        column: "거래처명",
        type: String,
        value: (data) => data.compNm,
      },
      {
        column: "거래처사업자번호",
        type: String,
        value: (data) => data.compBsNo,
      },
      {
        column: "거래처연락처",
        type: String,
        value: (data) => data.compHpNo,
      },
    ];

    await writeXlsxFile(data, {
      schema, // (optional) column widths, etc.
      fileName: "file.xlsx",
    }).then(function (response) {
      window.location.reload();
    });
  };

  render() {
    const { commonStore } = this.props;
    registerLocale("ko", ko);
    return (
      <Modal
        closeOnDimmerClick={false}
        size="tiny"
        open={commonStore.searchCompleteFreightModal}
        onClose={() => commonStore.confirmModalForDeleteClose()}
        style={{ height: "250px" }}
      >
        <Modal.Header>엑셀다운로드</Modal.Header>
        <Modal.Content>
          <p>날짜를 선택한 후 다운로드 버튼을 눌러 주세요.</p>
          <DatePicker
            locale="ko"
            showMonthYearPicker
            selected={this.state.selectedMonth}
            onChange={(date) => {
              this.fnSelectMonth(date);
            }}
            dateFormat="yyyy-MM"
          />
        </Modal.Content>
        <div style={{ float: "right", marginTop: "20px", marginRight: "20px" }}>
          <Button
            negative
            onClick={() => commonStore.searchCompleteFreightModalClose()}
          >
            닫기
          </Button>
          <Button positive onClick={() => this.fnexcelDownLoad()}>
            다운로드
          </Button>
        </div>
      </Modal>
    );
  }
}

export default CompleteFreightExcelModal;
