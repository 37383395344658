import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class CommonAlert extends Component {
  render() {
    const { commonStore } = this.props;
    return (
      <Modal
        size="tiny"
        open={commonStore.commonAlert.isOpen}
        onClose={() => commonStore.commonAlertClose()}
        style={{ height: "210px" }}
      >
        <Modal.Header>알림</Modal.Header>
        <Modal.Content>
          <p style={{ fontSize: "16pt" }}>{commonStore.commonAlert.content}</p>
        </Modal.Content>
        <div style={{ float: "right", marginTop: "20px", marginRight: "20px" }}>
          <Button positive onClick={() => commonStore.commonAlertClose()}>
            확인
          </Button>
        </div>
      </Modal>
    );
  }
}

export default CommonAlert;
