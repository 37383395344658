import { Component } from "react";
import PublicRoute from "./Component/Common/PublicRoute";
import PrivateRoute from "./Component/Common/PrivateRoute";
import Main from "./Containers/Main";
import BasicFreight from "./Containers/ServiceMenu/BasicFreight";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Prompt,
} from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import MainContainer from "./Containers/MainContainer";
import ManagementContainer from "./Containers/ManagementContainer";
import AdminLogin from "./Containers/AdminLogin";
import DriverLogin from "./Containers/DriverLogin";
import DriverContainer from "./Containers/Driver/DriverContainer";
import FreightManagement from "./Containers/Management/Freight/FreightManagement";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <PublicRoute
            exact
            restricted={false}
            path="/"
            component={MainContainer}
            sideBar={false}
          />
          <PublicRoute
            exact
            restricted={false}
            path="/:pageSection"
            component={MainContainer}
            sideBar={false}
          />
          <PublicRoute
            exact
            restricted={true}
            path="/admin/login"
            component={AdminLogin}
            sideBar={false}
          />
          <PublicRoute
            exact
            restricted={true}
            path="/driver/login"
            component={DriverLogin}
            sideBar={false}
          />
          <PrivateRoute
            exact
            restricted={false}
            path="/management/:managementSection"
            component={ManagementContainer}
            sideBar={true}
          />

          <PrivateRoute
            exact
            restricted={false}
            path="/management/freight/:freightSection"
            component={FreightManagement}
            sideBar={true}
          />

          <PrivateRoute
            exact
            restricted={false}
            path="/driverHome/:driverSection"
            component={DriverContainer}
            sideBar={false}
          />
        </Switch>
      </Router>
    );
  }
}
export default App;
