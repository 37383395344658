import React, { Component } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import axios from "axios";
import DriverMain from "./DriverMain";
import { COLOR_PRIMARY, COLOR_WHITE } from "../../common/Color";
import { API_UPDATE_FREIGHT_COMPLETE } from "../../common/ApiAddress/FrightAPI";
import {
  API_SELECT_CURRENT_FREIGHT,
  API_UPDATE_GEOLOCATION,
  API_UPDATE_ISEMPTY,
} from "../../common/ApiAddress/DriverAPI";
import DriverFreightList from "./DriverFreightList";
import CommonAlert from "../../Component/Common/CommonAlert";
import DriverFreightListDetail from "./DriverFreightListDetail";
@inject((stores) => ({
  driverStore: stores.DriverStore,
  commonStore: stores.CommonStore,
}))
@observer
class DriverContainer extends React.Component {
  fnRouterChage = (path) => {
    this.props.history.push(path);
  };

  fnGetGeoLocation = () => {
    const { driverStore } = this.props;
    let that = this;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        driverStore.setLatitude(position.coords.latitude);
        driverStore.setLongitude(position.coords.longitude);
        that.fnUpdateGeoLocation();
      },
      (error) => console.log(error)
    );
  };

  fnUpdateGeoLocation = () => {
    const { driverStore, commonStore } = this.props;
    let params = {};
    params.driverId = sessionStorage.getItem("driverId");
    params.latitude = driverStore.latitude;
    params.longitude = driverStore.longitude;
    axios({
      url: API_UPDATE_GEOLOCATION,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          //commonStore.commonAlertOpen("위치정보를 갱신했습니다.");
        } else {
          // commonStore.commonAlertOpen(
          //   "위치정보 갱신에 실패했습니다. 관리자에 문의하세요."
          // );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectCurrentFreight = () => {
    const { driverStore, commonStore } = this.props;
    let params = {};
    params.driverId = sessionStorage.getItem("driverId");

    axios({
      url: API_SELECT_CURRENT_FREIGHT,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          driverStore.setCurrentFreight(response.data.data);
        } else {
          driverStore.initCurrentFreight();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //운송완료 처리
  fnUpdateFreightComplete = () => {
    let params = {};
    const { driverStore } = this.props;
    let that = this;
    params.freightNo = driverStore.currentFreight.freightNo;
    params.freightCd = "운송완료";
    params.isEmpty = "Y";
    params.driverId = sessionStorage.getItem("driverId");

    let frieghtInfo = { ...driverStore.currentFreight };
    params.freightInfo = frieghtInfo;

    axios({
      url: API_UPDATE_FREIGHT_COMPLETE,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          that.fnGetMsgAuth(params);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //공차변경
  fnUpdateDriverIsEmpty = () => {
    let params = {};
    const { commonStore } = this.props;
    let that = this;
    params.driverId = sessionStorage.getItem("driverId");
    params.isEmpty = "Y";

    axios({
      url: API_UPDATE_ISEMPTY,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.commonAlertOpen("공차로 설정변경을 완료 했습니다.");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //로그아웃
  fnDriverLogOut = () => {
    sessionStorage.removeItem("driverId");
    this.props.history.push("/driver/login");
  };

  fnGetMsgAuth = (obj) => {
    let that = this;
    let params = obj.freightInfo;
    axios({
      url: "https://htruck.co.kr/exapi/msgAuth",
      method: "post",
      data: params,
    })
      .then((response) => {
        that.fnSendMsgForComplete(params, response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSendMsgForComplete = (msgData, authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content =
      "[운송완료 안내] \n" +
      "다음 배송건에 대해서 운송이 완료되었습니다.\n" +
      "●상차지\n" +
      msgData.loadingSido +
      msgData.loadingSigu +
      msgData.loadingDong +
      "\n";

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != "") {
      content = content + msgData.loadingDetailAdd + "\n";
    }

    content =
      content +
      "●하차지\n" +
      msgData.unloadingSido +
      msgData.unloadingSigu +
      msgData.unloadingDong +
      "\n";

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ""
    ) {
      content = content + msgData.unloadingDetailAdd + "\n";
    }

    content = content + "화물정보 : " + msgData.freightInfo + "\n";

    //phone.push("01031254802");
    phone.push("01088521982");

    //phone.push(msgData.hpNo);
    id.push("ttSystem");
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    axios({
      url: "https://htruck.co.kr/exapi/msgSendLmsforTrucker",
      method: "get",
      params: params,
    })
      .then(function (response) {
        if (response.data.code == "1000") {
          that.fnSelectCurrentFreight();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    let currentPage;
    switch (this.props.location.pathname) {
      case "/driverHome/main":
        currentPage = (
          <DriverMain
            fnUpdateFreightComplete={this.fnUpdateFreightComplete}
            fnUpdateDriverIsEmpty={this.fnUpdateDriverIsEmpty}
            fnGetGeoLocation={this.fnGetGeoLocation}
            fnSelectCurrentFreight={this.fnSelectCurrentFreight}
            fnUpdateGeoLocation={this.fnUpdateGeoLocation}
          />
        );
        break;
      case "/driverHome/freightList":
        currentPage = <DriverFreightList />;
        break;
      case "/driverHome/freighDetail":
        currentPage = <DriverFreightListDetail />;
        break;
    }
    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <div style={{ width: "100%", height: "500px" }}>{currentPage}</div>
        <div
          style={{
            position: "fixed",
            bottom: "0",
            width: "100%",
            height: "65px",
            background: COLOR_PRIMARY,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: COLOR_WHITE,
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
            fontWeight: "700",
            fontSize: "1.55rem",
            textAlign: "center",
          }}
        >
          <p
            style={{ width: "33%", marginBottom: "0px" }}
            onClick={() => {
              this.props.history.push("/driverHome/main");
            }}
          >
            배차현황
          </p>
          <p
            style={{ width: "33%", marginBottom: "0px" }}
            onClick={() => {
              this.props.history.push("/driverHome/freightList");
            }}
          >
            운송내역
          </p>
          <p
            style={{ width: "33%", marginBottom: "0px" }}
            onClick={() => this.fnDriverLogOut()}
          >
            로그아웃
          </p>
        </div>
        <CommonAlert />
      </div>
    );
  }
}

export default withRouter(DriverContainer);
