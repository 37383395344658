import React, { Component, memo } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import {
  API_SELECT_CORPORATION_LIST,
  API_UPDATE_CORPORATION_CONTACT_STATUS,
  API_UPDATE_CORPORATION_MEMO,
} from "../../common/ApiAddress/CorporationAPI";
import axios from "axios";
import { Table, TextArea, Form, Button, Pagination } from "semantic-ui-react";
import moment from "moment";
import {
  COLOR_BACKGROUND_GRAY,
  COLOR_FONT_BLACK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";
@inject((stores) => ({
  quoteStore: stores.QuoteStore,
}))
@observer
class CorporationManagement extends React.Component {
  state = {
    memo: null,
  };

  componentDidMount() {
    this.fnSelectCorporationList();
  }

  componentWillUnmount() {
    const { quoteStore } = this.props;
    quoteStore.initB2BContactForm();
  }

  fnSelectCorporationList = (currentPage) => {
    const { quoteStore } = this.props;
    let params = {};
    if (currentPage == undefined || currentPage == 1) {
      params.startCnt = 0;
      params.endCnt = 20;
    } else {
      params.startCnt = (currentPage - 1) * 20;
      params.endCnt = 20;
    }
    axios({
      url: API_SELECT_CORPORATION_LIST,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          quoteStore.setCorporationList(response.data.data);
          quoteStore.setTotalPage(response.data.totalPageNum);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSeletedCorporation = (value) => {
    const { quoteStore } = this.props;
    quoteStore.SetSelectedCorporation(value);
    if (value.memo != undefined || value.memo == null) {
      quoteStore.setMemo(value.memo);
    }
    if (value.memo == undefined) {
      quoteStore.initMemo();
    }
  };

  fnUpdateMemo = () => {
    const { quoteStore } = this.props;
    let params = {};
    params.id = quoteStore.selectedCorporation.id;
    params.memo = quoteStore.memo;
    axios({
      url: API_UPDATE_CORPORATION_MEMO,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUpdateContactStatus = () => {
    const { quoteStore } = this.props;
    let params = {};
    params.id = quoteStore.selectedCorporation.id;
    axios({
      url: API_UPDATE_CORPORATION_CONTACT_STATUS,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { quoteStore } = this.props;
    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <div
          style={{
            width: "40%",
            padding: "30px",
            float: "left",
            boxShadow: "4px 4px 10px rgba(5,5,5,0.1)",
            borderRadius: "10px",
            marginBottom: "2.5%",
            marginRight: "2%",
            marginLeft: "1%",
          }}
        >
          <Table celled style={{ textAlign: "center" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>번호</Table.HeaderCell>
                <Table.HeaderCell>회사명</Table.HeaderCell>
                <Table.HeaderCell>등록일</Table.HeaderCell>
                <Table.HeaderCell>연락유무</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {quoteStore.corporationList == null ||
            quoteStore.corporationList.length == 0 ? (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan="3" textAlign="center">
                    등록된 문의가 없습니다.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ) : (
              <Table.Body>
                {quoteStore.corporationList.map((data, i) => (
                  <React.Fragment key={data.id}>
                    <Table.Row
                      style={{
                        backgroundColor:
                          quoteStore.selectedCorporation != null &&
                          data.id == quoteStore.selectedCorporation.id
                            ? COLOR_PRIMARY
                            : COLOR_WHITE,
                        cursor: "pointer",
                        color:
                          quoteStore.selectedCorporation != null &&
                          data.id == quoteStore.selectedCorporation.id
                            ? COLOR_WHITE
                            : COLOR_FONT_BLACK,
                      }}
                      onClick={() => this.fnSeletedCorporation(data)}
                    >
                      <Table.Cell>{data.id}</Table.Cell>
                      <Table.Cell>{data.companyNm}</Table.Cell>
                      <Table.Cell>
                        {moment(data.registDt).format("YYYY-MM-DD")}
                      </Table.Cell>
                      <Table.Cell>{data.contactYn}</Table.Cell>
                    </Table.Row>
                  </React.Fragment>
                ))}
              </Table.Body>
            )}
          </Table>
          <Pagination
            onPageChange={(e, data) => {
              this.fnSelectCorporationList(data.activePage);
            }}
            defaultActivePage={quoteStore.currentPage}
            totalPages={quoteStore.totalPage}
            siblingRange={1}
            ellipsisItem={null}
          />
        </div>
        <div
          style={{
            width: "55%",
            padding: "30px",
            overflow: "hidden",
            boxShadow: "4px 4px 10px rgba(5,5,5,0.1)",
            borderRadius: "10px",
            marginTop: "2.5%",
            marginBottom: "2.5%",
            marginRight: "2%",
          }}
        >
          {quoteStore.selectedCorporation == null ? (
            <h2>목록에서 선택 해주세요.</h2>
          ) : (
            <div>
              <h2>{quoteStore.selectedCorporation.companyNm}</h2>
              <p style={{ fontSize: "18px", fontWeight: "500px" }}>문의내역</p>
              <p
                style={{
                  whiteSpace: "break-spaces",
                  background: COLOR_BACKGROUND_GRAY,
                  padding: "20px",
                }}
              >
                {quoteStore.selectedCorporation.content}
              </p>
              <p style={{ fontSize: "18px", fontWeight: "500px" }}>
                업무노트(진행사항 및 업무노트 작성)
              </p>
              <Form>
                <TextArea
                  rows={8}
                  style={{
                    resize: "none",
                    width: "90%",
                    border: "1px solid",
                  }}
                  onChange={(e) => {
                    quoteStore.setMemo(e.target.value);
                  }}
                  value={quoteStore.memo || ""}
                />
              </Form>
              <div
                style={{ marginTop: "20px", textAlign: "right", width: "90%" }}
              >
                <Button
                  color="teal"
                  onClick={() => this.fnUpdateContactStatus()}
                >
                  연락확인
                </Button>
                <Button positive onClick={() => this.fnUpdateMemo()}>
                  메모저장
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(CorporationManagement);
