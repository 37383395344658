import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import CommonAlert from "../../Component/Common/CommonAlert";
import MediaQuery from "react-responsive";
import { COLOR_PRIMARY, COLOR_WHITE } from "../../common/Color";
@inject((stores) => ({
  driverStore: stores.DriverStore,
  commonStore: stores.CommonStore,
  freightStore: stores.FreightStore,
}))
@observer
class DriverFreightDetail extends React.Component {
  render() {
    const { driverStore, freightStore } = this.props;
    return (
      <Fragment>
        <MediaQuery maxWidth={480}>
          <div
            style={{ width: "100%", height: "100vh", background: "#F8F9FA" }}
          >
            <div
              style={{
                width: "100%",
                fontWeight: "700",
                fontSize: "1.5rem",
                textAlign: "center",
                paddingTop: "90px",
                height: "150px",
              }}
            >
              <p>운송내역 상세 정보</p>
            </div>
            <div
              style={{
                width: "90%",
                boxShadow: "0px 4px 4px 0px #00000040",
                background: COLOR_WHITE,
                height: "410px",
                borderRadius: "10px",
                margin: "0 auto",
                padding: "20px",
                display: "flex",
                justifyItems: "center",
              }}
            >
              {freightStore.selectedFreight == null ? (
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h2>현재 배차내역이 없습니다.</h2>
                </div>
              ) : (
                <table style={{ width: "100%" }}>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      상차지
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {freightStore.selectedFreight.loadingSido +
                        " " +
                        freightStore.selectedFreight.loadingSido +
                        " " +
                        freightStore.selectedFreight.loadingDong +
                        " " +
                        freightStore.selectedFreight.loadingDetailAdd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      하차지
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {freightStore.selectedFreight.unloadingSido +
                        " " +
                        freightStore.selectedFreight.unloadingSido +
                        " " +
                        freightStore.selectedFreight.unloadingDong +
                        " " +
                        freightStore.selectedFreight.unloadingDetailAdd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      화물정보
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {freightStore.selectedFreight.freightInfo}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      상차방법
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {freightStore.selectedFreight.loadingCd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      하차방법
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {freightStore.selectedFreight.unloadingCd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      운송형태
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {freightStore.selectedFreight.logisCd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      결제방법
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {freightStore.selectedFreight.payCd}
                    </td>
                  </tr>
                  <tr style={{ height: "45px" }}>
                    <td
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: COLOR_PRIMARY,
                        width: "30%",
                      }}
                    >
                      화물운임
                    </td>
                    <td
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {freightStore.selectedFreight.logisFee
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원"}
                    </td>
                  </tr>
                </table>
              )}
            </div>

            <CommonAlert />
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(DriverFreightDetail);
