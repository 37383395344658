import React, { Component } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { COLOR_PRIMARY, COLOR_WHITE } from "../common/Color";
import { Form, Input } from "semantic-ui-react";
import CommonAlert from "../Component/Common/CommonAlert";
import axios from "axios";
import { API_LOGIN_DRIVER } from "../common/ApiAddress/DriverAPI";
import MediaQuery from "react-responsive";
import { ReactComponent as Top_logo } from "../assets/top_logo.svg";
@inject((stores) => ({
  driverStore: stores.DriverStore,
  commonStore: stores.CommonStore,
}))
@observer
class DriverLogin extends React.Component {
  //로그인
  fnLogin = () => {
    const { driverStore, commonStore } = this.props;
    let params = {};
    params.driverId = driverStore.driverId;
    params.pw = driverStore.pw;
    let that = this;

    axios({
      url: API_LOGIN_DRIVER,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          sessionStorage.setItem("driverId", response.data.data.driverId);
          driverStore.initForm();
          that.props.history.push("/driverHome/main");
        } else {
          commonStore.commonAlertOpen("아이디와 비밀번호를 확인해 주세요.");
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const { driverStore } = this.props;
    return (
      <React.Fragment>
        <MediaQuery maxWidth={480}>
          <div
            style={{
              width: "100%",
              height: "calc(100vh)",
              display: "flex",
              alignItems: "center",
              //background: COLOR_PRIMARY,
            }}
          >
            <div
              style={{
                width: "70%",
                margin: "0 auto ",
                marginTop: "-80px",
                textAlign: "center",
              }}
            >
              <Top_logo
                width={"80%"}
                style={{ marginBottom: "15%" }}
                height={"100%"}
              />
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                차주 로그인
              </p>
              <Form
                style={{
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <Form.Field style={{ marginBottom: "23px" }}>
                  <input
                    style={{
                      height: "50px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #03C75A",
                    }}
                    placeholder={"아이디(차량번호)를 입력해주세요."}
                    onChange={(e) => driverStore.setDriverId(e.target.value)}
                    value={driverStore.driverId || ""}
                    type="text"
                  />
                </Form.Field>
                <Form.Field>
                  <input
                    style={{
                      height: "50px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #03C75A",
                    }}
                    placeholder={"비밀번호를 입력해 주세요."}
                    onChange={(e) => driverStore.setPw(e.target.value)}
                    value={driverStore.pw || ""}
                    type="password"
                    onKeyPress={(e) => {
                      if (e.charCode == 13) {
                        this.fnLogin();
                      }
                    }}
                  />
                </Form.Field>

                <div
                  onClick={() => {
                    this.fnLogin();
                  }}
                  style={{
                    width: "100%",
                    height: "50px",
                    marginTop: "45px",
                    marginBottom: "33px",
                    background: COLOR_PRIMARY,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "18px",
                    color: COLOR_WHITE,
                    cursor: "pointer",
                    borderRadius: "10px",
                    fontWeight: "600",
                  }}
                >
                  로그인
                </div>
              </Form>
            </div>
          </div>
          <CommonAlert />
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default withRouter(DriverLogin);
