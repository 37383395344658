import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import axios from "axios";
import { Table, Button, Input, Pagination } from "semantic-ui-react";
import {
  COLOR_FONT_BLACK,
  COLOR_PRIMARY,
  COLOR_WHITE,
} from "../../common/Color";

import FileUploadModal from "../../Component/Modal/FileUploadModal";
import readXlsxFile from "read-excel-file";
import {
  API_DELETE_COMP,
  API_INSERT_COMP,
  API_INSERT_COMP_BULK,
  API_RECOVER_COMP,
  API_SELECT_COMP_LIST,
  API_UPDATE_COMP,
} from "../../common/ApiAddress/CompAPI";
@inject((stores) => ({
  commonStore: stores.CommonStore,
  compStore: stores.CompStore,
}))
@observer
class CompManagement extends React.Component {
  state = {
    upsertView: false,
  };

  componentDidMount() {
    this.fnSelectCompList();
  }

  componentWillUnmount() {
    const { compStore } = this.props;
    compStore.initForm();
  }

  fnSelectCompList = (currentPage) => {
    const { compStore } = this.props;
    let params = {};
    if (currentPage == undefined || currentPage == 1) {
      params.startCnt = 0;
      params.endCnt = 20;
    } else {
      params.startCnt = (currentPage - 1) * 20;
      params.endCnt = 20;
    }
    axios({
      url: API_SELECT_COMP_LIST,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          compStore.setCompList(response.data.data);
          compStore.setTotalPage(response.data.totalPageNum);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectedComp = (value) => {
    const { compStore } = this.props;
    compStore.setSelectedComp(value);
    compStore.setCompNm(value.compNm);
    compStore.setCompBsNo(value.compBsNo);
    compStore.setCompHpNo(value.compHpNo);
    compStore.setRepresentative(value.representative);
    compStore.setBsTyp(value.bsTyp);
    compStore.setBsNm(value.bsNm);
    compStore.setCompAdd(value.compAdd);
    compStore.setEmail(value.email);

    this.setState({
      upsertView: true,
    });
  };

  fnUpsertViewOpen = () => {
    this.setState({
      upsertView: true,
    });
  };

  fnInsertComp = () => {
    const { compStore } = this.props;
    let params = {};
    params.compNm = compStore.compNm;
    params.compBsNo = compStore.compBsNo;
    params.compHpNo = compStore.compHpNo;
    params.representative = compStore.representative;
    params.bsTyp = compStore.bsTyp;
    params.bsNm = compStore.bsNm;
    params.compAdd = compStore.compAdd;
    params.email = compStore.email;

    axios({
      url: API_INSERT_COMP,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUpdateComp = () => {
    const { compStore } = this.props;
    let params = {};
    params.compNo = compStore.selectedComp.compNo;
    params.compNm = compStore.compNm;
    params.compBsNo = compStore.compBsNo;
    params.compHpNo = compStore.compHpNo;
    params.representative = compStore.representative;
    params.bsTyp = compStore.bsTyp;
    params.bsNm = compStore.bsNm;
    params.compAdd = compStore.compAdd;
    params.email = compStore.email;

    axios({
      url: API_UPDATE_COMP,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnDeleteComp = () => {
    const { compStore } = this.props;
    let params = {};
    params.compNo = compStore.selectedComp.compNo;

    axios({
      url: API_DELETE_COMP,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnRecoverComp = () => {
    const { compStore } = this.props;
    let params = {};
    params.compNo = compStore.selectedComp.compNo;

    axios({
      url: API_RECOVER_COMP,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnInsertCompByExcel = () => {
    //엑셀파일 => 리스트화 해야함
    const input = document.getElementById("excelUpload");
    let compList = [];
    readXlsxFile(input.files[0]).then((rows) => {
      for (let i = 1; i < rows.length; i++) {
        let data = {};
        data.compNm = rows[i][0];
        data.compBsNo = rows[i][1];
        data.compHpNo = rows[i][2];
        data.representative = rows[i][3];
        data.bsTyp = rows[i][4];
        data.bsNm = rows[i][5];
        data.compAdd = rows[i][6];
        data.email = rows[i][7];
        compList.push(data);
      }
      axios({
        url: API_INSERT_COMP_BULK,
        method: "post",
        data: compList,
      })
        .then(function (response) {
          if (response.data.resultCode == 100) {
            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  render() {
    const { commonStore, compStore } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          height: "calc(100vh - 100px)",
        }}
      >
        <div
          style={{
            width: "40%",
            padding: "30px",
            float: "left",
            boxShadow: "4px 4px 10px rgba(5,5,5,0.1)",
            borderRadius: "10px",
            marginBottom: "2.5%",
            marginRight: "2%",
            marginLeft: "1%",
            marginTop: "1.5%",
            height: "calc(100vh - 50px)",
          }}
        >
          <div style={{ textAlign: "right" }}>
            <a
              href="../../form/company_form.xlsx"
              download="거래처업로드양식.xlsx"
            >
              <Button color="teal">엑셀파일 양식 다운로드</Button>
            </a>
            <Button positive onClick={() => commonStore.fileUploadModalOepn()}>
              신규 거래처 추가(엑셀업로드)
            </Button>
            <Button
              color="blue"
              onClick={() => {
                compStore.initForm();
                this.fnUpsertViewOpen();
              }}
            >
              신규 거래처 추가(단건)
            </Button>
          </div>
          <div
            style={{
              overflowY: "scroll",
              height: "calc(100vh - 230px)",
              marginTop: "20px",
            }}
          >
            <Table celled style={{ textAlign: "center" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>번호</Table.HeaderCell>
                  <Table.HeaderCell>거래처명</Table.HeaderCell>
                  <Table.HeaderCell>연락처</Table.HeaderCell>
                  <Table.HeaderCell>사용유무</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {compStore.compList == null || compStore.compList.length == 0 ? (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan="3" textAlign="center">
                      등록된 거래처가 없습니다.
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ) : (
                <Table.Body>
                  {compStore.compList.map((data, i) => (
                    <React.Fragment key={data.compNo}>
                      <Table.Row
                        style={{
                          backgroundColor:
                            compStore.selectedComp != null &&
                            data.compNo == compStore.selectedComp.compNo
                              ? COLOR_PRIMARY
                              : COLOR_WHITE,
                          cursor: "pointer",
                          color:
                            compStore.selectedComp != null &&
                            data.compNo == compStore.selectedComp.compNo
                              ? COLOR_WHITE
                              : COLOR_FONT_BLACK,
                        }}
                        onClick={() => this.fnSelectedComp(data)}
                      >
                        <Table.Cell>{data.compNo}</Table.Cell>
                        <Table.Cell>{data.compNm}</Table.Cell>
                        <Table.Cell>{data.compHpNo}</Table.Cell>
                        <Table.Cell>{data.useYn}</Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  ))}
                </Table.Body>
              )}
            </Table>
          </div>
          <Pagination
            style={{ marginTop: "20px" }}
            onPageChange={(e, data) => {
              this.fnSelectCompList(data.activePage);
            }}
            defaultActivePage={compStore.currentPage}
            totalPages={compStore.totalPage}
          />
        </div>

        {this.state.upsertView == false ? null : (
          <div
            style={{
              width: "55%",
              padding: "30px",
              overflow: "hidden",
              boxShadow: "4px 4px 10px rgba(5,5,5,0.1)",
              borderRadius: "10px",
              marginBottom: "2.5%",
              marginRight: "2%",
              position: "relative",
              top: "4%",
              height: "90vh",
            }}
          >
            {compStore.selectedComp == null ? (
              <Fragment>
                <Table style={{ border: "0px", marginTop: "15px" }}>
                  <Table.Header style={{ border: "0px" }}>
                    <Table.Row>
                      <Table.HeaderCell
                        colSpan="2"
                        style={{ background: "#fff" }}
                      >
                        신규 거래처 추가
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>거래처 상호명</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="거래처 상호명 기입"
                          value={compStore.compNm || ""}
                          onChange={(e) => {
                            compStore.setCompNm(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>사업자 번호</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="사업자 번호"
                          value={compStore.compBsNo || ""}
                          onChange={(e) => {
                            compStore.setCompBsNo(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>연락처</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="기사 연락처 기입"
                          value={compStore.compHpNo || ""}
                          onChange={(e) => {
                            compStore.setCompHpNo(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>대표자명</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="대표장명 기입"
                          value={compStore.representative || ""}
                          onChange={(e) => {
                            compStore.setRepresentative(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>업태</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="업태 기입"
                          value={compStore.bsTyp || ""}
                          onChange={(e) => {
                            compStore.setBsTyp(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>종목</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="종목 기입"
                          value={compStore.bsNm || ""}
                          onChange={(e) => {
                            compStore.setBsNm(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>주소</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="주소 기입"
                          value={compStore.compAdd || ""}
                          onChange={(e) => {
                            compStore.setCompAdd(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>이메일</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="이메일 기입"
                          value={compStore.email || ""}
                          onChange={(e) => {
                            compStore.setEmail(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <div style={{ textAlign: "right" }}>
                  <Button positive onClick={() => this.fnInsertComp()}>
                    거래처 등록
                  </Button>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <Table style={{ border: "0px", marginTop: "15px" }}>
                  <Table.Header style={{ border: "0px" }}>
                    <Table.Row>
                      <Table.HeaderCell
                        colSpan="2"
                        style={{ background: "#fff" }}
                      >
                        거래처 정보 수정
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>거래처 상호명</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="거래처 상호명 기입"
                          value={compStore.compNm || ""}
                          onChange={(e) => {
                            compStore.setCompNm(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>사업자 번호</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="사업자 번호"
                          value={compStore.compBsNo || ""}
                          onChange={(e) => {
                            compStore.setCompBsNo(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>연락처</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="기사 연락처 기입"
                          value={compStore.compHpNo || ""}
                          onChange={(e) => {
                            compStore.setCompHpNo(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>대표자명</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="대표장명 기입"
                          value={compStore.representative || ""}
                          onChange={(e) => {
                            compStore.setRepresentative(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>업태</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="업태 기입"
                          value={compStore.bsTyp || ""}
                          onChange={(e) => {
                            compStore.setBsTyp(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>종목</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="종목 기입"
                          value={compStore.bsNm || ""}
                          onChange={(e) => {
                            compStore.setBsNm(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>주소</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="주소 기입"
                          value={compStore.compAdd || ""}
                          onChange={(e) => {
                            compStore.setCompAdd(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell collapsing>이메일</Table.Cell>
                      <Table.Cell>
                        <Input
                          style={{ width: "100%" }}
                          placeholder="이메일 기입"
                          value={compStore.email || ""}
                          onChange={(e) => {
                            compStore.setEmail(e.target.value);
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <div style={{ textAlign: "right" }}>
                  {compStore.selectedComp.useYn == "N" ? (
                    <Button color="teal" onClick={() => this.fnRecoverComp()}>
                      사용재개
                    </Button>
                  ) : (
                    <Button negative onClick={() => this.fnDeleteComp()}>
                      사용중지
                    </Button>
                  )}

                  <Button positive onClick={() => this.fnUpdateComp()}>
                    거래처 정보 수정
                  </Button>
                </div>
              </Fragment>
            )}
          </div>
        )}
        <FileUploadModal fnInsertBulkByExcel={this.fnInsertCompByExcel} />
      </div>
    );
  }
}

export default withRouter(CompManagement);
